import store from '@/store/index.js'
export default {
    print_pdf: function(numero, price) {
        let suffix = ''
        if (price) {
            suffix = '/admin/pdf/'
        } else {
            suffix = '/admin/pdf_noprice/'
        }
        let url = process.env.VUE_APP_API_ROOT + suffix  + store.state.utente.token + '/' + numero
        let w = window.open(url, 'Print', 'height=800,width=1200')
        setTimeout(() => {
            w.focus()
            //w.print()
        }, 1000);
    },
    print_pdf_cliente: function (numero) {
        let url = process.env.VUE_APP_API_ROOT + '/pdf/' + store.state.utente.token + '/' + numero 
        window.open(url, 'Print', 'height=800,width=1200')
    },
    print_pdf_ordine_crisantemi: function(id_prenotazione){
        let url = process.env.VUE_APP_API_ROOT + '/admin/crisantemi/prenotazione/' + id_prenotazione + "/stampa/token/" + store.state.utente.token
        window.open(url, 'Print', 'height=800,width=1200')
    },
    print_pdf_consegna_crisantemi: function(payload){
        let url = process.env.VUE_APP_API_ROOT + '/admin/crisantemi/prenotazione/' + payload.id_prenotazione + "/consegna/" + payload.id_consegna + "/stampa/token/" + store.state.utente.token
        window.open(url, 'Print', 'height=800,width=1200')
    },
    print_pdf_arrivo_settimana: function(payload){
        let url = process.env.VUE_APP_API_ROOT + '/admin/calendario_arrivi/arrivo/' + payload.id_arrivo + '/pdf/token/' + store.state.utente.token
        window.open(url, 'Print', 'height=800,width=1200')
    },
    print_pdf_settimana_calendario_arrivo: function(payload){
        let url = process.env.VUE_APP_API_ROOT + '/admin/calendario_arrivi/settimana/' + payload.id_settimana + '/pdf/token/' + store.state.utente.token
        window.open(url, 'Print', 'height=800,width=1200')
    }
}