import axios from "axios";

export default {
  state: {
    prodotti: [],
    prodotti_raw: [],
    lista_cliente: [],
  },
  getters: {
    get_lista_prodotti_raw: (state) => {
      return state.prodotti_raw;
    },
    get_lista_prodotti: (state) => {
      return state.prodotti;
    },
    get_numero_prodotti_dispo: (state) => {
      return state.prodotti.length;
    },
    get_prodotto_by_id: (state) => (id) => {
      return find_prodotto(state, id);
    },
    get_prodotto_by_cod: (state) => (cod) => {
      for (let i = 0; i < state.prodotti.categorie.length; i++) {
        for (
          let y = 0;
          y < state.prodotti.categorie[i].sub_categorie.length;
          y++
        ) {
          for (
            let x = 0;
            x < state.prodotti.categorie[i].sub_categorie[y].prodotti.length;
            x++
          ) {
            let prod = state.prodotti.categorie[i].sub_categorie[y].prodotti[x];
            if (cod === prod.cod) {
              return prod;
            }
          }
        }
      }
    },
    get_prodotto_raw_by_id: (state) => (id) => {
      return state.prodotti_raw.find((prod) => prod.id == id);
    },
    get_dispo_cliente: (state) => {
      return state.lista_cliente;
    },
    get_varieta_prodotto: (state) => (id_prodotto) => {
      return state.prodotti_raw.find((prod) => prod.id == id_prodotto).varieta;
    },
  },
  mutations: {
    set_prodotti(state, lista) {
      state.prodotti = lista;
    },
    set_prodotti_raw(state, lista) {
      state.prodotti_raw = lista;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_dispo_cliente(state, lista) {
      state.lista_cliente = lista;
    },
    azzera_prodotto(state, id) {
      for (let i = 0; i < state.prodotti.length; i++) {
        for (let y = 0; y < state.prodotti[i].sub_categorie.length; y++) {
          for (
            let x = 0;
            x < state.prodotti[i].sub_categorie[y].prodotti.length;
            x++
          ) {
            if (id === state.prodotti[i].sub_categorie[y].prodotti[x].id) {
              for (
                let col = 0;
                col <
                state.prodotti[i].sub_categorie[y].prodotti[x].colori.length;
                col++
              ) {
                state.prodotti[i].sub_categorie[y].prodotti[x].colori[
                  col
                ].numero = 0;
              }
            }
          }
        }
      }
    },
    set_stato_prodotto(state, payload) {
      let prodotto = find_prodotto(state, payload.id);
      prodotto.disponibile = payload.stato;
    },
    set_dispo_categ(state, payload) {
      let categ = state.prodotti.categorie.find(
        (categ) => categ.id == payload.categ_id
      );
      categ.disponibile = payload.value;
    },
    set_stato_colore(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      let colore = prodotto.colori.find(
        (colore) => colore.id == payload.id_colore
      );
      colore.disponibile = payload.disponibile;
    },
    add_nuovo_colore(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      prodotto.colori.push(payload.colore);
      state.prodotti_raw
        .find((prodotto) => prodotto.id == payload.id_prodotto)
        .colori.push(payload.colore);
    },
    set_modifica_colore(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      let colore = prodotto.colori.find(
        (colore) => colore.id == payload.id_colore
      );
      colore.md5 = payload.md5;
      colore.nome = payload.nome;
      let colore_raw = state.prodotti_raw
        .find((prodotto) => prodotto.id == payload.id_prodotto)
        .colori.find((colore) => colore.id == payload.id_colore);
      colore_raw.md5 = payload.md5;
      colore_raw.nome = payload.nome;
    },
    del_colore(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      let index = prodotto.colori.findIndex(
        (colore) => colore.id == payload.id_colore
      );
      prodotto.colori.splice(index, 1);
      let prodotto_raw = state.prodotti_raw.find(
        (prodotto) => prodotto.id == payload.id_prodotto
      );
      let index_raw = prodotto_raw.colori.findIndex(
        (colore) => colore.id == payload.id_colore
      );
      prodotto_raw.colori.splice(index_raw, 1);
    },
    add_nuova_foto(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      prodotto.img_list.push(payload.foto);
    },
    del_foto_prodotto(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      let index = prodotto.img_list.findIndex(
        (img) => img.id == payload.id_foto
      );
      prodotto.img_list.splice(index, 1);
    },
    set_varieta_prodotto(state, payload) {
      let prodotto = state.prodotti_raw.find(
        (prod) => prod.id == payload.id_prodotto
      );
      prodotto.varieta = payload.varietà;
    },
    set_nuova_varieta(state, payload) {
      let prodotto = state.prodotti_raw.find(
        (prod) => prod.id == payload.id_prodotto
      );
      if (!prodotto.varieta) {
        prodotto.varieta = [];
      }
      prodotto.varieta.push(payload.varietà);
    },
    set_water_req(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      prodotto.water_req = payload.value;
    },
    set_frost_resist(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      prodotto.frost_resist = payload.value;
    },
    set_exposition(state, payload) {
      let prodotto = find_prodotto(state, payload.id_prodotto);
      prodotto.exposition = payload.value;
    },
  },
  actions: {
    fetch_prodotti(context) {
      return new Promise((resolve, reject) => {
        context.commit("set_loading", true);
        let url = process.env.VUE_APP_API_ROOT + "/admin/list";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_prodotti", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.commit("set_loading", false);
          });
      });
    },
    fetch_prodotti_raw(context) {
      return new Promise((resolve, reject) => {
        context.commit("set_loading", true);
        let url = process.env.VUE_APP_API_ROOT + "/admin/list_raw";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_prodotti_raw", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.commit("set_loading", false);
          });
      });
    },
    fetch_dispo_cliente(context, iva) {
      return new Promise((resolve, reject) => {
        //context.commit("set_loading", true)
        let url = process.env.VUE_APP_API_ROOT + "/admin/lista/" + iva;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_dispo_cliente", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_categ_dispo(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/categoria/" +
          payload.categ_id +
          "/disponibile/" +
          payload.value;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_dispo_categ", payload);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_disponibile(context, payload) {
      return new Promise((resolve, reject) => {
        //context.commit("set_loading", true)
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id +
          "/disponibile/" +
          payload.stato;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_stato_prodotto", payload);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_commento(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/info";
        let auth = context.getters.get_credential;
        axios
          .put(url, { info: payload.info }, { auth: auth })
          .then(() => {
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_colore_disponibile(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore;
        let auth = context.getters.get_credential;
        axios
          .put(url, { disponibile: payload.disponibile }, { auth: auth })
          .then(() => {
            context.commit("set_stato_colore", payload);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            //context.commit("set_loading", false)
          });
      });
    },
    nuovo_colore(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.nome;
        let auth = context.getters.get_credential;
        axios
          .post(url, payload.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            auth: auth,
          })
          .then((res) => {
            context.commit("add_nuovo_colore", {
              id_prodotto: payload.id_prodotto,
              colore: res.data,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            //context.commit("set_loading", false)
          });
      });
    },
    modifica_colore(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore +
          "/" +
          payload.nome +
          "/foto/" +
          payload.modifica_img;
        let auth = context.getters.get_credential;

        axios
          .put(url, payload.formData, {
            auth: auth,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            context.commit("set_modifica_colore", {
              id_prodotto: payload.id_prodotto,
              id_colore: payload.id_colore,
              nome: payload.nome,
              md5: res.data.md5,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            //context.commit("set_loading", false)
          });
      });
    },
    elimina_colore(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("del_colore", payload);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            //context.commit("set_loading", false)
          });
      });
    },
    fetch_varieta(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload +
          "/varietà";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    nuova_varieta(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/varietà";
        let auth = context.getters.get_credential;
        let data = {
          nome: payload.nome,
          id_colore: payload.id_colore,
          id_fornitore: payload.id_fornitore,
        };
        axios
          .post(url, data, {
            auth: auth,
          })
          .then((res) => {
            // context.commit("set_nuova_varieta", {
            //   id_prodotto: payload,
            //   varietà: res.data,
            // });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_varieta(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/varietà/" +
          payload.id_varieta;
        let auth = context.getters.get_credential;
        axios
          .delete(url, {
            auth: auth,
          })
          .then(() => {
            // context.commit("set_nuova_varieta", {
            //   id_prodotto: payload,
            //   varietà: res.data,
            // });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    modifica_water_req(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/water_req/" +
          payload.value;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_water_req", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    modifica_exposition(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/exposition/" +
          payload.value;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_exposition", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    modifica_frost_resist(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto/" +
          payload.id_prodotto +
          "/frost_resist/" +
          payload.value;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_frost_resist", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_NUOVI_PRODOTTI(context) {
      context.dispatch("fetch_prodotti");
      context.dispatch("fetch_prodotti_raw");
    },
  },
  modules: {},
};

const find_prodotto = function (state, id_prodotto) {
  for (let i = 0; i < state.prodotti.categorie.length; i++) {
    for (let y = 0; y < state.prodotti.categorie[i].sub_categorie.length; y++) {
      for (
        let x = 0;
        x < state.prodotti.categorie[i].sub_categorie[y].prodotti.length;
        x++
      ) {
        let prodotto = state.prodotti.categorie[i].sub_categorie[y].prodotti[x];
        if (id_prodotto === prodotto.id) {
          return prodotto;
        }
      }
    }
  }
  return null;
};
