<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="is_mobile"
    :scrollable="is_mobile"
  >
    <v-card :img="require('@/assets/chat_bg_2.jpg')">
      <v-card-title class="white--text font-weight-light blue lighten-1">
        <v-list-item class="">
          <v-list-item-avatar tile size="40">
            <v-avatar color="white" size="40">
              <v-icon color="grey">mdi-account</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ autore.nome }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
              <template v-if="autore.is_connected">
                <v-avatar color="light-green" size="10" class="mr-1" />
                <span class="white--text">Online</span>
              </template>
              <template v-else> Ultimo accesso {{ last_access }} </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-card-text class="chat_wrapper mt-2" ref="chat_body">
        <template v-if="chat != []">
          <template v-for="giorno in msg_array">
            <v-row class="my-2" :key="giorno.data" justify="center">
              <v-card max-width="300" elevation="1" class="blue lighten-5 pa-1">
                <span style="font-size: 12px" class="">
                  {{ giorno.data }}
                </span>
              </v-card>
            </v-row>
            <Messaggio
              v-for="messaggio in giorno.msg"
              :key="messaggio.id"
              :messaggio="messaggio"
              :id_conversazione="chat._id"
              :miaChat="in_mio_carico"
              :chat_visibile="dialog"
            ></Messaggio>
          </template>
        </template>
      </v-card-text>
      <v-row no-gutters>
        <span
          :style="{ visibility: ontyping ? 'visible' : 'hidden' }"
          style="font-size: 12px"
          class="font-italic grey--text ml-2"
          >Sta scrivendo ...</span
        >
      </v-row>

      <v-card-actions
        class="white d-flex align-center"
        v-if="chat.destinatario"
      >
        <v-textarea
          rows="1"
          auto-grow
          v-if="in_mio_carico"
          v-model="text_tosend"
          class="py-2"
          rounded
          placeholder="Scrivi un messaggio"
          filled
          background-color=""
          dense
          hide-details
          append-outer-icon="mdi-send"
          @click:prepend-inner="show_emo = true"
          @click:append-outer="send_msg()"
        >
          <template v-slot:append>
            <ImagePicker :chat_id="chat._id" :destinatario="chat.autore.iva" />
            <FilePicker :chat_id="chat._id" :destinatario="chat.autore.iva" />
          </template>
          <template v-slot:prepend-inner>
            <EmojiPicker @emoji="add_to_text" />
          </template>
        </v-textarea>
      </v-card-actions>
      <v-card-actions class="white d-flex align-center">
        <v-spacer />
        <v-btn
          outlined
          color="green"
          class="mr-2"
          @click="prendi_in_carico()"
          v-if="non_in_carico"
          >Prendi in Carico</v-btn
        >

        <v-dialog v-model="delete_dialog" width="400">
          <template v-slot:activator="{ on }">
            <v-btn outlined color="purple" v-on="on">
              <v-icon>mdi-package-down</v-icon>
              Archivia
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="purple--text"> Archivia Chat </v-card-title>
            <v-card-text>
              La chat verrà archiviata.
              <br />
              Procedere?
            </v-card-text>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn outlined color="purple" @click="delete_chat()">
                <v-icon class="mr-2">mdi-package-down</v-icon>
                Archivia
              </v-btn>
              <v-btn outlined color="grey" @click="close()"> Annulla </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import chat_main_mixin from "../mixin/chat_main_mixin";
import Messaggio from "./chat_messaggio";
import EmojiPicker from "./emoji_picket.vue";
import ImagePicker from "./image_picker";
import FilePicker from "./file_picker";
export default {
  components: {
    Messaggio,
    EmojiPicker,
    ImagePicker,
    FilePicker,
  },
  props: {
    show: Boolean,
    chat: Object,
  },
  created() {},
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.$store.commit("sort_chats");
      }
    },
    $route(to, from) {
      if (from.hash === "#dettaglio-chat" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  computed: {
    is_mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    mia_iva() {
      return this.$store.getters.get_utente.iva;
    },
    non_in_carico() {
      return !this.chat.destinatario;
    },
    in_mio_carico() {
      if (this.non_in_carico) {
        return false;
      }
      return this.mia_iva == this.chat.destinatario.iva;
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-chat");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
  mixins: [chat_main_mixin],
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.chat_wrapper {
  height: 500px;
  overflow-y: scroll;
}
</style>