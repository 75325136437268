import axios from "axios";

export default {
  state: {
    cliente: {},
    carrello: [],
  },
  getters: {
    get_carrello: (state) => {
      return { cliente: state.cliente, prodotti: state.carrello };
    },
    get_carrello_count: (state) => {
      return state.carrello.length;
    },
    get_prodotto_carrello: (state) => (id_prodotto) => {
      return state.carrello.find((prodotto) => prodotto.art == id_prodotto);
    },
    get_colore: (state) => (payload) => {
      let prodotto = state.carrello.find((prod) => prod.art == payload.prod_id);
      if (prodotto) {
        let colore = prodotto.colori.find(
          (colore) => colore.id == payload.col_id
        );
        if (colore) {
          return colore;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    get_numero_colore: (state) => (payload) => {
      let prodotto = state.carrello.find((prod) => prod.art == payload.prod_id);
      if (prodotto) {
        let colore = prodotto.colori.find(
          (colore) => colore.id == payload.color_id
        );
        if (colore) {
          return colore.numero;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
  mutations: {
    set_carrello(state, carrello) {
      state.carrello = carrello;
    },
    set_cliente(state, cliente) {
      state.cliente = cliente;
    },
    set_commento(state, payload) {
      state.carrello.find((art) => art.art == payload.id_prodotto).commento =
        payload.commento;
    },
  },
  actions: {
    fetch_carrello(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/carrello/" + payload.iva;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_carrello", res.data);
            let cliente = context.getters.get_cliente_by_iva(payload.iva);
            context.commit("set_cliente", cliente);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_prodotto_carrello(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/carrello/" +
          context.getters.get_carrello.cliente.iva +
          "/" +
          payload.id_prodotto +
          "/" +
          payload.id_colore +
          "/" +
          payload.qta;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.dispatch("fetch_carrello");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invio_colore(context, payload) {
      return new Promise((resolve, reject) => {
        let iva = context.getters.get_carrello.cliente.iva;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/carrello/" +
          iva +
          "/" +
          payload.id_prodotto +
          "/" +
          payload.id_colore +
          "/" +
          payload.qta;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.dispatch("fetch_carrello", { iva: iva });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    remove_prodotto_carrello(context, id_prodotto) {
      return new Promise((resolve, reject) => {
        let iva = context.getters.get_carrello.cliente.iva;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/carrello/" +
          iva +
          "/" +
          id_prodotto;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.dispatch("fetch_carrello", { iva: iva });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invio_commento(context, payload) {
      return new Promise((resolve, reject) => {
        let iva = context.getters.get_carrello.cliente.iva;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/carrello/" +
          iva +
          "/commento/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;

        axios
          .post(url, { commento: payload.commento }, { auth: auth })
          .then(() => {
            context.commit("set_commento", payload);
            // context.dispatch("fetch_carrello");
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    concludi_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let iva = context.getters.get_carrello.cliente.iva;
        let url = process.env.VUE_APP_API_ROOT + "/admin/finalizza/" + iva;
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_ultima_destinzaione_cliente", {
              iva: iva,
              destinazione: payload.destinazione,
            });
            context.commit("set_carrello", []);
            context.commit("add_new_order", JSON.parse(res.data));
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
};
