import _ from "lodash";

export default {
  props: {
    item: Object, 
    show: Boolean,
  },
  data() {
    return {
      dialog: false,
      cmt_rules: [(v) => v.length <= 60 || "Max 60 caratteri"],
      help_menu: false,
      cmt_to_send: "",
    };
  },
  created() {
    this.debouncecmt = _.debounce(this.invia_cmt, 1200);
  },

  computed: {
    commento: {
      get() {
        let art = this.$store.getters.get_prodotto_carrello(
          this.item.id
        )
        return art ? art.commento : "";
      },
      set(cmt) {
        this.cmt_to_send = cmt;
        this.debouncecmt();
      },
    },
    foto_array() {
      let array = [];
      for (let i = 0; i < this.item.img_list_count; i++) {
        array.push(this.item.img_list_src + i);
      }
      return array;
    },

    unita() {
      if (this.item.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.item.unita;
      }
    },
    prezzo_cliente() {
      if (this.item.prezzo) {
        return this.item.prezzo;
      }
      let listino = this.$store.getters.get_carrello.cliente.listino;
      switch (listino) {
        case "1":
          return this.item.prezzo1;
        case "2":
          return this.item.prezzo2;
        case "3":
          return this.item.prezzo3;
      }
    },
    prezzo_unitario() {
      return (this.prezzo_cliente / this.item.unita).toFixed(2);
    },
    nome() {
      if (this.item.nome.includes("Vaso")) {
        return this.item.nome.split("Vaso")[0];
      } else {
        return this.item.nome.split("Basket")[0];
      }
    },
    main_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/main_img/" +
        this.item.id +
        "?t=" +
        this.item.img_md5
      );
    },
    avatar_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.item.id +
        "?t=" +
        this.item.avatar_md5
      );
    },
    exist() {
      return this.$store.getters.get_prodotto_carrello(this.item.id);
    },
  },

  watch: {
    // commento: function () {
    //   this.debouncecmt();
    // },
    $route(to, from) {
      if (from.hash === "#dettaglio-prodotto" && this.dialog) {
        this.dialog = false;
      }
    },
  },

  methods: {
    invia_cmt() {
      this.$store.dispatch("invio_commento", {
        id_prodotto: this.item.id,
        commento: this.cmt_to_send,
      });
    },
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-prodotto");
    },
    close_dial() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
