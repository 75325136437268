<template>
  <div>
    <v-card>
      <v-card-title class="grey--text"> Ordini Attivi </v-card-title>
      <v-card-text>
        Ci sono
        <span style="font-size: 22px" class="green--text">{{
          n_ordini_nuovi
        }}</span>
        Nuovi Ordini
        <br>
        Ci sono
        <span style="font-size: 22px" class="pink--text">{{
          n_ordini_attivi
        }}</span>
        Ordini Attivi
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="green" to="/ordini">Vedi Tutti</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-4">
      <v-card-title class="grey--text"> Conteggi</v-card-title>
      <v-card-text>
        Ci sono
        <span style="font-size: 22px" class="purple--text">{{
          n_conteggi
        }}</span>
        Conteggi Attivi
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="purple" to="/conteggi">Vedi Tutti</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="my-4">
      <v-card-title class="grey--text"> Messaggi</v-card-title>
      <v-card-text>
        Ci sono
        <span style="font-size: 22px" class="blue--text">{{ n_chat_nl }}</span>
        Messaggi da Leggere
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="blue" to="/chat">Vedi Tutti</v-btn>
      </v-card-actions>
    </v-card>
    <ClientiOnline/>
    <!-- <v-card class="mt-4">
      <v-card-title class="grey--text"> Utenti Online</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="utente in utenti_online" :key="utente.iva">
            <v-list-item-avatar>
              <v-icon :color="utente.is_connected ? 'green' : 'amber'" size="10">mdi-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{utente.nome}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
import ClientiOnline from "../../Statistiche/mobile/clienti_oniline_mobile/clienti_attivi_mobile.vue"
export default {
  components: {
    ClientiOnline
  },
  computed: {
    n_ordini_nuovi() {
      return this.$store.getters.get_ordini_nuovi;
    },
    n_ordini_attivi(){
      return this.$store.getters.get_numero_ordini_attivi;
    },
    n_conteggi() {
      return this.$store.getters.get_numero_conteggi;
    },
    n_chat_nl() {
      return this.$store.getters.get_chats_nl;
    },
    utenti_online() {
      return this.$store.getters.get_clienti_online;
    },
  },
};
</script>