import axios from "axios";

export default {
  state: {
    utente: {},
    isAuth: false,
    token: null,
  },
  getters: {
    get_utente: (state) => {
      return state.utente;
    },
    get_isAuth: (state) => {
      return state.isAuth;
    },
    get_token: (state) => {
      return state.token;
    },
    get_credential: (state) => {
      return {
        username: state.token,
        password: "",
      };
    },
  },
  mutations: {
    set_isAuth(state, status) {
      state.isAuth = status;
    },
    set_token(state, token) {
      state.token = token;
    },
    set_utente(state, utente) {
      state.utente = utente;
    },
    disconnect(state) {
      state.isAuth = false;
      state.utente = {};
      state.token = ""
      localStorage.setItem("token", "");
    },
  },
  actions: {
    fetch_auth(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/token";
        let auth = {
          username: payload.username,
          password: payload.password,
        };
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_token", res.data.token);
            context.commit("set_utente", res.data.user);
            // context.commit("set_isAuth", true);
            localStorage.setItem("token", res.data.token);
            resolve({ status: 200 });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout(context, payload) {
      let url = process.env.VUE_APP_API_ROOT + "/subscription/" + payload;
      let auth = context.getters.get_credential
      axios.delete(url, { auth: auth });
      context.commit("disconnect");
    },
    SOCKET_DISCONNETTI(context) {
      context.commit("disconnect");
      this._vm.$socket.close();
    },
  },
  modules: {},
};
