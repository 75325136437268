<template>
  <v-dialog width="800" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"><v-icon>mdi-open-in-new</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Dettaglio Lotti
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3"> Fornitore </v-col>
          <v-col cols="2"> Codice </v-col>
          <v-col cols="3"> Data </v-col>
          <v-col cols="2"> Settimana </v-col>
          <v-col cols="2"> Documento </v-col>
        </v-row>
        <v-divider class="mb-4" />

        <v-row v-if="loading" class="pa-6" justify="center" align="center">
          <v-progress-circular indeterminate width="2" color="green" />
        </v-row>
        <template v-else>
          <template v-if="!lotti.length">
            <v-row justify="center" align="center" class="pa-6">
              Nussun Lotto Registrato</v-row
            >
          </template>
          <template v-else>
            <v-row v-for="lotto in lotti" :key="lotto.id">
              <v-col cols="3">
                <v-avatar size="25" class="mr-4"
                  ><v-img
                    :src="avatar_fornitore(fornitore(lotto.id_fornitore).id)"
                  ></v-img
                ></v-avatar>
                {{ fornitore(lotto.id_fornitore).nome }}
              </v-col>
              <v-col cols="2">
                {{ lotto.codice }}
              </v-col>
              <v-col cols="3">
                {{ data(lotto.ts_documento) }}
              </v-col>
              <v-col cols="2">
                {{ settimana(lotto.ts_documento) }}
              </v-col>
              <v-col cols="2">
                {{ lotto.n_documento }}
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  props: {
    prodotto: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      lotti: [],
    };
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        if (!this.prodotto.lotti.length) {
          return;
        }
        this.loading = true;
        this.lotti = [];
        this.prodotto.lotti.forEach((lotto) => {
          this.$store
            .dispatch("get_lotto_by_cod", lotto)
            .then((res) => {
              this.lotti.push(res.data);
            })
            .finally(() => {
              this.loading = false;
            });
        });
      }
    },
  },
  methods: {
    fornitore(id_fornitore) {
      return this.$store.getters.get_fornitore_by_id(id_fornitore);
    },
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    data(ts) {
      return moment.unix(ts).format("dddd DD MMMM");
    },
    settimana(ts) {
      return moment.unix(ts).format("WW");
    },
  },
};
</script>