import _ from "lodash";
export default {
  props: {
    prodotto: Object,
    colore: Object,
  },
  created() {
    this.debounceqta = _.debounce(this.set_qta, 400);
  },
  data() {
    return {
      men: false,
      qta: this.$store.getters.get_numero_colore({
        prod_id: this.prodotto.id,
        color_id: this.colore.id,
      }),
      loading: false,
      adding: false,
      removing: false,
      messaggio: "",
      dialog: false,
    };
  },
  watch: {
    qta: function () {
      if (isNaN(this.qta)) {
        return;
      }
      if (this.qta < 0) {
        this.qta = 0;
      } else if (this.qta > 500) {
        this.qta = 500;
      }
      this.debounceqta();
    },
    show: function () {
      if (this.show) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  computed: {
    valid() {
      return !isNaN(this.qta);
    },
    col() {
      return this.$store.getters.get_colore({
        prod_id: this.prodotto.id,
        col_id: this.colore.id,
      });
    },
    color_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto.id +
        "/img_colore/" +
        this.colore.id
      );
    },
  },
  methods: {
    select_col(colore) {
      this.$emit("colore_sel", colore);
    },
    set_qta() {
      if (this.colore.numero == 0) {
        if (this.qta == null || this.qta == "" || this.qta == 0) {
          return;
        }
      }
      this.loading = true;

      if (this.qta == null || this.qta == "") {
        this.colore.numero = 0;
      } else {
        this.colore.numero = this.qta;
      }

      this.$store
        .dispatch("invio_colore", {
          id_colore: this.colore.id,
          qta: this.colore.numero,
          id_prodotto: this.prodotto.id,
        })
        .then(() => {
          if (this.colore.numero == 0) {
            this.removing = true;
          } else {
            this.adding = true;
          }
          setTimeout(() => {
            this.adding = false;
            this.removing = false;
          }, 2000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select_box() {
      if (this.$vuetify.breakpoint.mobile) {
        return;
      }
      setTimeout(() => {
        this.$refs.qta_box.focus();
      }, 100);
    },
    select_text: function (e) {
      // if (this.$vuetify.breakpoint.mobile) {
      //   return;
      // }
      e.target.select();
    },
  },
};
