<template>
  <v-card height="400">
    <v-card-title class="grey--text">
      Accessi
      <v-spacer />
      <DialogLogs />
    </v-card-title>
    <v-card-text class="scroll">
      <template v-if="loading">
        <v-row justify="center" align="center" class="py-12">
          <v-progress-circular color="green" width="2" indeterminate />
        </v-row>
      </template>
      <template v-else>
        <v-list>
          <v-list-item v-for="cliente in lista_cli" :key="cliente.id">
            <v-list-item-icon>
              <v-icon
                size="15"
                :color="cliente.is_connected ? 'green' : 'amber'"
                >mdi-circle</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>
                {{ cliente.nome }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  cliente.is_connected
                    ? "Online"
                    : data_format(cliente.last_access)
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <Carrello v-if="cliente.has_cart" :cliente="cliente"/>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import DialogLogs from "../../desktop/clienti_online/dialog_log_clienti.vue";
import clienti_attivi_mixin from "../../mixin/clienti_attivi_mixin";
import Carrello from "./carrello_cliente_mobile.vue"
export default {
  components: {
    DialogLogs,
    Carrello
  },
  mixins: [clienti_attivi_mixin],
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 330px;
  overflow-y: scroll;
}
</style>