<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on }">
      <v-col
        v-if="!icon"
        cols="12"
        :class="ritiro.color + '--text'"
        style="cursor: pointer"
        v-on="on"
      >
        {{ ritiro.text }}
      </v-col>
      <v-icon v-else v-on="on" size="20" class="ml-2"> mdi-pencil </v-icon>
    </template>
    <v-card :loading="loading">
      <v-card-title class="primary--text"> Cambia Trasporto </v-card-title>
      <v-card-text>
        <v-radio-group v-model="codice_ritiro">
          <v-radio value="1">
            <template v-slot:label>
              <div>Consegna Nostra</div>
            </template>
          </v-radio>
          <v-radio value="0">
            <template v-slot:label>
              <div>Ritiro Cliente</div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-select
          v-if="codice_ritiro == 1"
          :items="destinazioni"
          item-text="indirizzo"
          item-value="id"
          v-model="destinazione_sel"
          outlined
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="menu = false"> Fatto </v-btn>
        <!-- <v-btn outlined color="primary" :loading="loading" @click="confirm_type_change()">
          Conferma
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import cambia_tipo_mixin from "../../mixin/cambia_tipo_consegna_mixin";
export default {
  mixins: [cambia_tipo_mixin],
};
</script>