export default {
  data() {
    return {
      dialog: false,
      clienti_sel: [],
      zona: null,
      text_send: null,
      radios: "free",
      loading: false,
      dialog_new_text: false,
      text_modello: null,
      id_modello: null
    };
  },
  computed: {
    zone() {
      return this.$store.getters.get_zone;
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
    clienti_filter() {
      if (this.zona == "Tutte") {
        return this.clienti;
      }
      return this.clienti.filter((cliente) => cliente.zona == this.zona);
    },
    fix_texts() {
      return this.$store.getters.get_testi_broadcast;
    },
    to_send() {
      return this.radios == "free" ? this.text_send : this.radios;
    },
  },
  watch: {
    zona: function () {
      this.clienti_sel = [];
    },
    $route(to, from) {
        if (from.hash === "#nuovo_broadcast" && this.dialog) {
          this.dialog = false;
        }
      },
  },
  methods: {
    open(){
        this.$router.push("#nuovo_broadcast");
        this.dialog = true
    },
    close(){
        this.$router.back()
        this.dialog = false
    },
    remove(item) {
      const index = this.clienti_sel.indexOf(item.iva);
      if (index >= 0) this.clienti_sel.splice(index, 1);
    },
    invia_broadcast() {
      this.loading = true;
      this.$store
        .dispatch("invia_broadcast", {
          clienti: this.clienti_sel,
          id_modello: this.radios,
        })
        .finally(() => {
          this.loading = false;
          this.close()
        });
    },
    invia_broadcast_immagine(){
      this.loading = true;
      this.$store
        .dispatch("invia_broadcast_immagine", {
          clienti: this.clienti_sel,
          id_modello: this.radios,
        })
        .finally(() => {
          this.loading = false;
          this.close()
        });
    },
    send_new_text() {
      this.$store
        .dispatch("new_testo_broadcast",{id_modello: this.id_modello, text: this.text_modello})
        .finally(() => {
          this.new_fix_text = null;
          this.dialog_new_text = false;
        });
    },
    delete_text(id) {
      this.$store.dispatch("elimina_testo_broadcast", id);
    },
  },
};
