import printer from "@/service/printer";
import _ from "lodash";
export default {
  props: {
    ordine: Object,
    small: Boolean,
    noIcon: Boolean,
  },
  created() {
    this.debouncecmt = _.debounce(this.send_commento, 2000);
  },
  data() {
    return {
      show: false,
      show_all: false,
      menu_edit_note: false,
      note_edit: this.ordine.commento,
      loading_cmt: false,
      ok_cmt: false,
      menu_modifica_tipo_cc: false,
      tipo_carrello_new: this.ordine.tipo_cc,
      tipo_carrello: [
        { value: 0, text: "Non specificato" },
        { value: 1, text: "Rosso Nostro" },
        { value: 2, text: "Lucchetto Nero" },
        { value: 3, text: "Tag Verde" },
      ],
      loading_conferma:false
    };
  },
  watch: {
    show: function () {
      if (!this.show) {
        this.$emit("close");
      } else {
        if (this.ordine.nuovo) {
          this.$store.dispatch("modifica_ordine_nuovo_vecchio", {
            id_ordine: this.ordine._id.$oid,
            stato: false,
          });
        }
      }
    },
    note_edit: function () {
      this.debouncecmt();
    },
    tipo_carrello_new() {
      this.$store
        .dispatch("modifica_ordine_tipo_cc", {
          id_ordine: this.ordine._id.$oid,
          tipo_cc: this.tipo_carrello_new,
        })
        .then(() => {
          this.menu_modifica_tipo_cc = false;
        });
    },
  },
  computed: {
    order_tot() {
      return this.ordine.articoli
        .filter((art) => !art.eliminato)
        .map((art) => {
          return art.colori
            .filter((col) => !col.eliminato)
            .map((col) => {
              return (col.qta_admin ? col.qta_admin : col.numero) * art.prezzo;
            })
            .reduce((tot, num) => tot + num, 0);
        })
        .reduce((tot, num) => tot + num, 0);
    },
    tot_pezzi() {
      return this.ordine.colori.filter(col => !col.eliminato)
        .map((col) => col.qta_admin ? parseInt(col.qta_admin) : parseInt(col.numero))
        .reduce((tot, num) => tot + num);
    },
    prezzo_tot() {
      let tot = this.tot_pezzi * this.ordine.prezzo;
      return tot.toFixed(2);
    },
    tipo_cc() {
      switch (this.ordine.tipo_cc) {
        case 0:
          return { text: "Non Specificato", color: "grey" };
        case 1:
          return { text: "Rosso Nostro", color: "red" };
        case 2:
          return { text: "Lucchetto Nero", color: "black" };
        case 3:
          return { text: "Tag Verde", color: "green" };
      }
      return { text: "Non Specificato", color: "grey" };
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    processa_ordine(stato) {
      this.loading_conferma = true;
      this.$store
        .dispatch("processa_ordine", {
          id: this.ordine._id.$oid,
          stato: stato,
        })
        .finally(() => {
          this.loading_conferma = false;
        });
    },
    pdf(price) {
      this.$store.dispatch("modifica_ordine_print", {id_ordine: this.ordine._id.$oid, stato: true})
      printer.print_pdf(this.ordine.numero, price);
    },
    send_commento() {
      this.loading_cmt = true;
      this.$store
        .dispatch("modifica_commento_finale", {
          ordine: this.ordine._id.$oid,
          commento: this.note_edit,
        })
        .then(() => {
          this.loading_cmt = false;
          this.ok_cmt = true;
          setTimeout(() => {
            this.ok_cmt = false;
          }, 1500);
        });
    },
  },
};
