<template>
  <v-hover v-slot:default="{ hover }">
    <v-row
      align="center"
      :class="{ 'light-green lighten-5 green--text': hover }"
      class="py-3 py-md-2"
      @click="$refs.dialog_prodotto.open()"
      no-gutters
    >
      <v-col
        cols="1"
        v-if="$vuetify.breakpoint.width > 600"
        style="cursor: pointer"
        class="d-flex justify-center"
      >
        <v-avatar size="60" class="mr-3">
          <v-img :src="avatar_img"> </v-img>
        </v-avatar>
      </v-col>
      <v-col cols="5" md="3" class="text-truncate" style="cursor: pointer">
        <span style="font-size: 18px">{{ nome }}</span>
        <br />
        <span>{{ tipo_vaso }}</span>
      </v-col>
      <v-col cols="2" md="1"> Ø {{ item.diametro }} </v-col>
      <v-col cols="3" v-if="$vuetify.breakpoint.width > 600">
        <template v-if="item.isNew">
          <v-icon color="success">mdi-spa</v-icon
          ><span class="primary--text mr-3"> Novità</span>
        </template>
        {{ item.info }}
      </v-col>
      <v-col cols="2" md="1"> € {{ item.prezzo }} </v-col>
      <v-col cols="1" class="d-flex justify-start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-badge
              color="pink lighten-1"
              class="mr-4"
              dark
              :value="numero_prodotti"
              :content="numero_prodotti"
              overlap
              offset-x="20"
              offset-y="20"
            >
              <v-btn
                v-show="true"
                color="green"
                dark
                small
                fab
                icon
                v-on="on"
                @click="show_dialog = true"
              >
                <v-icon>mdi-cart</v-icon>
              </v-btn>
            </v-badge>
          </template>
          Acquista
        </v-tooltip>
        <ProdottoDialog :item="item" ref="dialog_prodotto" />
        <!-- <DialogDettaglio
          :item="item"
          :show="show_dialog"
          ref="dettaglio"
          @close="show_dialog = false"
          @deleted="$emit('deleted')"
          @updated="$emit('updated')"
        /> -->

        <!-- <FotoGallery
          v-if="item.img_list_count"
          :image_list_src="item.img_list_src"
          :image_count="item.img_list_count"
        /> -->

        <v-tooltip v-if="item.preferito" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="switch_pref()">
              <v-icon color="primary">mdi-heart-outline</v-icon>
            </v-btn>
          </template>
          Rimuovi da preferiti
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-show="hover" v-on="on" @click="switch_pref()">
              <v-icon color="grey">mdi-heart-outline</v-icon>
            </v-btn>
          </template>
          Aggiungi a preferiti
        </v-tooltip>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
//import DialogDettaglio from "./dialog_inserimento/listino_dettaglio_prodotto_desktop";
//import FotoGallery from "./listino_fotogallery_desktop";
import prod_element_mixin from "../mixin/listino_prodotto_element_mixin";
import ProdottoDialog from "../../dialog_carrello"
export default {
  components: {
    ProdottoDialog
    //DialogDettaglio,
    //FotoGallery,
  },
  mixins: [prod_element_mixin],
  data() {
    return {
      show_dialog: false,
    };
  },
  props: {
    show: Boolean,
  },
};
</script>
