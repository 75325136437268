import moment from 'moment'

export default {
    data() {
        return {
            metodo_valid: true,
            orario_valid: true,
            dialog_confirm: false,
            on_ending: false,
            ordine_confermato: null,
            notifica: false
        }
    },
    computed: {
        cart_cnt() {
            return this.$store.getters.get_carrello_count
      }  
    },
    methods: {
        concludi_ordine() {
            this.$refs.metodo_form.validate()
            if (!this.metodo_valid) {
                return
            }
            let time_stamp = 0
            if (this.specifica_data) {
                this.$refs.orario_form.validate()
                if (!this.orario_valid) {
                    return
                }
                time_stamp = moment(this.date + ' ' + this.time).format("X")
            }
            this.on_ending = true
            let json_ordine = {
                'commento': this.commento,
                'consegna': parseInt(this.metodo_trasporto) ,
                'destinazione': this.destinazione_sel,
                'tipo_cc': this.tipo_cc_sel,
                'giorno': this.specifica_data ? this.dateFormatter : null,
                'orario': this.specifica_data ? this.time : null,
                'orario_ts': time_stamp,
                'notifica': this.notifica,
            };
            this.$store.dispatch("concludi_ordine", json_ordine)
                .then(() => {
                    this.cliente_sel = null
                    this.dialog = false
                })
                .finally(() => {
                    this.on_ending = false
                })
        }
    }
}