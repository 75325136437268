<template>
  <v-dialog v-model="dialog" width="1800">
    <!-- <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" class="ml-8"><v-icon>mdi-map</v-icon></v-btn>
    </template> -->
    <v-snackbar v-model="avviso_avvio" top shaped color="green">
      <v-icon>mdi-information</v-icon>
      Il mezzo selezionato si trova in azienda e la consegna verrà avviata non
      appena partirà.
    </v-snackbar>
    <v-snackbar v-model="snack_mezzo_offline" bottom shaped color="error">
      <v-icon>mdi-alert</v-icon>
      Il mezzo selezionato risulta offline.
    </v-snackbar>

    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Dettaglio Consegna
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col :cols="map_with">
            <v-row
              v-if="dialog_loading"
              no-gutters
              justify="center"
              align="center"
              fill-height
            >
              <v-progress-circular
                indeterminate
                color="green"
                width="1"
                size="20"
                class="mr-4"
              ></v-progress-circular>
              Attendi...
            </v-row>
            <v-row no-gutters v-else>
              <GmapMap
                :center="center"
                :zoom="10"
                :style="'width: 100%; height:' + (window_height - 200) + 'px'"
              >
                <gmap-custom-marker :marker="truck_position">
                  <v-menu min-width="300">
                    <template v-slot:activator="{ on }">
                      <v-btn fab small v-on="on" color="green"
                        ><v-icon color="white">mdi-truck</v-icon></v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title class="grey--text text-subtitle-1">{{
                        mezzo_consegna.nome
                      }}</v-card-title>
                      <v-card-subtitle>
                        {{ mezzo_consegna.marca }}
                        <br />
                        {{ mezzo_consegna.targa }}
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                </gmap-custom-marker>
                <gmap-custom-marker :marker="center">
                  <v-menu min-width="300">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        fab
                        small
                        v-on="on"
                        @mouseenter="evidenzia_linea_ritorno()"
                        @mouseleave="pulisci_linea()"
                        ><v-icon color="black"
                          >mdi-flag-checkered</v-icon
                        ></v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title class="grey--text text-subtitle-1"
                        >G.M. Flor</v-card-title
                      >
                      <v-card-subtitle>
                        Via Villafalletto 59, Fossano
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                </gmap-custom-marker>
                <gmap-custom-marker
                  v-for="(tappa, index) in consegna.tappe.filter(
                    (tappa) => !tappa.ritorno
                  )"
                  :key="tappa.id"
                  :marker="tappa.coords"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        @mouseenter="evidenzia_linea(tappa.id)"
                        @mouseleave="pulisci_linea()"
                        fab
                        small
                        v-on="{ ...tooltip }"
                        :color="array_colori[index].name"
                        ><v-icon color="white">{{
                          icona_tappa(tappa)
                        }}</v-icon></v-btn
                      >
                    </template>
                    {{ cliente(tappa.cliente).nome }}
                  </v-tooltip>
                </gmap-custom-marker>

                <template v-if="consegna.tappe.length">
                  <gmap-polyline
                    v-for="(tappa, index) in consegna.tappe"
                    :key="tappa.id + 'poly'"
                    :path="tappa.polyline"
                    v-bind:options="{
                      strokeColor:
                        tappa.completa || tappa.id == id_linea_evidenziata
                          ? array_colori[index].hex
                          : '#AAAAAA',
                      strokeWeight: 4,
                      strokeOpacity:
                        tappa.id != id_linea_evidenziata &&
                        id_linea_evidenziata != null
                          ? 0.5
                          : 1,
                    }"
                  >
                  </gmap-polyline>
                </template>
                <template v-if="show_trace">
                  <gmap-polyline
                    v-bind:path.sync="storico_consegna"
                    v-bind:options="{ strokeColor: '#008000' }"
                  >
                  </gmap-polyline>
                </template>
              </GmapMap>
            </v-row>
          </v-col>
          <v-col>
            <v-card
              flat
              :height="window_height - 200"
              color=""
              class="overflow-auto"
            >
              <v-card-text>
                <v-row no-gutters align="center" class="ml-6">
                  Stato:
                  <template v-if="consegna.stato == 1">
                    <v-icon color="indigo" size="20" class="mr-2 ml-2">
                      mdi-truck-fast
                    </v-icon>
                    in Consegna
                    <v-spacer></v-spacer>

                    <v-dialog
                      v-model="dialog_termina"
                      v-if="consegna.stato == 1"
                      width="400"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn outlined color="amber" v-on="on">
                          <v-icon class="mr-2">mdi-truck-check</v-icon>
                          Termina
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="amber--text">
                          Termina Consegna
                        </v-card-title>
                        <v-card-text>
                          La consegna verrà terminata.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="green"
                            :loading="loading_termina"
                            @click="termina_consegna()"
                          >
                            <v-icon class="mr-2">mdi-truck-chek</v-icon>
                            Termina
                          </v-btn>
                          <v-btn
                            text
                            color="grey"
                            @click="dialog_termina = false"
                            >Annulla</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-if="consegna.stato == 0">
                    <v-icon color="orange" size="20" class="mr-2 ml-2">
                      mdi-clock
                    </v-icon>
                    Programmata
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="green"
                      small
                      @click="apri_dialog_avvia_consegna()"
                      ><v-icon class="mr-2">mdi-truck-fast</v-icon>Avvia</v-btn
                    >
                    <v-dialog v-model="dialog_avvia_consegna" width="400">
                      <v-card>
                        <v-card-title>Attenzione</v-card-title>
                        <v-card-text>
                          <p>
                            Il mezzo di consegna non si trova in azienda ...
                          </p>
                          <v-btn
                            outlined
                            color="blue"
                            class="my-2"
                            @click="programma_consegna()"
                            :loading="loading_programma_consegna"
                            >Non è ancora arrivato</v-btn
                          >
                          <v-btn
                            outlined
                            color="green"
                            class="my-2"
                            :loading="avvia_loading"
                            @click="avvia_consegna_immediato()"
                            >è già partito</v-btn
                          >
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-if="consegna.stato == 2">
                    <v-icon color="green" size="20" class="mr-2 ml-2">
                      mdi-check-circle
                    </v-icon>
                    Completata
                  </template>
                  <template v-if="consegna.stato == 3">
                    <v-icon color="purple" size="20" class="mr-2 ml-2">
                      mdi-upload
                    </v-icon>
                    Attesa Partenza
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="red"
                      small
                      :loading="annulla_loading"
                      @click="annulla_consegna()"
                      ><v-icon class="mr-2">mdi-cancel</v-icon>Annulla</v-btn
                    >
                  </template>
                  <template v-if="consegna.stato == 4">
                    <v-icon color="indigo" size="20" class="mr-2 ml-2">
                      mdi-bus-clock
                    </v-icon>
                    Attesa Arrivo Mezzo
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="red"
                      small
                      :loading="annulla_loading"
                      @click="annulla_consegna()"
                      ><v-icon class="mr-2">mdi-cancel</v-icon>Annulla</v-btn
                    >
                  </template>
                </v-row>
                <v-timeline dense class="mt-6">
                  <v-timeline-item icon="mdi-store" fill-dot>
                    <v-hover v-slot="{ hover }">
                      <v-row no-gutters>
                        <v-col cols="10">
                          <b>G.M. Flor</b>
                          <br />
                          Via Villafalletto 59, Fossano
                        </v-col>

                        <v-col cols="2" v-if="consegna.stato == 0">
                          <v-btn
                            icon
                            v-if="hover"
                            @click="apri_dialog_aggiungi(0)"
                            ><v-icon color="green">mdi-plus</v-icon></v-btn
                          >
                        </v-col>
                        <v-col cols="12" v-if="consegna.ts_partenza">
                          Partito {{ partenza_effettiva }}
                        </v-col>
                      </v-row>
                    </v-hover>
                  </v-timeline-item>

                  <v-timeline-item
                    fill-dot
                    v-for="(tappa, index) in consegna.tappe.filter(
                      (tappa) => !tappa.ritorno
                    )"
                    :key="tappa.id_ordine"
                    clipped
                    :icon="icona_tappa(tappa)"
                    :color="array_colori[index].name"
                  >
                    <v-row class="">
                      <v-col>
                        <v-icon size="20" class="mr-1">mdi-clock</v-icon
                        >{{ tappa.durata.text }}
                      </v-col>
                    </v-row>
                    <v-hover v-slot="{ hover }">
                      <v-row
                        @mouseenter="evidenzia_linea(tappa.id)"
                        @mouseleave="pulisci_linea(tappa.id)"
                      >
                        <v-col cols="10" class="text-truncate">
                          <b>{{ cliente(tappa.cliente).nome }}</b>
                          <br />
                          <span
                            class="text-truncate d-inline-block"
                            style="max-width: 200px"
                            >{{
                              ordine(tappa.ordine).destinazione.indirizzo
                            }}</span
                          >
                          <br />
                          <span>Stato: </span>
                          <template v-if="!tappa.raggiunta && !tappa.completa">
                            <span class="amber--text">In attesa</span>
                          </template>
                          <template
                            v-else-if="tappa.raggiunta && !tappa.completa"
                          >
                            <span class="indigo--text">Scarico in corso</span>
                          </template>
                          <template
                            v-else-if="tappa.raggiunta && tappa.completa"
                          >
                            <span class="green--text">Completata </span
                            >{{ orario_completamento(tappa.ts_fine_scarico) }}
                          </template>
                          <br />
                          Tot. CC:
                          {{ $store.getters.get_qta_cc_ordine(tappa.ordine) }}
                          <template v-if="cliente(tappa.cliente).cambio_cc">
                            <span class="green--text">Cambio CC</span>
                          </template>
                          <template v-else>
                            <span class="amber--text">Scarico</span>
                          </template>
                        </v-col>
                        <v-col cols="2" v-if="consegna.stato == 0">
                          <v-btn
                            icon
                            v-if="hover && consegna.tappe.length > 2"
                            @click="rimuovi_tappa(tappa.id)"
                            ><v-icon color="red">mdi-delete</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            v-if="hover"
                            @click="apri_dialog_aggiungi(index + 1)"
                            ><v-icon color="green">mdi-plus</v-icon></v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-hover>
                  </v-timeline-item>
                  <v-timeline-item
                    small
                    fill-dot
                    v-if="!consegna.tappe.some((tappa) => tappa.ritorno)"
                  >
                    <v-row class="grey--text mt-n3">
                      <v-col>
                        <v-btn
                          outlined
                          color="green"
                          small
                          @click="dialog_aggiungi = true"
                          >Aggiungi ...</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                  <v-timeline-item
                    small
                    v-if="!tappe.some((tappa) => tappa.ritorno) && tappe.length"
                    color="purple"
                    fill-dot
                  >
                    <v-row class="mt-n3">
                      <v-col>
                        <v-btn
                          outlined
                          color="purple"
                          @click="termina_giro()"
                          small
                          >Concludi</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                  <v-timeline-item
                    icon="mdi-flag-checkered"
                    fill-dot
                    v-if="consegna.tappe.some((tappa) => tappa.ritorno)"
                  >
                    <v-row align="center" class="">
                      <v-col class="d-flex align-center">
                        <v-icon size="20" class="mr-1">mdi-clock</v-icon
                        >{{
                          consegna.tappe.find((tappa) => tappa.ritorno).durata
                            .text
                        }}
                      </v-col>
                    </v-row>
                    <v-row
                      no-gutters
                      @mouseenter="evidenzia_linea_ritorno()"
                      @mouseleave="pulisci_linea()"
                    >
                      <v-col cols="9">
                        <b>G.M. Flor </b>
                        <br />
                        Via Villafalletto 59, Fossano
                      </v-col>
                      <v-col cols="12" v-if="consegna.ts_arrivo">
                        Arrivato {{ arrivo_effettivo }}
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
                <v-dialog width="1200" v-model="dialog_aggiungi" scrollable>
                  <v-card>
                    <v-card-title>Aggiungi ... </v-card-title>
                    <v-card-text>
                      <v-card
                        class="my-4"
                        v-for="element in ordini_localizzati_destinati"
                        :key="element.zona"
                      >
                        <v-card-title class="indigo--text text-subtitle-1">
                          <v-icon color="indigo" class="mr-2">mdi-city</v-icon
                          >{{ element.zona }}
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="4"
                              v-for="ordine in element.ordini"
                              :key="ordine._id.$oid"
                            >
                              <v-card
                                elevation="0"
                                :color="
                                  trova_valori_viaggio(ordine._id.$oid)
                                    ? array_colori[
                                        trova_valori_viaggio(ordine._id.$oid)
                                          .index
                                      ].name + ' lighten-5'
                                    : 'white'
                                "
                              >
                                <v-card-title
                                  class="grey--text text-subtitle-1"
                                >
                                  <v-icon
                                    class="mr-1 ml-n2"
                                    :color="
                                      trova_valori_viaggio(ordine._id.$oid)
                                        ? array_colori[
                                            trova_valori_viaggio(
                                              ordine._id.$oid
                                            ).index
                                          ].name
                                        : 'grey'
                                    "
                                    >mdi-map-marker</v-icon
                                  >
                                  <span
                                    class="d-inline-block text-truncate"
                                    style="max-width: 250px"
                                  >
                                    {{ ordine.cliente }}
                                  </span>

                                  <v-spacer></v-spacer>
                                  <template
                                    v-if="
                                      !trova_valori_viaggio(ordine._id.$oid)
                                    "
                                  >
                                    <v-btn
                                      icon
                                      color="green"
                                      fab
                                      depressed
                                      small
                                      @click="aggiungi_tappa(ordine._id.$oid)"
                                      ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                  </template>
                                  <template v-else>
                                    <v-btn
                                      icon
                                      color="red"
                                      @click="rimuovi_tappa(ordine._id.$oid)"
                                      ><v-icon>mdi-minus</v-icon></v-btn
                                    >
                                  </template>
                                </v-card-title>

                                <v-card-subtitle
                                  >Ordine # {{ ordine.numero }}
                                  <br />
                                  Tot cc:
                                  <b>
                                    {{
                                      $store.getters.get_qta_cc_ordine(
                                        ordine._id.$oid
                                      )
                                    }}
                                  </b>
                                  <template
                                    v-if="trova_valori_viaggio(ordine._id.$oid)"
                                  >
                                    <br />
                                    <span
                                      >Posizione
                                      <b>
                                        #
                                        {{
                                          trova_valori_viaggio(ordine._id.$oid)
                                            .index + 1
                                        }}</b
                                      ></span
                                    >
                                  </template>
                                </v-card-subtitle>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn text color="grey" @click="dialog_aggiungi = false"
                        >chiudi</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-row class="ml-4 mt-4">
                  <v-col>
                    Totale Carrelli:
                    <b>{{ carrelli_tot }}</b>
                    <br />
                    Tempo Viaggio:
                    <b>{{ tempo_viaggio.text }}</b>
                    <br />
                    Tempo Scarico:
                    <b>{{ tempo_scarico_totale.text }}</b>
                    <br />
                    Tempo Totale:
                    <b>{{ tempo_totale_viaggio }}</b>
                  </v-col>
                </v-row>

                <v-row class="ml-4 mt-4">
                  <v-col cols="12">
                    <v-select
                      v-model="id_mezzo"
                      label="Automezzo"
                      :items="mezzi"
                      item-value="id"
                      item-text="nome"
                      outlined
                      hide-details
                      :disabled="consegna.stato != 0"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      v-if="consegna.stato != 0"
                      class="mt-n2"
                      inset
                      v-model="show_trace"
                      label="Mostra Tracciato"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col class="">
                    Partenza Prevista: <b> {{ partenza_prevista }}</b>
                    <v-menu
                      v-model="menu_cambia_data"
                      :close-on-content-click="false"
                      offset-x
                      left
                      max-width="300"
                      v-if="consegna.stato == 0"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                          ><v-icon>mdi-pencil</v-icon></v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-text>
                          <v-col cols="12">
                            <v-select
                              outlined
                              hide-details
                              label="Giorno"
                              :items="giorni"
                              v-model="giorno_consegna"
                              item-text="label"
                              item-value="value"
                            >
                              <template v-slot:append-item>
                                <v-menu v-model="menu_scegli_giorno">
                                  <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on">
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >Il ...</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                  <v-date-picker
                                    v-model="day_select"
                                    @change="imposta_giorno_man()"
                                  ></v-date-picker>
                                </v-menu>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              outlined
                              hide-details
                              label="Orario"
                              :items="orari"
                              v-model="orario_consegna"
                              item-text="label"
                              item-value="value"
                            ></v-select>
                          </v-col>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            :disabled="!giorno_consegna || !orario_consegna"
                            outlined
                            color="green"
                            @click="cambia_giorno_consegna()"
                            >cambia</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import moment from "moment";
export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  props: {
    consegna: Object,
  },
  data() {
    return {
      dialog: false,
      dialog_aggiungi: false,
      dialog_loading: false,
      menu_cambia_data: false,
      active_menu_index: [],
      center: { lat: 44.542, lng: 7.678 },
      ordini_localizzati: [],
      tappe: [],
      map_with: 9,
      array_colori: [
        { hex: "#F44336", name: "red" },
        { hex: "#9C27B0", name: "purple" },
        { hex: "#4CAF50", name: "green" },
        { hex: "#03A9F4", name: "light-blue" },
        { hex: "#FF9800", name: "orange" },
        { hex: "#009688", name: "teal" },
        { hex: "#E91E63", name: "pink" },
      ],
      giorni: [
        { label: "Oggi", value: moment().startOf("day") },
        {
          label: "Domani",
          value: moment().add(1, "day").startOf("day"),
        },
      ],
      orari: [
        { label: "Prima Mattina", value: 8 },
        { label: "Tarda Mattina", value: 10 },
        { label: "Primo Pomeriggio", value: 14 },
        { label: "Tardo Pomeriggio", value: 16 },
      ],
      menu_scegli_giorno: false,
      day_select: null,
      giorno_consegna: null,
      orario_consegna: null,
      id_mezzo: this.consegna.automezzo,
      index_inserimento: null,
      dialog_avvia_consegna: false,
      mezzo_consegna_in_azienda: null,
      avviso_avvio: false,
      annulla_loading: false,
      loading_programma_consegna: false,
      dialog_termina: false,
      loading_termina: false,
      id_linea_evidenziata: null,
      snack_mezzo_offline: false,
      avvia_loading: false,
      window_height: window.innerHeight,
      show_trace: false,
      storico_consegna: [],
    };
  },
  mounted() {
    window.onresize = () => {
      this.window_height = window.innerHeight;
    };
  },
  watch: {
    show_trace: function () {
      if (this.show_trace) {
        this.ottieni_storico_consegna();
      }
    },
    truck_position: function () {
      if (this.show_trace) {
        this.ottieni_storico_consegna();
      }
    },
    id_mezzo: function () {
      this.$store.dispatch("cambia_mezzo_consegna", {
        id_consegna: this.consegna.id,
        id_mezzo: this.id_mezzo,
      });
    },
  },
  computed: {
    ordini() {
      return this.$store.getters.get_ordini_attivi
        .filter(
          (ord) =>
            (ord.stato == 8 || ord.stato == 1) &&
            ord.ritiro_cod == "1" &&
            ord.joined_in == false &&
            !ord.consegna_programmata
        )
        .map((ordine) => {
          return {
            ...ordine,
            cliente_obj: this.$store.getters.get_cliente_by_iva(ordine.iva),
            cliente: this.$store.getters.get_cliente_by_iva(ordine.iva).nome,
          };
        });
    },
    mezzi() {
      return this.$store.getters.get_automezzi;
    },
    mezzo_consegna() {
      return this.$store.getters.get_automezzi.find(
        (mezzo) => mezzo.id == this.consegna.automezzo
      );
    },
    truck_position() {
      if ("latitudine" in this.mezzo_consegna) {
        return {
          lat: this.mezzo_consegna.latitudine,
          lng: this.mezzo_consegna.longitudine,
        };
      } else {
        return null;
      }
    },
    carrelli_tot() {
      return this.consegna.tappe
        .filter((tappa) => !tappa.ritorno)
        .map((tappa) => this.$store.getters.get_qta_cc_ordine(tappa.ordine))
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },
    tempo_viaggio() {
      let seconds = this.consegna.tappe
        .map((tappa) => tappa.durata.value)
        .reduce((tot, num) => tot + num, 0);
      let duration = moment.duration(seconds, "seconds");
      return {
        value: seconds / 60,
        text: duration.hours() + " ore e " + duration.minutes() + " minuti",
      };
    },
    tempo_scarico_totale() {
      let minutes = this.consegna.tappe
        .filter((tappa) => !tappa.ritorno)
        .map((tappa) => this.$store.getters.get_ordine_by_id(tappa.ordine))
        .map((ordine) => {
          let tot_cc = this.$store.getters.get_qta_cc_ordine(ordine._id.$oid);
          let tempi = this.$store.getters.get_tempi;
          if (this.$store.getters.get_cliente_by_iva(ordine.iva).cambio_cc) {
            return tot_cc * tempi.tempo_cambio_cc + tempi.tempo_pagamento;
          } else {
            return tot_cc * tempi.tempo_scarico_cc + tempi.tempo_pagamento;
          }
        })
        .reduce((tot, num) => tot + num, 0);
      let duration = moment.duration(minutes, "minutes");
      return {
        value: minutes,
        text: duration.hours() + " ore e " + duration.minutes() + " minuti",
      };
    },
    tempo_totale_viaggio() {
      if (!this.consegna.tappe.length) {
        return 0;
      }
      let minutes = this.tempo_viaggio.value + this.tempo_scarico_totale.value;
      // if (this.consegna.tappe[this.consegna.tappe.length - 1].ritorno) {
      //   minutes +=
      //     this.consegna.tappe[this.consegna.tappe.length - 1].durata.value / 60;
      // }
      let duration = moment.duration(minutes, "minutes");
      return duration.hours() + " ore e " + duration.minutes() + " minuti";
    },
    ordini_localizzati_destinati() {
      let lista_ordini = this.ordini;
      let lista_zona = _.groupBy(lista_ordini, "cliente_obj.zona");
      let lista_finale = [];
      Object.keys(lista_zona).forEach((zona) => {
        lista_finale.push({ zona: zona, ordini: lista_zona[zona] });
      });
      return lista_finale;
    },
    partenza_prevista() {
      return moment
        .unix(this.consegna.ts_partenza_prevista)
        .format("dddd HH:mm");
    },
    partenza_effettiva() {
      return moment
        .unix(this.consegna.ts_partenza)
        .format("[alle] HH:mm [del] DD/MM");
    },
    arrivo_effettivo() {
      return moment
        .unix(this.consegna.ts_arrivo)
        .format("[alle] HH:mm [del] DD/MM");
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    ordine(id) {
      return this.$store.getters.get_ordine_by_id(id);
    },
    icona_tappa(tappa) {
      if (!tappa.raggiunta && !tappa.completa) {
        return "mdi-map-marker";
      }
      if (tappa.raggiunta && !tappa.completa) {
        return "mdi-human-dolly";
      }
      if (tappa.raggiunta && tappa.completa) {
        return "mdi-check";
      }
    },
    async get_coords() {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/";
      const promises = this.ordini.map((ordine) =>
        axios.get(url + ordine._id.$oid + "/coordinate", { auth: auth })
      );
      const response = await Promise.all(promises);
      this.punti_ordine = response.map((res) => res.data[0].geometry.location);
      console.log(this.punti_ordine);
    },
    ottieni_coordinate_ordini() {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/";
      this.ordini_localizzati = [];
      this.ordini.forEach((ordine) => {
        axios
          .get(url + ordine._id.$oid + "/coordinate", { auth: auth })
          .then((res) => {
            this.ordini_localizzati.push({
              ...ordine,
              coord: res.data,
            });
          });
      });
    },
    ottieni_storico_consegna() {
      let auth = this.$store.getters.get_credential;
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/automezzo/" +
        this.consegna.automezzo +
        "/consegna/" +
        this.consegna.id;
      axios.get(url, { auth: auth }).then((res) => {
        this.storico_consegna = res.data;
      });
    },
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
    apri_dialog_aggiungi(index) {
      console.log(
        "[CONSEGNA DETTAGLIO TAPPA] Tappa da aggiungere dopo index: " + index
      );
      this.ottieni_coordinate_ordini();
      this.index_inserimento = index;
      this.dialog_aggiungi = true;
    },
    aggiungi_tappa(id_ordine) {
      this.dialog_loading = true;
      this.dialog_aggiungi = false;
      this.$store
        .dispatch("aggiungi_tappa_consegna", {
          id_ordine: id_ordine,
          id_consegna: this.consegna.id,
          index: this.index_inserimento,
        })
        .then(() => {
          this.dialog_loading = false;
        });
    },
    rimuovi_tappa(id_tappa) {
      this.dialog_loading = true;
      this.$store
        .dispatch("rimuovi_tappa_consegna", {
          id_tappa: id_tappa,
          id_consegna: this.consegna.id,
        })
        .then(() => {
          this.dialog_loading = false;
          this.dialog_aggiungi = false;
        });
    },
    calcola_percorso(payload) {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/calcola_punto_punto";
      let json = [payload.partenza, payload.arrivo];
      axios.post(url, json, { auth: auth }).then((res) => {
        console.log(res);
        this.tappe.push({
          ...res.data,
          id_ordine: payload.id_ordine,
          ritorno: payload.ritorno,
        });
        this.active_menu_index = [];
      });
    },
    trova_valori_viaggio(id_ordine) {
      let index = this.tappe.findIndex(
        (ordine) => ordine.id_ordine == id_ordine
      );
      if (index < 0) {
        return null;
      }
      return { index: index, value: this.tappe[index] };
    },
    imposta_giorno_man() {
      let mom = moment(this.day_select);
      if (!this.giorni[2]) {
        this.giorni.push({ label: "", value: null });
      }
      this.giorni[2].label = mom.format("dddd");
      this.giorni[2].value = mom;
      this.giorno_consegna = mom;
      this.menu_scegli_giorno = false;
    },
    salva_consegna() {
      this.$store
        .dispatch("salva_consegna", {
          tappe: this.tappe,
          ts_partenza_prevista: this.giorno_consegna
            .hours(this.orario_consegna)
            .format("X"),
          id_mezzo: this.id_mezzo,
        })
        .then(() => {
          this.dialog = false;
        });
    },
    cambia_giorno_consegna() {
      this.$store
        .dispatch("cambia_data_prevista_consegna", {
          id_consegna: this.consegna.id,
          ts_consegna: this.giorno_consegna
            .hours(this.orario_consegna)
            .format("X"),
        })
        .then(() => {
          this.menu_cambia_data = false;
        });
    },
    swipe() {
      if (this.map_with == 6) {
        this.map_with = 9;
      } else {
        this.map_with = 6;
      }
    },
    // apri_dialog_avvia_consegna() {
    //   this.$store
    //     .dispatch("mezzo_consegna_in_azienda", this.consegna.id)
    //     .then((res) => {
    //       console.log("Mezzo in azienda: " + res);
    //       if (res) {
    //         this.avviso_avvio = true;
    //         this.$store.dispatch("avvia_consegna", this.consegna.id);
    //       } else {
    //         this.dialog_avvia_consegna = true;
    //       }
    //     });
    // },
    annulla_consegna() {
      this.annulla_loading = true;
      this.$store.dispatch("annulla_consegna", this.consegna.id).then(() => {
        this.annulla_loading = false;
      });
    },
    apri_dialog_avvia_consegna() {
      this.loading_programma_consegna = true;
      let last_update = this.$store.getters.get_mezzo_by_id(
        this.consegna.automezzo
      ).last_update;
      console.log(last_update);
      let difference = new Date().getTime() / 1000 - last_update;
      console.log(difference < 10 * 60);
      let isOnline = difference < 10 * 60;
      this.$store
        .dispatch("mezzo_consegna_in_azienda", this.consegna.id)
        .then((res) => {
          console.log("Mezzo in azienda: " + res);
          this.loading_programma_consegna = false;
          if (!isOnline) {
            console.log("Il mezzo è offline. La consegna non può partire");
            this.snack_mezzo_offline = true;
            return;
          }
          if (res) {
            this.avviso_avvio = true;
            this.$store.dispatch("avvia_consegna", this.consegna.id);
          } else {
            this.dialog_avvia_consegna = true;
          }
        });
    },
    avvia_consegna_immediato() {
      this.avvia_loading = true;
      console.log("Avvia Consegna");
      this.$store
        .dispatch("avvia_consegna_immediato", this.consegna.id)
        .then(() => {
          this.avvia_loading = false;
        });
    },
    programma_consegna() {
      this.loading_programma_consegna = true;
      this.$store
        .dispatch("programma_consegna", {
          id_consegna: this.consegna.id,
          id_mezzo: this.id_mezzo,
        })
        .then(() => {
          this.loading_programma_consegna = false;
          this.dialog_avvia_consegna = false;
        });
    },
    termina_consegna() {
      this.loading_termina = true;
      this.$store.dispatch("termina_consegna", this.consegna.id).then(() => {
        this.loading_termina = false;
        this.dialog_termina = false;
      });
    },
    orario_completamento(ts) {
      return moment.unix(ts).format("[alle] HH:mm [del] DD/MM");
    },
    evidenzia_linea(id_tappa) {
      console.log(id_tappa);
      this.id_linea_evidenziata = id_tappa;
    },
    evidenzia_linea_ritorno() {
      this.id_linea_evidenziata = this.consegna.tappe.find(
        (tappa) => tappa.ritorno
      ).id;
    },
    pulisci_linea() {
      this.id_linea_evidenziata = null;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 310px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>
