<template>
  <v-list-item
    @click="$refs.messaggio_dialog.open()"
    @contextmenu="show_del_menu"
    three-line
  >
    <v-list-item-icon
      class=""
      :class="$vuetify.breakpoint.mobile ? 'ml-n4' : 'mt-6'"
    >
      <v-badge overlap color="pink" :value="n_non_letti" :content="n_non_letti">
        <v-avatar color="grey lighten-2" size="40">
          <v-icon color="white"> mdi-account </v-icon>
        </v-avatar>
      </v-badge>
    </v-list-item-icon>

    <v-list-item-content class="ml-n3">
      <v-list-item-title>
        <span class="d-inline-block text-truncate" style="max-width: 90%">{{
          chat.autore.nome
        }}</span>
      </v-list-item-title>
      <v-list-item-subtitle v-if="chat.destinatario">
        In carico a
        <span class="green--text">{{ chat.destinatario.nome }}</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else>
        <span class="blue--text"> Non in carico</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="ultimo_msg" class="text-truncate">
        <template v-if="ultimo_msg.msg.isText">
          <span class="d-inline-block text-truncate" style="max-width: 90%">
            {{ ultimo_msg.msg.testo }}
          </span>
        </template>
        <template v-if="ultimo_msg.msg.isFile">
          <v-icon color="blue" size="15" class="mr-2">mdi-file</v-icon>
          {{ ultimo_msg.msg.file_name }}.{{ ultimo_msg.msg.file_type }}
        </template>
        <template v-if="ultimo_msg.msg.isImg">
          <v-icon color="purple" size="15" class="mr-2">mdi-camera</v-icon> Foto
        </template>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text v-if="ultimo_msg">
      <v-row justify="end" align="center" class="mb-n4 green--text" v-if="autore.is_connected">
        <v-icon size="10" color="green" class="mr-2">mdi-circle</v-icon> Online
      </v-row>
      <v-row>
        {{ ultimo_msg.data }}
      </v-row>
      <v-row justify="end">
        <template v-if="ultimo_msg.msg.autore === mia_iva">
          <template v-if="chat.autore.no_subscription">
            <v-icon color="red" size="15" class="mr-2">
              mdi-comment-remove-outline
            </v-icon>
          </template>
          <template v-else>
            <v-icon
              v-if="ultimo_msg.msg.notificato"
              color="green"
              size="15"
              class="mr-2"
            >
              mdi-comment-check-outline
            </v-icon>
            <v-icon
              v-if="!ultimo_msg.msg.notificato"
              color="amber"
              size="15"
              class="mr-2"
            >
              mdi-comment-alert-outline
            </v-icon>
          </template>
          <v-icon v-if="ultimo_msg.msg.letto == 2" color="blue" size="15"
            >mdi-check-all</v-icon
          >
          <v-icon v-if="ultimo_msg.msg.letto == 1" color="grey" size="15"
            >mdi-check-all</v-icon
          >
          <v-icon v-if="ultimo_msg.msg.letto == 0" color="grey" size="15"
            >mdi-check</v-icon
          >
        </template>
      </v-row>
    </v-list-item-action-text>
    <MessaggioDialog
      :show="show"
      :chat="chat"
      @close="show = false"
      ref="messaggio_dialog"
    />
    <v-menu
      v-model="del_menu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="archivia_chat()">
          <v-list-item-icon>
            <v-icon color="purple">mdi-package-down</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Archivia</v-list-item-title>
        </v-list-item>
        <v-list-item @click="elimina_chat()">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Elimina</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>

<script>
import moment from "moment";
import MessaggioDialog from "./messaggio_dialog.vue";
export default {
  components: {
    MessaggioDialog,
  },
  props: {
    chat: Object,
  },
  data() {
    return {
      show: false,
      del_menu: false,
      y: 0,
      x: 0,
    };
  },
  computed: {
    iniziale() {
      return this.chat.autore.nome.slice(0, 1);
    },
    ultimo_msg() {
      if (this.chat.messaggi.length) {
        return {
          msg: this.chat.messaggi.at(-1),
          data: moment.unix(this.chat.messaggi.at(-1).ts_creazione).calendar(),
        };
      } else {
        return null;
      }
    },
    n_non_letti() {
      return this.$store.getters.get_chat_nl(this.chat._id);
    },
    mia_iva() {
      return this.$store.getters.get_utente.iva;
    },
    autore(){
      return this.$store.getters.get_cliente_by_iva(this.chat.autore.iva)
    }
  },
  methods: {
    show_del_menu(e) {
      e.preventDefault();
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.del_menu = true;
      });
    },
    archivia_chat() {
      this.$store.dispatch("archive_chat", this.chat._id);
    },
    elimina_chat() {
      this.$store.dispatch("delete_chat", this.chat._id);
    },
  },
};
</script>