<template>
  <v-dialog v-model="menu_add" width="800" top :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn color="blue" outlined v-on="on"
        ><v-icon class="mr-2">mdi-plus</v-icon>Aggiungi</v-btn
      >
    </template>

    <v-card min-height="20">
      <v-card-title> Aggiungi prodotto </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-text-field
            v-model="nome_prodotto"
            append-icon="mdi-delete"
            label="Seleziona Prodotto"
            outlined
            @click:append="nome_prodotto = ''"
          ></v-text-field>
        </v-row>
        <v-expand-transition>
          <v-card
            flat
            min-height="80"
            max-height="180"
            color=""
            class="overflow-auto mb-2"
            v-if="nome_prodotto.length > 2"
          >
            <v-row no-gutters>
              <v-list-item-group :value="selectedItem" color="green">
                <v-list-item
                  v-for="prodotto in lista_prodotti"
                  :key="prodotto.id"
                  @click="prodotto_sel_id = prodotto.id"
                >
                  <v-list-item-avatar>
                    <img :src="avatar_img(prodotto.id)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ prodotto.nome }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      prodotto.sotto_categoria
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-row>
          </v-card>
        </v-expand-transition>
        <v-divider />

        <!-- <v-autocomplete
            v-model="prodotto_sel_id"
            outlined
            hide-details
            label="Seleziona Prodotto"
            :items="prodotti"
            item-text="nome"
            item-value="id"
            clearable
          >
            <template v-slot:selection="data">
              <v-chip color="white">
                <v-avatar size="50" left>
                  <v-img :src="avatar_img(data.item.id)"></v-img>
                </v-avatar>

                <span>
                  {{ data.item.nome }}
                </span>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <img :src="avatar_img(data.item.id)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  data.item.sotto_categoria
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete> -->

        <v-expand-transition>
          <v-card
            min-height="80"
            v-if="prodotto_sel != null"
            tile
            elevation="0"
          >
            <v-card-subtitle class="mb-n6"> Colori </v-card-subtitle>
            <v-row class="mt-4" no-gutters>
              <!-- <v-col v-for="col in prodotto_sel.colori" :key="col.id">
            {{col}}
          </v-col> -->
              <Colore
                v-for="col in prodotto_sel.colori"
                :ordine="ordine"
                :prodotto="prodotto_sel.id"
                :colore="col"
                :key="col.id"
              />
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="commento"
                  outlined
                  label="Commento"
                  hide-details
                >
                  <template v-slot:append>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-progress-circular
                        indeterminate
                        color="grey"
                        width="1"
                        size="20"
                        v-if="loading_commento"
                      />
                      <v-icon color="success" v-if="commento_ok"
                        >mdi-check</v-icon
                      >
                    </v-slide-x-reverse-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="grey" @click="menu_add = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Colore from "./ordine_aggiungi_colore.vue";
import ordine_aggiungi_prodotto_mixin from "../../mixin/ordine_dettaglio/ordine_aggiungi_prodotto_mixin";
export default {
  components: {
    Colore,
  },
  mixins: [ordine_aggiungi_prodotto_mixin],
  // props: {
  //   ordine: Object,
  // },
  // data() {
  //   return {
  //     menu_add: false,
  //     prodotto_sel_id: null,
  //   };
  // },
  // computed: {
  //   prodotti() {
  //     return this.$store.getters.get_lista_prodotti_raw;
  //   },
  //   prodotto_sel() {
  //     return this.$store.getters.get_prodotto_by_id(this.prodotto_sel_id);
  //   },
  // },
  // methods: {
  //   avatar_img(id) {
  //     return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
  //   },
  // },
};
</script>
