<template>
  <v-card height="400">
    <v-card-title class="grey--text text-subtitle-1">
      Accessi Cliente
      <v-spacer />
      <DialogLogs />
    </v-card-title>

    <v-card-text class="scroll">
      <v-row no-gutters align="center" class="font-weight-medium mt-n5">
        <v-col cols="4"> Cliente </v-col>
        <v-col cols="4">
          <!-- <v-switch inset dense v-model="cart_only" /> -->
        </v-col>
        <v-col cols="4" class="text-truncate"> Ultimo accesso </v-col>
      </v-row>
      <template v-if="loading">
        <v-row justify="center" align="center" class="py-12">
          <v-progress-circular color="green" width="2" indeterminate />
        </v-row>
      </template>
      <template v-else>
        <v-row
          v-for="cliente in lista_cli"
          :key="cliente.id"
          class="py-1 mt-n2"
          no-gutters
          align="center"
        >
          <v-col cols="4" class="text-truncate">
            <v-icon
              :color="cliente.is_connected ? 'green' : 'amber'"
              size="10"
              class="mr-2"
              >mdi-circle</v-icon
            >
            {{ cliente ? cliente.nome : "" }}
          </v-col>
          <v-col cols="4">
            <Carrello
              :cliente="cliente"
              v-if="cliente.has_cart"
            />
          </v-col>
          <v-col cols="4">
            {{
              cliente.is_connected
                ? "Online"
                : data_format(cliente.last_access)
            }}
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import Carrello from "./carrello_cliente";
import _ from "lodash";
import DialogLogs from "./dialog_log_clienti.vue";
export default {
  components: {
    Carrello,
    DialogLogs,
  },
  data() {
    return {
      loading: true,
      cart_only: false,
      ts_inizio: moment().subtract(3, "days").format("X"),
      ts_fine: moment().format("X"),
    };
  },
  mounted() {
    this.$store
      .dispatch("fetch_accessi_clienti", {
        ts_inizio: this.ts_inizio,
        ts_fine: this.ts_fine,
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    lista_clienti_online() {
      return this.$store.getters.get_clienti_online;
    },
    lista_clienti() {
      let list = this.$store.getters.get_accessi_cliente
        .filter((log) => log.event == 1)
        .map((log) => {
          return {
            cliente: this.$store.getters.get_cliente_by_iva(log.iva),
            ts: log.ts,
          };
        })
        .filter((el) => !el.cliente.isAdmin)
        .sort((a, b) => b.cliente.last_access - a.cliente.last_access);
      list = _.uniqBy(list, "cliente.iva");
      return this.cart_only
        ? list.filter((cliente) => cliente.cliente.has_cart)
        : list;
    },
    lista_cli(){
      return this.$store.getters.get_clienti
      .filter(cli => cli.last_access > moment().subtract(3, 'days').format("X"))
      .sort((a,b)=> b.last_access - a.last_access)
    }
  },
  methods: {
    data_format(ts) {
      return moment().to(moment.unix(ts));
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 330px;
  overflow-y: scroll;
}
</style>