import axios from "axios";

export default {
  state: {
    trasporto: {},
    soglie_orari: {},
    stampante_remota: {},
  },
  getters: {
    get_trasporto: (state) => {
      return state.trasporto;
    },
    get_soglie_orari: (state) => {
      return state.soglie_orari;
    },
    get_stampante_remota: (state) => {
      return state.stampante_remota;
    },
  },
  mutations: {
    set_trasporto(state, trasporto) {
      state.trasporto = trasporto;
    },
    set_soglie_orari(state, soglie) {
      state.soglie_orari = soglie;
    },
    set_stampante_remota(state, stamp) {
      state.stampante_remota = stamp;
    },
    set_ip_stampante(state, ip) {
      state.stampante_remota.ip = ip;
    },
    set_porta_stampante(state, porta) {
      state.stampante_remota.porta = porta;
    },
    set_prezzo_trasporto_1(state, prezzo) {
      state.trasporto.prezzo_listino_1 = parseInt(prezzo);
    },
    set_prezzo_trasporto_2(state, prezzo) {
      state.trasporto.prezzo_listino_2 = parseInt(prezzo);
    },
    set_prezzo_trasporto_3(state, prezzo) {
      state.trasporto.prezzo_listino_3 = parseInt(prezzo);
    },
    set_soglia_trasporto(state, prezzo) {
      state.trasporto.soglia = parseInt(prezzo);
    },
    set_orario_apertura(state, orario) {
      state.soglie_orari.apertura = orario;
    },
    set_orario_chiusura(state, orario) {
      state.soglie_orari.chiusura = orario;
    },
    set_tempo_prep_cc(state, minuti) {
      state.soglie_orari.t_prep_cc = minuti;
    },
    set_printer_status(state, status) {
      state.stampante_remota.statsu = status;
    },
  },
  actions: {
    fetch_trasporto(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/trasporto_admin";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_trasporto", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_soglie_orari(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/orari";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_soglie_orari", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_stampante_remota(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/stampante_remota";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_stampante_remota", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_ip_stampante(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/ip_stampante/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_ip_stampante", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_porta_stampante(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/porta_stampante/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_porta_stampante", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_spesa_trasporto_1(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/prezzo_trasporto_1/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_prezzo_trasporto_1", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_spesa_trasporto_2(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/prezzo_trasporto_2/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_prezzo_trasporto_2", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_spesa_trasporto_3(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/prezzo_trasporto_3/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_prezzo_trasporto_3", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_soglia_trasporto(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/soglia_trasporto/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_soglia_trasporto", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_orario_apertura(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/orario_apertura/" + payload;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_orario_apertura", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_orario_chiusura(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/orario_chiusura/" + payload;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_orario_chiusura", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_tempo_prep_cc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/tempo_prep_cc/" + payload;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_tempo_prep_cc", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_PRINTER_STATUS(context, payload) {
      context.commit("set_printer_status", payload);
    },
  },
};
