<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-title class="grey--text"> Invia Broadcast </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              outlined
              label="Zona"
              :items="zone"
              v-model="zona"
              clearable
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="clienti_sel"
              :items="clienti_filter"
              chips
              color="blue-grey lighten-2"
              label="Clienti"
              item-text="nome"
              item-value="iva"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-icon>mdi-account</v-icon>
                  </v-avatar>
                  {{ data.item.nome }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon size="15" color="green">mdi-whatsapp</v-icon>
                      {{ data.item.telefono }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon size="15">mdi-city</v-icon>
                      {{ data.item.zona }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-row>
          <v-col cols="11"> Tipi di Annuncio </v-col>
          <v-col cols="1">
            <v-dialog v-model="dialog_new_text" width="600">
              <template v-slot:activator="{ on }">
                <v-btn icon class="ml-4" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="grey--text">
                  Tipo Annuncio Whatsapp
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    outlined
                    v-model="text_modello"
                    label="Testo"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    v-model="id_modello"
                    label="Id"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn outlined @click="send_new_text()" color="green"
                    >Invia</v-btn
                  >
                  
                  <v-btn text color="grey" @click="dialog_new_text = false"
                    >Chiudi</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row no-gutters class="">
          <v-col>
            <v-radio-group v-model="radios">
              <v-radio
                v-for="modello in fix_texts"
                :key="modello.id.$oid"
                :value="modello.id_modello"
              >
                <template v-slot:label>
                  <v-row no-gutters align="center">
                    <v-col cols="11">
                      {{ modello.text }}
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        icon
                        color="red"
                        @click="delete_text(modello.id.$oid)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" text @click="close()">chiudi</v-btn>
        <v-btn
          outlined
          color=" green"
          :loading="loading"
          :disabled="!clienti_sel.length || !this.to_send"
          @click="invia_broadcast()"
          ><v-icon class="mr-2">mdi-send</v-icon>Invia</v-btn
        >
        <!-- BUTTON PER INVIO IMMAGINE
        <v-btn
          outlined
          color=" green"
          :loading="loading"
          :disabled="!clienti_sel.length || !this.to_send"
          @click="invia_broadcast_immagine()"
          ><v-icon class="mr-2">mdi-send</v-icon>Invia Immagine</v-btn
        >
        -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import broadcast_mixin from "../mixin/broadcast_mixin";
export default {
  mixins: [broadcast_mixin],
};
</script>
