<template>
  <v-card :height="height">
    <v-card-title class="grey--text text-subtitle-1">
      Consegne Attive
      <v-btn icon @click="expand()" class="ml-4">
        <v-icon>{{
          is_expanded ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <v-spacer />
      <NuovaConsegna />
    </v-card-title>

    <v-card-text :class="is_expanded ? 'scroll_extended' : 'scroll'">
      <v-divider class="mb-2"></v-divider>
      <v-row class="font-weight-medium">
        <v-col cols="1" class="d-flex justify-center"># </v-col>
        <v-col cols="2"> Clienti </v-col>
        <v-col cols="2"> Partenza Prevista </v-col>
        <v-col cols="2" class="d-flex justify-center"> Stato </v-col>
        <v-col cols="1"> Carrelli </v-col>
        <v-col cols="2"> Durata </v-col>
      </v-row>
      <v-divider class="mt-2 mb-4" />

      <ConsegnaEl
        v-for="consegna in consegne"
        :consegna="consegna"
        :key="consegna.id"
      />
      <v-row class="py-8" v-if="!consegne.length" justify="center">
        Nessuna Consegna in Programma
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NuovaConsegna from "./mappa_consegne.vue";
import ConsegnaEl from "./consegna_element_desktop copy";
export default {
  components: {
    NuovaConsegna,
    ConsegnaEl,
  },
  data() {
    return {
      is_expanded: false,
      height: 400,
    };
  },
  computed: {
    consegne() {
      let cons = this.$store.getters.get_consegne.slice()
      return cons.sort((a,b)=> a.ts_partenza_prevista - b.ts_partenza_prevista);
    },
  },
  methods: {
    expand() {
      if (this.is_expanded) {
        this.is_expanded = false;
        this.height = 400;
      } else {
        this.is_expanded = true;
        this.height = 700;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 310px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>
