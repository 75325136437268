import axios from "axios";

export default {
  state: {
    clienti: [],
  },
  getters: {
    get_clienti_online: (state) => {
      return state.clienti.filter(
        (cliente) => cliente.is_connected && !cliente.isAdmin
      );
    },
    get_clienti: (state) => {
      return state.clienti;
    },
    get_cliente_by_iva: (state) => (iva) => {
      return state.clienti.find((cliente) => cliente.iva == iva);
    },
    get_cliente_by_phone: (state) => (phone) => {
      return state.clienti.find((cliente) => '39' + cliente.telefono ==  phone);
    },
  },
  mutations: {
    set_clienti(state, clienti) {
      state.clienti = clienti;
    },
    set_utente_connesso(state, iva) {
      state.clienti.find((cliente) => cliente.iva == iva).is_connected = true;
    },
    set_utente_disconnesso(state, payload) {
      let cli = state.clienti.find((cliente) => cliente.iva == payload.iva);
      cli.is_connected = false;
      cli.last_access = payload.last_access;
    },
    set_utente_email(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).email =
        payload.email;
    },
    set_utente_nome(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).nome =
        payload.nome;
    },
    set_utente_aka(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).aka =
        payload.aka;
    },
    set_utente_tel(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).telefono =
        payload.tel;
    },
    set_utente_indirizzo(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).indirizzo =
        payload.indirizzo;
    },
    set_utente_plus_code(state, payload) {
      let cliente = state.clienti.find((cliente) => cliente.iva == payload.iva);
      cliente.plus_code = payload.plus_code;
      cliente.destinazioni.find((dest) => dest.primaria).plus_code =
        payload.plus_code;
    },
    set_utente_città(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).città =
        payload.città;
    },
    set_utente_listino(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).listino =
        payload.listino;
    },
    set_utente_zona(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).zona =
        payload.zona;
    },
    set_utente_cambio_cc(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).cambio_cc =
        payload.cambio;
    },
    set_utente_tipo_cc(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).tipo_cc =
        payload.tipo;
    },
    set_utente_stato(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).stato =
        payload.status;
    },
    set_nuovo_utente(state, payload) {
      state.clienti.push(payload);
    },
    set_utente_eliminato(state, iva) {
      let index = state.clienti.findIndex((cliente) => cliente.iva == iva);
      state.clienti.splice(index, 1);
    },
    set_has_cart(state, payload) {
      state.clienti.find((cliente) => cliente.iva == payload.iva).has_cart =
        payload.status;
    },
    set_ultima_destinzaione_cliente(state, payload) {
      state.clienti.find(
        (cliente) => cliente.iva == payload.iva
      ).ultima_destinazione = payload.destinazione;
    },
    set_nuova_dest(state, payload) {
      state.clienti
        .find((cliente) => cliente.iva == payload.iva)
        .destinazioni.push(payload.dest);
    },
    del_dest(state, payload) {
      let cliente = state.clienti.find((cliente) => cliente.iva == payload.iva);
      let index = cliente.destinazioni.findIndex(
        (dest) => dest.indirizzo == payload.indirizzo
      );
      cliente.destinazioni.splice(index, 1);
    },
    set_utente_numero(state, payload) {
      let cliente = state.clienti.find((cliente) => cliente.iva == payload.iva);
      cliente.numero = payload.numero;
    },
  },
  actions: {
    fetch_clienti(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/clienti";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_clienti", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_nome(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/nome";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { nome: payload.nome }, { auth: auth })
          .then(() => {
            context.commit("set_utente_nome", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_aka(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/aka";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { aka: payload.aka }, { auth: auth })
          .then(() => {
            context.commit("set_utente_aka", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_email(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/email";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { email: payload.email }, { auth: auth })
          .then(() => {
            context.commit("set_utente_email", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_tel(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/telefono";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { tel: payload.tel }, { auth: auth })
          .then(() => {
            context.commit("set_utente_tel", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_indirizzo(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/indirizzo";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { indirizzo: payload.indirizzo }, { auth: auth })
          .then(() => {
            context.commit("set_utente_indirizzo", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_plus_code(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/cliente/" +
          payload.iva +
          "/plus_code";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { plus_code: payload.plus_code }, { auth: auth })
          .then(() => {
            context.commit("set_utente_plus_code", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_città(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/città";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { città: payload.città }, { auth: auth })
          .then(() => {
            context.commit("set_utente_città", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_listino(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/listino";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { listino: payload.listino }, { auth: auth })
          .then(() => {
            context.commit("set_utente_listino", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_zona(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/zona";
        let auth = context.getters.get_credential;
        axios
          .patch(url, { zona: payload.zona }, { auth: auth })
          .then(() => {
            context.commit("set_utente_zona", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_cambio_cc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/cambio_cc/" +
          payload.cambio;
        let auth = context.getters.get_credential;
        axios
          .patch(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_utente_cambio_cc", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_tipo_cc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/tipo_cc/" +
          payload.tipo;
        let auth = context.getters.get_credential;
        axios
          .patch(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_utente_tipo_cc", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_numero(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/numero/" +
          payload.numero;
        let auth = context.getters.get_credential;
        axios
          .patch(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_utente_numero", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    attivazione_cliente(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/wellcome/" + payload.iva;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_utente_stato", { iva: payload.iva, status: 1 });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    nuovo_cliente(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/clienti";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_nuovo_utente", res.data);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    status_cliente(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/clienti/" +
          payload.iva +
          "/accesso/" +
          payload.status;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_utente_stato", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_cliente(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/clienti/" + payload.iva;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_utente_eliminato", payload.iva);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_integrità(context, iva) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/cliente/" +
          iva +
          "/integrità/0";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_nuova_dest(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/cliente/" +
          payload.iva +
          "/destinazione";
        let auth = context.getters.get_credential;
        axios
          .post(
            url,
            {
              destinazione: payload.destinazione,
              plus_code: payload.plus_code,
            },
            { auth: auth }
          )
          .then((res) => {
            context.commit("set_nuova_dest", {iva: payload.iva, dest: res.data});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    rimuovi_dest(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/cliente/" +
          payload.iva +
          "/destinazione/" +
          payload.destinazione;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("del_dest", payload);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_USER_CONNECTED(context, iva) {
      if (iva != "admin") {
        context.commit("set_utente_connesso", iva);
      }
    },
    SOCKET_USER_DISCONNECTED(context, payload) {
      context.commit("set_utente_disconnesso", payload);
    },
    SOCKET_ADD_CART(context, iva) {
      context.commit("set_has_cart", { iva: iva, status: true });
    },
    SOCKET_CART_EMPTY(context, iva) {
      context.commit("set_has_cart", { iva: iva, status: false });
    },
  },
  modules: {},
};
