<template>
  <div>
    <v-row
      class="pa-1 font-weight-medium"
      justify="start"
      align="center"
      no-gutters
    >
      <v-col cols="1">
        <v-btn icon color="primary" @click="show_color = !show_color">
          <v-icon>{{
            show_color ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <b>{{ tot_pezzi }}</b>
      </v-col>
      <v-col cols="3" class="text-truncate" @click="show_color = !show_color">
        <v-list-item class="ml-n4">
          <v-list-item-avatar size="40">
            <v-avatar size="40" class="mr-2">
              
              <v-img :src="avatar_img"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-body-1 font-weight-light"
                >{{ nome }} Ø {{ prodotto.diametro }}</span
              >
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="font-weight-light" style="font-size: 14px">{{
                tipo_vaso
              }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="3" class="pl-2" v-if="$vuetify.breakpoint.width > 600">
        {{ item.commento }}
      </v-col>
      <v-col cols="2"> € {{ item.prezzo.toFixed(2) }} </v-col>
      <v-col cols="1"> € {{ prezzo_tot }} </v-col>
      <v-col cols="1">
        <v-dialog v-model="dialog_elimina" width="400">
          <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon color="red" v-on="{ ...tooltip, ...dialog }">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Rimuovi dal carrello
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="red--text"> Rimuovi </v-card-title>
            <v-card-text> Sei sicuro? </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="primary" @click="dialog_elimina = false">
                annulla
              </v-btn>
              <v-btn color="red" outlined dark @click="delete_prod()">
                <v-icon>mdi-delete</v-icon>
                elimina
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn color="green" dark small fab icon @click="edit_prod()">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="show_color">
      <v-row align="center" no-gutters class="pa-1 mb-1 ml-6">
        <v-col
          cols="12"
          v-for="colore in item.colori"
          :key="colore.id"
          class="text-truncate pa-1"
        >
          <v-row no-gutters align="center" class="ml-8">
            <v-col cols="1" offset="1">
              <span>{{ colore.numero }}</span>
            </v-col>
            <v-col cols="3">
              <v-avatar size="30" class="mr-2">
                <v-img :src="colore_img(colore.id)" />
              </v-avatar>
              {{ colore.nome }}
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <v-divider />
  </div>
</template>

<script>
//import DialogProdotto from '@/components/lista/desktop/dialog_inserimento/listino_dettaglio_prodotto_desktop'
export default {
  components: {
    //DialogProdotto
  },

  props: {
    item: Object,
  },
  data() {
    return {
      dialog_elimina: false,
      show_color: false,
      show_prod: false,
    };
  },
  computed: {
    nome() {
      if (this.prodotto.nome.includes("Vaso")) {
        return this.prodotto.nome.split("Vaso")[0];
      } else {
        return this.prodotto.nome.split("Basket")[0];
      }
    },
    tipo_vaso() {
      if (this.prodotto.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.prodotto.unita;
      }
    },
    prodotto() {
      return this.$store.getters.get_prodotto_raw_by_id(this.item.art);
    },
    tot_pezzi() {
      return this.item.colori
        .map((col) => parseInt(col.numero))
        .reduce((tot, num) => tot + num);
    },
    prezzo_tot() {
      let tot = this.tot_pezzi * this.item.prezzo;
      return tot.toFixed(2);
    },
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.item.art;
    },
  },
  methods: {
    delete_prod() {
      this.$store
        .dispatch("remove_prodotto_carrello", this.item.art)
        .then(() => {
          this.$emit("delete");
          this.dialog_elimina = false;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
    edit_prod() {
      this.$emit("edit", this.item.art);
    },
    colore_img(id) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.item.art +
        "/img_colore/" +
        id
      );
    },
  },
};
</script>