<template>
  <v-dialog v-model="menu_add" fullscreen>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-avatar>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> Aggiungi ... </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>Aggiungi Prodotto</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="prodotto_sel_id"
          outlined
          hide-details
          label="Seleziona Prodotto"
          :items="prodotti"
          item-text="nome"
          item-value="id"
          clearable
        >
          <template v-slot:selection="data">
            <v-chip color="white">
              <v-avatar size="50" left>
                <v-img :src="avatar_img(data.item.id)"></v-img>
              </v-avatar>

              <span>
                {{ data.item.nome }}
              </span>
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <img :src="avatar_img(data.item.id)" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.nome"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.group"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-row v-if="prodotto_sel != null" class="mt-4" no-gutters>
          <Colore
            v-for="col in prodotto_sel.colori"
            :ordine="ordine"
            :prodotto="prodotto_sel.id"
            :colore="col"
            :key="col.id"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="menu_add = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Colore from "../../desktop/ordine_dettaglio/ordine_aggiungi_colore.vue";
import ordine_aggiungi_prodotto_mixin from "../../mixin/ordine_dettaglio/ordine_aggiungi_prodotto_mixin";
export default {
  components: {
    Colore,
  },
  mixins: [ordine_aggiungi_prodotto_mixin],
  watch: {},
};
</script>