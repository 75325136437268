<template>
  <v-btn icon class="mt-n2" @click="open_file_window()">
    <input
      type="file"
      style="display: none"
      @change="upload_img($event)"
      ref="input"
      accept="image/*"
    />
    <v-icon>mdi-camera</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    chat_id: String,
    destinatario: String,
  },
  data() {
    return {
      main_loading: false,
      percentLoadMain: 0,
      random_date: 0,
    };
  },
  methods: {
    open_file_window() {
      this.$refs.input.click();
    },
    upload_img: function (e) {
      let file = e.target.files[0];
      if (!file){
        return
      }
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("send_chat_img", {
        id_chat: this.chat_id,
        destinatario: this.destinatario,
        formData: formData,
      });
    },
  },
};
</script>