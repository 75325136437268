import axios from "axios";
export default {
  state: {
    fornitori: [],
  },
  getters: {
    get_fornitori: (state) => {
      return state.fornitori;
    },
    get_fornitore_by_id: (state) => (id_fornitore) => {
      return state.fornitori.find((fornit) => fornit.id == id_fornitore);
    },
  },
  mutations: {
    set_fornitori(state, fornitori) {
      state.fornitori = fornitori;
    },
    set_nuovo_fornitore(state, fornitore) {
      state.fornitori.push(fornitore);
    },
    set_fornitore_edit(state, fornitore) {
      let index = state.fornitori.findIndex(
        (fornit) => fornit.id == fornitore.id
      );
      state.fornitori.splice(index, 1, fornitore);
    },
    set_fornitore_md5_logo(state, payload) {
      state.fornitore.find((fornit) => fornit.id == payload.id).logo_md5 =
        payload.md5;
    },
    set_fornitore_delete(state, id_fornitore) {
      let index = state.fornitori.findIndex(
        (fornit) => fornit.id == id_fornitore
      );
      state.fornitori.splice(index, 1);
    },
  },
  actions: {
    fetch_fornitori(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/fornitori";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_fornitori", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_fornitore(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/fornitore";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_nuovo_fornitore", res.data);
            resolve({ id_fornitore: res.data.id });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_fornitore(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/fornitore/" + payload.id;
        let auth = context.getters.get_credential;
        axios
          .put(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_fornitore_edit", payload);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_fornitore(context, id_fornitore) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/fornitore/" + id_fornitore;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_fornitore_delete", id_fornitore);
            resolve("ok");
          })
          .catch((err) => {
            if (err.response.status == 400) {
              resolve("bound");
            }
            reject(err);
          });
      });
    },
  },
};
