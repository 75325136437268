export default {
  props: {
    ordine: Object,
    icon: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      conteggio: null,
      dialog: false,
      loading_invia: false,
      integrità: null,
      loading_conteggio: true
    };
  },
  computed: {
    lista_combinata() {
      let lista_comb = [];
      this.conteggio.prodotti.forEach((prodotto) => {
        if (this.ordine) {
          let art_ordine = this.ordine.articoli
            .filter((art) => !art.eliminato)
            .find((articolo) => articolo.art == prodotto.id_prodotto);
          if (art_ordine) {
            lista_comb.push({
              id_prodotto: prodotto.id_prodotto,
              qta_ordine: art_ordine.colori
                .map((col) => col.qta_admin ? col.qta_admin : col.numero)
                .reduce((tot, sum) => tot + sum),
              qta_contata: prodotto.qta,
              lotti: prodotto.lotti,
              prezzo_contato: prodotto.prezzo_contato,
            });
          } else {
            lista_comb.push({
              id_prodotto: prodotto.id_prodotto,
              qta_contata: prodotto.qta,
              qta_ordine: 0,
              lotti: prodotto.lotti,
              prezzo_contato: prodotto.prezzo_contato,
            });
          }
        } else {
          lista_comb.push({
            id_prodotto: prodotto.id_prodotto,
            qta_contata: prodotto.qta,
            qta_ordine: 0,
            lotti: prodotto.lotti,
            prezzo_contato: prodotto.prezzo_contato,
          });
        }
      });
      if (this.ordine) {
        this.ordine.articoli
          .filter((art) => !art.eliminato)
          .forEach((art) => {
            let art_contato = this.conteggio.prodotti.find(
              (element) => element.id_prodotto == art.art
            );
            if (!art_contato) {
              lista_comb.push({
                id_prodotto: art.art,
                qta_ordine: art.colori
                  .map((col) => col.qta_admin ? col.qta_admin : col.numero)
                  .reduce((tot, sum) => tot + sum),
                qta_contata: 0,
              });
            }
          });
      }
      return lista_comb;
    },
    cliente() {
      if (this.ordine) {
        return this.$store.getters.get_cliente_by_iva(this.ordine.iva);
      } else {
        return this.$store.getters.get_cliente_by_iva(this.conteggio.cliente);
      }
    },
    listino() {
      return this.cliente.listino;
    },
    totale_contato() {
      return this.lista_combinata
        .map((prod) => {
          if (prod.prezzo_contato) {
            return prod.qta_contata * prod.prezzo_contato;
          }
          let prodotto = this.$store.getters.get_prodotto_by_id(
            prod.id_prodotto
          );
          switch (this.listino) {
            case "1":
              return prod.qta_contata * prodotto.prezzo1;
            case "2":
              return prod.qta_contata * prodotto.prezzo2;
            case "3":
              return prod.qta_contata * prodotto.prezzo3;
          }
        })
        .reduce((tot, sum) => tot + sum);
    },
    totale_ordinato() {
      return this.lista_combinata
        .map((prod) => {
          let qta = 0;
          if (prod.qta_ordine) {
            qta = prod.qta_ordine;
          }
          if(prod.prezzo_contato){
            return prod.prezzo_contato * qta
          }
          let prodotto = this.$store.getters.get_prodotto_by_id(
            prod.id_prodotto
          );
          switch (this.listino) {
            case "1":
              return qta * prodotto.prezzo1;
            case "2":
              return qta * prodotto.prezzo2;
            case "3":
              return qta * prodotto.prezzo3;
          }
        })
        .reduce((tot, sum) => tot + sum);
    },
    totale_discrepanza() {
      let differenza = this.totale_contato - this.totale_ordinato;
      return {
        tot: differenza,
        icon: !differenza ? "mdi-check" : "mdi-alert",
        color: !differenza ? "green" : "amber",
      };
    },
    totale_documento() {
      return this.totale_contato + (this.ordine ? this.ordine.tot_trasp : 0);
    },
  },
  watch: {
    conteggio: function () {
      if (this.conteggio.cliente != null) {
        this.$store
          .dispatch("fetch_integrità", this.conteggio.cliente)
          .then((res) => {
            this.integrità = res;
          });
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.loading_conteggio = true;
        this.$store
          .dispatch("fetch_conteggio_by_id_ordine", this.ordine._id.$oid)
          .then((res) => {
            this.conteggio = res.data;
            this.loading_conteggio = false;
          });
    },
    spedisci_conteggio() {
      this.loading_invia = true;
      this.$store.dispatch("invia_csv", this.conteggio.id).finally(() => {
        this.loading_invia = false;
      });
    },
  },
};
