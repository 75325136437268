<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-title class="primary--text">
        Ordine n. {{ ordine.numero }}
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        del {{ ordine.data }}
        <br />
        <span v-if="ordine.is_joined" class="amber--text mr-1"
          ><v-icon color="amber" size="15" class="mr-1">mdi-merge</v-icon
          >Unito</span
        >
        <span v-if="!ordine.visibile"
          ><v-icon size="15" class="mr-1">mdi-eye-off</v-icon>Nascosto</span
        >
      </v-card-subtitle>
      <v-card-subtitle
        ><v-icon class="mr-2">mdi-account</v-icon> {{ ordine.cliente }}
        <br />
        <v-icon class="mr-2" :color="stato.color">{{ stato.mdi }}</v-icon>
        {{ stato.text }}
        <v-btn
          small
          text
          color="purple"
          v-if="ordine.stato == 8"
          @click="apri_modale()"
          >Visualizza</v-btn
        >
        <Conteggio
          v-if="ordine.stato == '8'"
          :conteggio="conteggio"
          :ordine="ordine"
          :loading="loading_conteggio"
          ref="modale_conteggio"
        />
      </v-card-subtitle>
      <v-list>
        <template v-for="item in ordine.articoli">
          <DettaglioElement :key="item.art" :item="item" :ordine="ordine" />
        </template>
        <AggiungiProdotto
          list
          :ordine="ordine"
          v-if="(ordine.stato == 1 || ordine.stato == 0) && !ordine.joined_in"
        />
        <v-divider />
      </v-list>
      <v-card-subtitle>
        Totale Ordinato: € {{ order_tot.toFixed(2) }}
        <br />
        Totale Trasporto: € {{ ordine.tot_trasp.toFixed(2) }}
        <br />
        <b>Totale: € {{ (order_tot + ordine.tot_trasp).toFixed(2) }}</b>
      </v-card-subtitle>
      <v-card-subtitle>
        <template v-if="ordine.ritiro_cod == 1">
          <span class="blue--text font-weight-bold">Consegna:</span>
          <br />
          {{ ordine.destinazione.indirizzo }}
        </template>
        <template v-else>
          <span class="green--text font-weight-bold">Ritiro:</span>
          <br/>
          In azienda
        </template>
        <br />

        <span v-if="ordine.orario">{{ ordine.orario }}</span>
      </v-card-subtitle>
      <v-card-subtitle>
        <b>Note Finali:</b
        >

        <v-menu
                  v-model="menu_edit_note"
                  :close-on-content-click="false"
                  v-if="!ordine.joined_in && (ordine.stato == 1 || ordine.stato == 0)"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-text="'mdi-pencil'"
                      size="20"
                      class="ml-2"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-text-field
                      v-model="note_edit"
                      hide-details
                      outlined
                      class="pa-2"
                    >
                      <template v-slot:append>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-progress-circular
                            indeterminate
                            width="1"
                            size="18"
                            color="grey"
                            class="mt-1"
                            v-if="loading_cmt"
                          ></v-progress-circular>
                          <v-icon color="success" v-if="ok_cmt"
                            >mdi-check</v-icon
                          >
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-text-field>
                  </v-card>
                </v-menu>
        
        
        <br />
        {{ ordine.commento }}
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer />
        <ArchiviaOrdine
          testo
          v-if="ordine.stato == 1 || ordine.stato == 8 || ordine.stato == 7"
          :id_ordine="ordine._id.$oid"
        />
        <v-btn
          v-if="ordine.stato == 0"
          outlined
          color="green"
          @click="processa_ordine(1)"
          :loading="loading_conferma"
          ><v-icon class="mr-2">mdi-check</v-icon> Accetta</v-btn
        >
        <v-btn text color="purple" @click="pdf()" v-if="ordine.stato != 0">
          <v-icon class="mr-2"> mdi-printer </v-icon>
          stampa
        </v-btn>
        <v-btn text color="grey" @click="close()"> chiudi </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ordine_element_mixin from "../../mixin/ordine_attivo_element_mixin";
import ordine_dett_mixin from "../../mixin/ordine_dettaglio_dialog_mixin";
import DettaglioElement from "./ordine_dettaglio_element_mobile";
import AggiungiProdotto from "./ordine_aggiungi_prodotto_mobile.vue";
import ArchiviaOrdine from "../../desktop/ordine_attivo_utilità/menu_archivia_ordine.vue";
import Conteggio from "../../../Conteggi/mobile/dettaglio/conteggio_dettaglio_mobile.vue";

export default {
  components: {
    DettaglioElement,
    AggiungiProdotto,
    ArchiviaOrdine,
    Conteggio,
  },
  props: {
    ordine: Object,
    icon: Boolean,
  },
  mixins: [ordine_dett_mixin, ordine_element_mixin],
  data() {
    return {};
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-ordine" && this.show) {
        this.show = false;
      }
    },
  },
  methods: {
    open() {
      this.show = true;
      this.$router.push("#dettaglio-ordine");
    },
    close() {
      this.show = false;
      this.$router.back();
    },
  },
};
</script>