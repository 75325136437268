<template>
  <v-card :height="small ? 180 : 300" class="">
    <v-card-title class="grey--text text-subtitle-1">
      Grafico Ordine
    </v-card-title>
    <v-card-subtitle class="" v-if="!small">
      <v-row no-gutters>
        <v-col cols="8" class="mb-n2 mt-2">
          <v-select
            :items="periodi_list"
            v-model="intervallo"
            label="Periodo"
            item-value="value"
            item-text="label"
            hide-details
            dense
            outlined
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-subtitle class="mb-n6">
        Oggi: 
      <span style="font-size: 25px" class="blue--text ml-2">{{
        n_ordini_oggi
      }}</span>
    </v-card-subtitle>

      <template v-if="loading">
        <v-row justify="center" align="center" class="mt-12">
          <v-progress-circular indeterminate width="2" color="green" />
        </v-row>
      </template>
      <template v-else>
        <v-col cols="12" class="mt-2">
          <VueApexCharts
            class="mt-n2"
            type="line"
            :height="this.small ? 100 : 140"
            :options="options"
            :series="lista"
          />
        </v-col>
      </template>

  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import _ from "lodash";
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueApexCharts,
  },
  mounted() {
    this.fetch_trend();
  },
  data() {
    return {
      loading: false,
      intervallo: 7,
      analisi: "day",
      periodi_list: [
        { label: "Ultimi 3 Giorni", value: 3 },
        { label: "Ultima Settimana", value: 7 },
        { label: "Ultimo Mese", value: moment().diff(moment().startOf('month'), 'days') },
        { label: "Ultimo Anno", value: moment().dayOfYear() },
      ],
      analisi_list: [
        { label: "Giorno", value: "day" },
        { label: "Settimana", value: "week" },
        { label: "Mese", value: "month" },
      ],
      options: {
        chart: {
          stacked: true,
          type: "area",
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        title: {
          text: this.n_ordini_oggi,
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val - 1;
            },
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        colors: ["#448AFF", "#00E676"],
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#448AFF"],
        },
      },
    };
  },
  watch: {
    intervallo: function () {
      this.fetch_trend();
    },
  },
  computed: {
    ordini() {
      return this.$store.getters.get_ordini_trend;
    },
    lista() {
      let lista_ts = this.ordini.map((element) =>
        moment.unix(element.timeStamp).startOf("day").format("X")
      );
      let lista_group = _.groupBy(lista_ts);
      let lista_giorni = Array.from(Array(this.intervallo).keys())
        .map((day) => moment().subtract(day, "day").startOf("day").format("X"))
        .sort((a, b) => a - b);
      let data = lista_giorni.map((giorno) => {
        return {
          x: moment.unix(giorno).format("DD MMMM"),
          y: giorno in lista_group ? lista_group[giorno].length + 1 : 1,
        };
      });
      return [
        {
          name: "Ordini",
          data: data,
        },
      ];
    },
    n_ordini_oggi() {
      return this.lista[0].data.at(-1).y - 1;
    },
  },
  methods: {
    fetch_trend() {
      this.loading = true;
      this.$store
        .dispatch("fetch_ordini_trend", {
          ts_inizio: moment().subtract(this.intervallo, "days").format("X"),
          ts_fine: moment().format("X"),
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>