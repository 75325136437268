<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card>
      <v-card-title> Dettaglio Conteggio </v-card-title>
      <v-card-subtitle class="mt-2">
        <template v-if="ordine != null && !loading">
          Ordine # {{ ordine.numero }}
          <br />
          {{ cliente.nome }}
        </template>
      </v-card-subtitle>
      <v-card-text>
        <template v-for="prodotto in lista_combinata">
          <ContDettEl
            :key="prodotto.id"
            :prodotto="prodotto"
            :listino="listino"
          />
          <v-divider :key="prodotto.id + '-div'" />
        </template>
        <v-row class="mt-6" no-gutters>
          <v-col cols="12" v-if="ordine"> Totale Ordine: € {{ totale_ordinato.toFixed(2) }} </v-col>
          <v-col cols="12"> Totale Contato: € {{ totale_contato.toFixed(2) }} </v-col>
          <v-col cols="12" v-if="ordine">
            Discrepanza:
            <v-icon size="15" :color="totale_discrepanza.color">
              {{ totale_discrepanza.icon }}
            </v-icon>
            € {{ totale_discrepanza.tot.toFixed(2) }}
          </v-col>
          <v-col cols="12" v-if="ordine"> Totale Trasporto: € {{ ordine.tot_trasp.toFixed(2) }} </v-col>
          <v-col cols="12" class="font-weight-bold"> Totale Documento: € {{ totale_documento.toFixed(2) }} + IVA</v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContDettEl from "./conteggio_dettaglio_element_mobile.vue";
import conteggio_dettaglio_mixin from "../../mixin/conteggio_dettaglio_mixin";
export default {
  components: {
    ContDettEl,
  },
  mixins: [conteggio_dettaglio_mixin],
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-conto" && this.dialog == true) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-conto");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
</script>