export default {
  props: {
    ordine: Object,
    item: Object,
    show_all: Boolean,
  },
  data() {
    return {
      show_color: false,
      dialog_elimina: false,
      dialog_elimina_soft: false,
      dialog_ripristina_soft: false,
      loading_elimina: false,
      loading_ripristina: false,
    };
  },
  watch: {
    show_all() {
      this.show_color = this.show_all;
    },
  },
  computed: {
    tot_pezzi() {
      return this.item.colori.filter(col => !col.eliminato)
        .map((col) => col.qta_admin ? parseInt(col.qta_admin) : parseInt(col.numero))
        .reduce((tot, num) => tot + num);
    },
    prezzo_tot() {
      let tot = this.tot_pezzi * this.item.prezzo;
      return tot.toFixed(2);
    },
    avatar_img() {
      let avatar_md5 = this.$store.getters.get_prodotto_by_id(
        this.item.art
      ).avatar_md5;
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.item.art +
        "?t=" +
        avatar_md5
      );
    },
    nome() {
      return this.item.nome.split(" in ");
    },
  },
  methods: {
    color_img(id) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.item.art +
        "/img_colore/" +
        id
      );
    },
    rimuovi_prodotto() {
      console.log("Elimina hard");

      this.loading_elimina = true;
      this.$store
        .dispatch("elimina_prodotto_ordine", {
          id_ordine: this.ordine._id.$oid,
          id_prodotto: this.item.art,
        })
        .finally(() => {
          this.loading_elimina = false;
        });
    },
    rimuovi_soft_prodotto() {
      console.log("Elimina soft");
      this.loading_elimina = true;
      this.$store
        .dispatch("elimina_soft_prodotto_ordine", {
          id_ordine: this.ordine._id.$oid,
          id_prodotto: this.item.art,
        })
        .finally(() => {
          this.loading_elimina = false;
          this.dialog_elimina_soft = false;
        });
    },
    ripristina_soft_prodotto() {
      console.log("Restore soft");
      this.loading_ripristina = true;
      setTimeout(() => {
        this.$store
          .dispatch("ripristina_soft_prodotto_ordine", {
            id_ordine: this.ordine._id.$oid,
            id_prodotto: this.item.art,
          })
          .finally(() => {
            this.loading_ripristina = false;
            this.dialog_ripristina_soft = false;
          });
      }, 1000);
    },
  },
};
