export default {
  props: {
    iva: String,
    id: String,
  },
  data() {
    return {
      dialog_unisci: false,
      ordini_array: [],
      loading: false,
    };
  },
  computed: {
    ordini_iva() {
      return this.$store.getters.get_ordini_attivi.filter(
        (ord) =>
          ord.iva == this.iva &&
          ord.joined_in == false &&
          this.id != ord._id.$oid &&
          ord.stato == 1
      );
    },
  },
  methods: {
    unisci() {
      this.ordini_array.push(this.id);
      this.loading = true;
      this.$store.dispatch("unisci_ordini", this.ordini_array).finally(() => {
        this.ordini_array = [];
        this.dialog_unisci = false;
        this.loading = false;
      });
    },
  },
};
