<template>
  <v-row align="center" no-gutters>
    <v-col cols="4">
      <v-list-item three-line class="ml-n4">
        <v-list-item-avatar>
          <v-img :src="avatar_img"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ nome[0] }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ nome[1] }}
          </v-list-item-subtitle>
          <v-list-item-subtitle> {{ prezzo_prodotto }} € </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="2"
      ><span style="font-size: 16px">{{
        prodotto.qta_ordine ? prodotto.qta_ordine : 0
      }}</span>
      <br />
      <span class="grey--text">{{ tot_ordinato.toFixed(2) }} €</span></v-col
    >
    <v-col cols="2"
      ><span style="font-size: 16px">{{ prodotto.qta_contata }}</span> <br />
      <span class="grey--text">{{ tot_conteggiato.toFixed(2) }} €</span></v-col
    >
    <v-col cols="3">
      <v-list-item two-line class="ml-0">
        <v-list-item-avatar>
          <v-icon :color="discrepanza.color" size="" class="">{{
            discrepanza.icon
          }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title :class="discrepanza.color + '--text'">
            {{ discrepanza.qta }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ tot_discrepanza.toFixed(2) }} €
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- <span style="font-size: 16px">{{ prodotto.qta_ordine }}</span>
      <v-icon :color="discrepanza.color" size="15" class="ml-2">{{
        discrepanza.icon
      }}</v-icon>
      <br />
      <span class="grey--text"
        >{{
          (prodotto.qta_ordine - prodotto.qta_contata) * prezzo_prodotto
        }}
        €</span
      > -->
    </v-col>
    <v-col cols="1">
      <DettaglioLotto :prodotto="prodotto" />
    </v-col>
  </v-row>
</template>

<script>
import conteggio_dettaglio_mixin from "../../mixin/conteggio_dettaglio_element_mixin";
import DettaglioLotto from "./conteggio_lotti.vue";
export default {
  components: {
    DettaglioLotto,
  },
  mixins: [conteggio_dettaglio_mixin],
};
</script>