<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Conteggio Crisantemi
        <v-btn
          outlined
          color="purple"
          class="ml-8"
          @click="visualizzazione = 1"
        >
          <v-icon class="mr-2">mdi-cart</v-icon>
          Carrelli</v-btn
        >
        <v-btn outlined color="green" class="ml-4" @click="visualizzazione = 2">
          <v-icon class="mr-2">mdi-clipboard-text</v-icon>
          Totale</v-btn
        >
      </v-card-title>
      <v-card-subtitle class="mt-0">
        <template v-if="ordine != null && !loading_conteggio">
          <!-- Ordine # {{ ordine.numero }}
          <br />
          {{ cliente.nome }} -->
        </template>

        <v-row no-gutters class="mt-4">
          <v-col cols="4">Prodotto</v-col>
          <v-col cols="2">Quantità in Ordine</v-col>
          <v-col cols="2">Quantità Contata</v-col>
          <v-col cols="2" class="d-flex justify-center">Discrepanza</v-col>
        </v-row>
        <v-divider class="mt-2" />
      </v-card-subtitle>
      <template v-if="loading_conteggio">
        <v-card-text>
          <v-row justify="center" class="my-8">
            <v-progress-circular indeterminate width="2"></v-progress-circular>
          </v-row>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text class="prod_wrapper">
          <template v-if="visualizzazione == 1">
            <v-tabs v-model="tab" class="mt-n2">
              <v-tab
                v-for="(carrello, index) in conteggio.carrelli"
                :key="carrello.id_carrello"
              >
                # {{ index + 1 }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="carrello in conteggio.carrelli"
                :key="carrello.id_carrello"
              >
                <ProdottoElement
                  v-for="prodotto in carrello.prodotti"
                  :key="prodotto.id_prodotto"
                  :prodotto="prodotto"
                  :carrello_element="true"
                />
              </v-tab-item>
            </v-tabs-items>
          </template>
          <template v-else>
            <template v-for="prodotto in conteggio.prodotti">
              <ProdottoElement
                :key="prodotto.id_prodotto"
                :prodotto="prodotto"
              />
              <v-divider :key="prodotto.id_prodotto + '-div'" />
            </template>
            <v-row class="mt-4">
              <v-col cols="4"></v-col>
              <v-col cols="2" class="pl-4"
                >€ {{ totale_ordinato.toFixed(2) }}</v-col
              >
              <v-col cols="2" class="pl-4"
                >€ {{ totale_contato.toFixed(2) }}</v-col
              >
              <v-col
                cols="2"
                class="pl-4"
                :class="totale_discrepanza != 0 ? 'amber--text' : 'green--text'"
                >€ {{ totale_discrepanza.toFixed(2) }}</v-col
              >
            </v-row>
          </template>
        </v-card-text>
      </template>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProdottoElement from "./elemento_conteggio_crisantemi.vue";
export default {
  components: {
    ProdottoElement,
  },
  props: {
    ordine: Object,
  },
  data() {
    return {
      dialog: false,
      conteggio: null,
      loading_conteggio: true,
      tab: null,
      visualizzazione: 2,
    };
  },
  computed: {
    totale_ordinato() {
      return this.conteggio.prodotti
        .map((prodotto) => prodotto.qta_ordinata * prodotto.prezzo_contato)
        .reduce((tot, num) => tot + num, 0);
    },
    totale_contato() {
      return this.conteggio.prodotti
        .map((prodotto) => prodotto.qta * prodotto.prezzo_contato)
        .reduce((tot, num) => tot + num, 0);
    },
    totale_discrepanza() {
      return this.totale_ordinato - this.totale_contato;
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.loading_conteggio = true;
      this.$store
        .dispatch("fetch_conteggio_cris_by_id_ordine", this.ordine._id.$oid)
        .then((res) => {
          this.conteggio = res.data;
          this.loading_conteggio = false;
        });
    },
  },
};
</script>
<style scoped>
.prod_wrapper {
  height: 500px;
  overflow-y: scroll;
}
</style>
