<template>
  <v-row justify="center" class="my-4">
    <v-col cols="10">
      <v-toolbar elevation="0">
        <v-text-field
          v-model="search_text"
          prepend-inner-icon="mdi-magnify"
          append-icon="mdi-delete"
          @click:append="search_text = ''"
          placeholder="Cerca prodotto ..."
          hide-details
          dense
          rounded
          filled
        ></v-text-field>
        <v-switch
          class="ml-4"
          label="Mostra solo preferiti"
          v-model="show_pref_only"
          hide-details
        ></v-switch>
      </v-toolbar>

      <v-divider />

      <v-row justify="start" no-gutters class="mt-3" align="center">
        <v-col cols="1">
          <v-btn icon @click="show_all = !show_all">
            <v-icon>{{
              show_all ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3"> Nome </v-col>
        <v-col cols="1"> Diam. </v-col>
        <v-col cols="3"> Info </v-col>
        <v-col cols="1"> Prezzo </v-col>
        <v-col cols="3"> </v-col>
      </v-row>

      <template v-if="filtered.length > 0">
        <template v-for="(categoria, index) in filtered">
          <v-row align="center" class="mb-2" :key="categoria.nome">
            <v-toolbar tile elevation="0" :src="categoria.back_src" class="mt-4">
              <v-btn icon @click="categoria.show = !categoria.show">
                <v-icon color="white">{{
                  categoria.show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
              <v-toolbar-title class="white--text text-uppercase">
                {{ categoria.nome }}
              </v-toolbar-title>
            </v-toolbar>
          </v-row>
          <template v-if="categoria.show">
            <SubCategList
                  v-for="sub_categ in categoria.sub_categorie"
                  :key="sub_categ.nome + index"
                  :sub_categ="sub_categ"
                  :show="show_all"
                />
          </template>
        </template>
      </template>
      <template v-else>
        <v-row justify="center" class="my-6"> Nessun Prodotto </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import SubCategList from "./listino_subcateg_desktop";
import listino_main_mixin from "../mixin/listino_main_mixin";
export default {
  components: {
    SubCategList,
  },
  mixins: [listino_main_mixin],
};
</script>
