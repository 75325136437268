<template>
  <v-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" class="" small>
        <v-icon color="purple"> mdi-cart </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text">
        Carrello di {{ cliente.nome }}
      </v-card-title>

      <v-card-text>
        <CarrelloEl
          v-for="prodotto in carrello"
          :item="prodotto"
          :key="prodotto.art"
        />
        <v-row no-gutters align="center" class="mt-6 font-weight-medium">
          <v-col cols="1" offset="10">
            € {{ totale.toFixed(2) }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="dialog = false" color="grey"> chiudi </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import CarrelloEl from "./carrello_element_desktop";
export default {
  components: {
    CarrelloEl,
  },
  props: {
    cliente: Object,
  },
  data() {
    return {
      dialog: false,
      carrello: [],
    };
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/carrello/" + this.cliente.iva;
        let auth = this.$store.getters.get_credential;
        axios.get(url, { auth: auth }).then((res) => {
          this.carrello = res.data;

        });
      }
    },
  },
  computed: {
    totale() {
      return this.carrello
        .map((art) =>
          art.colori
            .map((col) => col.numero * art.prezzo)
            .reduce((tot, num) => tot + num, 0)
        )
        .reduce((tot, num) => tot + num, 0);
    },
  },
};
</script>