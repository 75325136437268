import axios from "axios";
import moment from "moment";

export default {
  state: {
    consegne: [],
    consegne_archivio: [],
    scarichi: [],
    tempi: {},
    zone: [],
    coord: {},
  },
  getters: {
    get_scarichi: (state) => {
      return state.scarichi;
    },
    get_tempi: (state) => {
      return state.tempi;
    },
    get_consegne: (state) => {
      return state.consegne;
    },
    get_consegne_archivio: (state) => {
      return state.consegne_archivio;
    },
    get_consegna_by_id: (state) => (id) => {
      return state.consegne.find((cons) => cons.id == id);
    },
    get_ordine_in_consegna: (state) => (order_id) => {
      let flag = false;
      state.consegne.forEach((cons) => {
        cons.tappe.forEach((tappa) => {
          if (tappa.ordine == order_id) {
            flag = true;
          }
        });
      });
      return flag;
    },
    get_zone: (state) => {
      return state.zone;
    },
  },
  mutations: {
    set_consegne(state, consegne) {
      state.consegne = consegne;
    },
    set_consegne_archivio(state, consegne) {
      state.consegne_archivio = consegne;
    },
    push_consegna(state, consegna) {
      state.consegne.push(consegna);
    },
    update_consegna(state, consegna) {
      let index = state.consegne.findIndex((cons) => cons.id == consegna.id);
      state.consegne.splice(index, 1, consegna);
    },
    set_consegna_eliminata(state, id) {
      let index = state.consegne.findIndex((cons) => cons.id == id);
      if (index != -1) {
        state.consegne.splice(index, 1);
      } else {
        index = state.consegne_archivio.findIndex((cons) => cons.id == id);
        if (index != -1) {
          state.consegne_archivio.splice(index, 1);
        }
      }
    },
    set_consegna_avviata(state, id) {
      let consegna = state.consegne.find((cons) => cons.id == id);
      consegna.stato = 1;
      consegna.ts_partenza = moment().format("X");
    },
    set_consegna_terminata(state, id) {
      let consegna = state.consegne.find((cons) => cons.id == id);
      consegna.stato = 2;
      consegna.ts_arrivo = moment().format("X");
    },
    set_consegna_archiviata(state, id) {
      let cons = state.consegne.find((cons) => cons.id == id);
      cons.archiviata = true;
      let index = state.consegne.findIndex((cons) => cons.id == id);
      if (index != -1) {
        state.consegne.splice(index, 1);
      }
      state.consegne_archivio.push(cons);
    },
    set_consegna_attiva(state, id) {
      let cons = state.consegne_archivio.find((cons) => cons.id == id);
      cons.archiviata = false;
      let index = state.consegne_archivio.findIndex((cons) => cons.id == id);
      if (index != -1) {
        state.consegne_archivio.splice(index, 1);
      }
      state.consegne.push(cons);
    },
    set_scarichi(state, scarichi) {
      state.scarichi = [];
      state.scarichi = scarichi;
    },
    set_notifica_scarico(state, payload) {
      state.scarichi.tappe.find(
        (tappa) => tappa.cliente == payload.cliente
      ).notifica = payload.notifica;
    },
    set_tempi(state, tempi) {
      state.tempi = tempi;
    },
    set_tempo_cambio_cc(state, tempo) {
      state.tempi.tempo_cambio_cc = tempo;
    },
    set_tempo_scarico_cc(state, tempo) {
      state.tempi.tempo_scarico_cc = tempo;
    },
    set_tempo_pagamento(state, tempo) {
      state.tempi.tempo_pagamento = tempo;
    },
    set_zone(state, zone) {
      zone.unshift("Tutte");
      state.zone = zone;
    },
    set_coord_azienda(state, coord) {
      state.coord_azienda = coord;
    },
    add_zona(state, zona) {
      state.zone.push(zona);
    },
    delete_zona(state, zona) {
      let index = state.zone.indexOf(zona);
      state.zone.splice(index, 1);
    },
    update_tappa(state, payload) {
      let consegna = state.consegne.find(
        (cons) => cons.id == payload.id_consegna
      );
      let index = consegna.tappe.findIndex((tappa) => tappa.id == payload.id);
      consegna.tappe.splice(index, 1, payload);
    },
    update_tappe_consegna(state, payload) {
      let consegna = state.consegne.find(
        (consegna) => consegna.id == payload.id
      );
      consegna.tappe = payload.tappe;
    },
  },
  actions: {
    fetch_consegne(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/consegne_attive";
        let auth = context.getters.get_credential;

        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_consegne", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_consegne_archivio(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/consegne_archivio/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;

        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_consegne_archivio", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_zone(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/zone_consegna";
        let auth = context.getters.get_credential;

        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_zone", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_is_ordine_inconsegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/isinconsegna";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      });
    },
    salva_consegna_old(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/consegna";
        let auth = context.getters.get_credential;
        let scarichi = context.getters.get_scarichi;
        axios
          .post(
            url,
            {
              automezzo: payload.mezzo_consegna,
              scarichi: scarichi,
              partenza_prevista: payload.partenza_prevista,
            },
            { auth: auth }
          )
          .then((res) => {
            context.commit("push_consegna", res.data);
            context.dispatch("fetch_ordini_attivi");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    salva_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/consegna";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/consegna/" + id;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_consegna_eliminata", id);
            context.dispatch("fetch_ordini_attivi");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    annulla_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/consegna/" + id + "/annulla";
        let auth = context.getters.get_credential;
        axios
          .post(url, { auth: auth })
          .then(() => {
            // context.commit("set_consegna_eliminata", id);
            // context.dispatch("fetch_ordini_attivi");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    avvia_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/avvia_consegna/" + id;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    avvia_consegna_immediato(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/avvia_consegna_immediato/" +
          id;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    programma_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/programma_consegna/" +
          payload.id_consegna +
          "/mezzo/" +
          payload.id_mezzo;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    termina_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/termina_consegna/" + id;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_consegna", res.data);
            let tappe = context.getters.get_consegna_by_id(id).tappe;

            tappe.forEach((tappa) => {
              context.commit("set_ordine_confermato", {
                id: tappa.ordine,
                stato: 7,
              });
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    archivia_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/archivia_consegna/" + id;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_consegna_archiviata", id);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    mezzo_consegna_in_azienda(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/consegna/" + id + "/mezzo";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    attiva_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/attiva_consegna/" + id;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_consegna_attiva", id);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_tempi_consegna(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/tempi_consegna";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_tempi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_coord_azienda(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/coord_azienda";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_coord_azienda", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_tempo_cambio_cc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/tempi_cambio_cc/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_tempo_cambio_cc", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_tempo_scarico_cc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/tempi_scarico_cc/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_tempo_scarico_cc", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_tempo_pagamento(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/tempi_pagamento/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_tempo_pagamento", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_zona_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/zone_consegna/" + payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("add_zona", payload);
            resolve();
          })
          .catch((err) => {
            reject(err.response.status);
          });
      });
    },
    elimina_zona_consegna(context, zona) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/zone_consegna/" + zona;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("delete_zona", zona);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elabora_percorso_auto(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/percorso_auto";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_scarichi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elabora_percorso_man(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/percorso_man";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            context.commit("set_scarichi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ricalcola_percorso_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/consegna/" +
          payload.id_consegna +
          "/ricalcola_percorso";
        let auth = context.getters.get_credential;
        axios
          .put(url, payload.tappe, { auth: auth })
          .then((res) => {
            context.commit("update_tappe_consegna", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_tappa_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/consegna/" +
          payload.id_consegna +
          "/aggiungi_tappa/" +
          payload.id_ordine +
          "/posizione/" +
          payload.index;
        let auth = context.getters.get_credential;
        axios
          .put(url, payload.tappe, { auth: auth })
          .then((res) => {
            context.commit("update_tappe_consegna", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    rimuovi_tappa_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/consegna/" +
          payload.id_consegna +
          "/tappa/" +
          payload.id_tappa;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("update_tappe_consegna", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_mezzo_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/consegna/" +
          payload.id_consegna +
          "/cambia_mezzo/" +
          payload.id_mezzo;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_consegna", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_data_prevista_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/consegna/" +
          payload.id_consegna +
          "/cambia_partenza_prevista/" +
          payload.ts_consegna;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_consegna", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_NUOVA_CONSEGNA(context, payload) {
      console.log("[CONSEGNE SOCKET] Nuova consegna");
      context.commit("push_consegna", payload);
      context.dispatch("fetch_ordini_attivi");
    },
    SOCKET_CONSEGNA_ELIMINATA(context, payload) {
      context.commit("set_consegna_eliminata", payload);
    },
    SOCKET_AGGIORNA_CONSEGNA(context, payload) {
      context.commit("update_consegna", payload);
    },
    SOCKET_CONSEGNA_AVVIATA(context, payload) {
      console.log("[CONSEGNE] Avvio consegna");
      context.commit("update_consegna", payload);
      let tappe = context.getters.get_consegna_by_id(payload.id).tappe;
      tappe.forEach((tappa) => {
        context.commit("set_ordine_confermato", {
          id: tappa.ordine,
          stato: 6,
        });
      });
    },
    SOCKET_CONSEGNA_TERMINATA(context, payload) {
      console.log(payload);
      context.commit("update_consegna", payload);
    },
    SOCKET_UPDATE_TAPPA(context, payload) {
      console.log("[CONSEGNE] Update Tappa");
      console.log(payload);
      context.commit("update_tappa", payload);
    },
  },
};
