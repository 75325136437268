var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-1 font-weight-bold",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.show_color = !_vm.show_color}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show_color ? "mdi-chevron-up" : "mdi-chevron-down"))])],1),_c('span',{class:_vm.item.eliminato ? 'grey--text text-decoration-line-through' : ''},[_vm._v(_vm._s(_vm.tot_pezzi))])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.avatar_img}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1 font-weight-light",class:_vm.item.eliminato ? 'grey--text text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(_vm.nome[0])+" "),(_vm.item.make_admin)?_c('span',{staticClass:"green--text font-weight-light ml-2",staticStyle:{"font-size":"10px"}},[_vm._v(" Add by Admin")]):_vm._e()]),_c('v-list-item-subtitle',{staticClass:"font-weight-light",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.nome[1])+" ")])],1)],1)],1),_c('v-col',{class:_vm.item.eliminato ? 'grey--text text-decoration-line-through' : '',attrs:{"cols":"2"}},[_vm._v(" € "+_vm._s(_vm.item.prezzo.toFixed(2))+" ")]),_c('v-col',{class:_vm.item.eliminato ? 'grey--text text-decoration-line-through' : '',attrs:{"cols":"2"}},[_vm._v(" € "+_vm._s(_vm.prezzo_tot)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.item.commento)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[(!_vm.ordine.joined_in)?_c('ModificaProdotto',{attrs:{"prodotto":_vm.item.art,"ordine":_vm.ordine}}):_vm._e(),(_vm.item.make_admin)?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,389476454),model:{value:(_vm.dialog_elimina),callback:function ($$v) {_vm.dialog_elimina=$$v},expression:"dialog_elimina"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text"},[_vm._v(" Elimina Prodotto ")]),_c('v-card-text',[_vm._v(" Sei sicuro ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"red","loading":_vm.loading_elimina},on:{"click":function($event){return _vm.rimuovi_prodotto()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-delete")]),_vm._v("Elimina")],1),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog_elimina = false}}},[_vm._v("Annulla")])],1)],1)],1):_vm._e(),(!_vm.item.make_admin && !_vm.item.eliminato)?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,389476454),model:{value:(_vm.dialog_elimina_soft),callback:function ($$v) {_vm.dialog_elimina_soft=$$v},expression:"dialog_elimina_soft"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text"},[_vm._v(" Elimina Prodotto ")]),_c('v-card-text',[_vm._v(" Sei sicuro ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"red","loading":_vm.loading_elimina},on:{"click":function($event){return _vm.rimuovi_soft_prodotto()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-delete")]),_vm._v("Elimina")],1),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog_elimina_soft = false}}},[_vm._v("Annulla")])],1)],1)],1):_vm._e(),(_vm.item.eliminato)?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"green"}},on),[_c('v-icon',[_vm._v("mdi-restore")])],1)]}}],null,false,3851790655),model:{value:(_vm.dialog_ripristina_soft),callback:function ($$v) {_vm.dialog_ripristina_soft=$$v},expression:"dialog_ripristina_soft"}},[_c('v-card',[_c('v-card-title',{staticClass:"green--text"},[_vm._v(" Ripristina Prodotto ")]),_c('v-card-text',[_vm._v(" Sei sicuro ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"green","loading":_vm.loading_ripristina},on:{"click":function($event){$event.stopPropagation();return _vm.ripristina_soft_prodotto()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-restore")]),_vm._v("Ripristina")],1),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog_ripristina_soft = false}}},[_vm._v("Annulla")])],1)],1)],1):_vm._e()],1)],1),(_vm.show_color)?[_c('v-row',{staticClass:"pa-1 mb-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.item.colori),function(colore){return _c('v-col',{key:colore.id,staticClass:"text-truncate my-1",attrs:{"cols":"12"}},[_c('v-row',{class:colore.eliminato ? 'text-decoration-line-through' : '',attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"color":"white","disabled":!colore.qta_admin,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"1"}},[(!colore.qta_admin)?[_c('span',[_vm._v(_vm._s(colore.numero))])]:[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(colore.qta_admin))])]],2),_c('v-col',_vm._g({attrs:{"cols":"11"}},on),[_c('v-avatar',{staticClass:"mx-4",attrs:{"size":"27"}},[_c('v-img',{attrs:{"src":_vm.color_img(colore.id)}})],1),_vm._v(" "+_vm._s(colore.nome)+" "),(colore.make_admin)?_c('span',{staticClass:"green--text font-weight-light",staticStyle:{"font-size":"10px"}},[_vm._v(" Add by Admin")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticClass:"black--text"},[_vm._v(" "+_vm._s(colore.numero)+" "),_c('span',{staticClass:"pink--text",staticStyle:{"font-size":"10px"}},[_vm._v(" in precedenza ")])])]),_c('v-col',{attrs:{"cols":"6"}})],1)],1)}),1)],1)],1)]:_vm._e(),_c('v-divider')],2)}
var staticRenderFns = []

export { render, staticRenderFns }