import axios from "axios";

export default {
  state: {
    prenotazioni_crisantemi: [],
    dispo_crisantemi: [],
    conteggi_crisantemi: [],
  },
  getters: {
    get_prenotazioni_crisantemi: (state) => {
      return state.prenotazioni_crisantemi;
    },
    get_prenotazione_crisantemi_by_iva: (state) => (payload) => {
      return state.prenotazioni_crisantemi.find(
        (prenot) => prenot.cliente == payload.iva && prenot.anno == payload.anno
      );
    },
    get_prenotazione_crisantemi_by_id: (state) => (id) => {
      return state.prenotazioni_crisantemi.find((prenot) => prenot.id == id);
    },
    get_totCC_prenotazione: (state, getters) => (id_prenotazione) => {
      return state.prenotazioni_crisantemi
        .find((pren) => pren.id == id_prenotazione)
        .prodotti.map((prodotto) => {
          return (
            prodotto.colori
              .map((colore) => colore.qta)
              .reduce((tot, num) => tot + num, 0) /
            getters.get_prodotto_by_id(prodotto.id_prodotto).qta_cc
          );
        })
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },
    get_totCC_consegna: (state, getters) => (payload) => {
      return state.prenotazioni_crisantemi
        .find((pren) => pren.id == payload.id_prenotazione)
        .consegne.find(cons => cons.id == payload.id_consegna)
        .prodotti.map((prodotto) => {
          return (
            prodotto.colori
              .map((colore) => colore.qta)
              .reduce((tot, num) => tot + num, 0) /
            getters.get_prodotto_by_id(prodotto.id_prodotto).qta_cc
          );
        })
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },
    get_totValore_prenotazione: (state, getters) => (id_prenotazione) => {
      let prenotazione = state.prenotazioni_crisantemi.find(
        (pren) => pren.id == id_prenotazione
      );
      let listino = getters.get_cliente_by_iva(prenotazione.cliente).listino;
      return prenotazione.prodotti
        .map((prodotto) => {
          return prodotto.colori
            .map((colore) => {
              let prod = getters.get_prodotto_by_id(prodotto.id_prodotto);
              let prezzo;
              switch (listino) {
                case "1":
                  prezzo = prod.prezzo1;
                  break;
                case "2":
                  prezzo = prod.prezzo2;
                  break;
                case "3":
                  prezzo = prod.prezzo3;
                  break;
              }
              return colore.qta * prezzo;
            })
            .reduce((tot, num) => tot + num, 0);
        })
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },
    get_numero_prenotato_crisantemo: (state) => (payload) => {
      let prenotazione = state.prenotazioni_crisantemi.find(
        (prenot) => prenot.cliente == payload.cliente
      );
      if (!prenotazione) {
        return 0;
      }
      let prodotto = prenotazione.prodotti.find(
        (prodotto) => prodotto.id_prodotto == payload.prodotto
      );
      if (!prodotto) {
        return 0;
      }
      let colore = prodotto.colori.find(
        (col) => col.id_colore == payload.colore
      );
      if (!colore) {
        return 0;
      }
      return colore.qta;
    },
    get_numero_prenotato_prodotto_crisantemo: (state) => (payload) => {
      let prenotazione = state.prenotazioni_crisantemi.find(
        (prenot) => prenot.cliente == payload.cliente
      );
      if (!prenotazione) {
        return 0;
      }
      let prodotto = prenotazione.prodotti.find(
        (prodotto) => prodotto.id_prodotto == payload.prodotto
      );
      if (!prodotto) {
        return 0;
      }
      return prodotto.colori.map((col) => col.qta).reduce((a, b) => a + b, 0);
    },
    get_qta_dispo_crisantemo: (state) => (payload) => {
      if (!state.dispo_crisantemi.length) {
        return 0;
      }
      let prodotto = state.dispo_crisantemi.find(
        (prod) =>
          prod.id_prodotto == payload.prodotto && prod.anno == payload.anno
      );
      if (!prodotto) {
        return 0;
      }
      let colore = prodotto.colori.find(
        (col) => col.id_colore == payload.colore
      );
      if (!colore) {
        return 0;
      }
      return colore.qta;
    },
    get_qta_dispo_ordinata_crisantemo: (state) => (payload) => {
      let clienti = [];
      return {
        qta: state.prenotazioni_crisantemi
          .filter((pren) => pren.anno == payload.anno)
          .map((pren) => {
            let prodotto = pren.prodotti.find(
              (prod) => prod.id_prodotto == payload.prodotto
            );
            if (!prodotto) {
              return 0;
            }
            let colore = prodotto.colori.find(
              (col) => col.id_colore == payload.colore
            );
            if (!colore) {
              return 0;
            }
            clienti.push({
              iva: pren.cliente,
              qta: colore.qta,
              id_pren: pren.id,
            });
            return colore.qta;
          })
          .reduce((tot, num) => tot + num, 0),
        clienti: clienti,
      };
    },
    get_conteggio_crisantemi_by_id: (state) => (id) => {
      return state.conteggi_crisantemi.find((cont) => cont.id == id);
    },
    get_prodotto_in_conteggio_cris: (state) => (payload) => {
      let conteggio = state.conteggi_crisantemi.find(
        (conteggio) => conteggio.id_consegna == payload.id_consegna
      );
      if (!conteggio) {
        return null;
      }
      let prodotto = conteggio.prodotti.find(
        (prodotto) => prodotto.id_prodotto == payload.id_prodotto
      );
      if (!prodotto) {
        return 0;
      }
      return prodotto.qta;
    },
  },
  mutations: {
    set_prenotazioni_crisantemi(state, prenot) {
      state.prenotazioni_crisantemi = prenot;
    },
    update_prenotazione_crisantemi(state, prenot) {
      let index = state.prenotazioni_crisantemi.findIndex(
        (prenotazione) => prenotazione.id == prenot.id
      );
      if (index >= 0) {
        state.prenotazioni_crisantemi.splice(index, 1, prenot);
      } else {
        state.prenotazioni_crisantemi.push(prenot);
      }
    },
    update_colore_prenotazione(state, payload) {
      let prenotazione = state.prenotazioni_crisantemi.find(
        (prenot) => prenot.id == payload.prenotazione.id
      );
      if (!prenotazione) {
        state.prenotazioni_crisantemi.push(payload.prenotazione);
        return;
      }
      let prodotto = prenotazione.prodotti.find(
        (prod) => prod.id_prodotto == payload.id_prodotto
      );
      if (!prodotto) {
        prenotazione.prodotti.push({
          id_prodotto: payload.id_prodotto,
          colori: [
            {
              id_colore: payload.id_colore,
              qta: parseInt(payload.qta),
            },
          ],
        });
        return;
      }
      let colore = prodotto.colori.find(
        (col) => col.id_colore == payload.id_colore
      );
      if (!colore) {
        prodotto.colori.push({
          id_colore: payload.id_colore,
          qta: parseInt(payload.qta),
        });
        return;
      } else {
        if (parseInt(payload.qta) > 0) {
          colore.qta = parseInt(payload.qta);
        } else {
          let index = prodotto.colori.findIndex(
            (col) => col.id_colore == payload.id_colore
          );
          prodotto.colori.splice(index, 1);
          if (!prodotto.colori.length) {
            let index = prenotazione.prodotti.findIndex(
              (prod) => prod.id == payload.id_prodotto
            );
            prenotazione.prodotti.splice(index, 1);
          }
        }
      }
    },
    update_colore_consegna(state, payload) {
      let prenotazione = state.prenotazioni_crisantemi.find(
        (prenot) => prenot.id == payload.id_prenotazione
      );
      let consegna = prenotazione.consegne.find(
        (cons) => cons.id == payload.id_consegna
      );
      let prodotto = consegna.prodotti.find(
        (prod) => prod.id_prodotto == payload.id_prodotto
      );
      if (!prodotto) {
        consegna.prodotti.push({
          id_prodotto: payload.id_prodotto,
          colori: [
            {
              id: payload.id_colore,
              qta: parseInt(payload.qta),
            },
          ],
        });
        return;
      }
      let colore = prodotto.colori.find((col) => col.id == payload.id_colore);
      if (!colore) {
        prodotto.colori.push({
          id: payload.id_colore,
          qta: parseInt(payload.qta),
        });
      } else {
        if (parseInt(payload.qta) == 0) {
          let index = prodotto.colori.findIndex(
            (col) => col.id == payload.id_colore
          );
          prodotto.colori.splice(index, 1);
          if (!prodotto.colori.length) {
            let index = consegna.prodotti.findIndex(
              (prod) => prod.id == payload.id_prodotto
            );
            consegna.prodotti.splice(index, 1);
          }
        } else {
          colore.qta = parseInt(payload.qta);
        }
      }
    },
    delete_prenotazione_crisantemi(state, id_prenotazione) {
      let index = state.prenotazioni_crisantemi.findIndex(
        (pren) => pren.id == id_prenotazione
      );
      if (index >= 0) {
        state.prenotazioni_crisantemi.splice(index, 1);
      }
    },
    set_dispo_crisantemi(state, dispo) {
      state.dispo_crisantemi = dispo;
    },
    update_dispo_crisantemi(state, payload) {
      let index = state.dispo_crisantemi.findIndex(
        (prod) => prod.id_prodotto == payload.id_prodotto
      );
      if (index >= 0) {
        state.dispo_crisantemi.splice(index, 1, payload);
      } else {
        state.dispo_crisantemi.push(payload);
      }
    },
    set_id_ordine_to_consegna(state, payload) {
      state.prenotazioni_crisantemi
        .find((prenot) => prenot.id == payload.id_prenotazione)
        .consegne.find(
          (cons) => cons.id == payload.id_consegna
        ).id_ordine_creato = payload.id_ordine;
    },
    add_conteggio_cris(state, conteggio) {
      if (!state.conteggi_crisantemi.find((cont) => cont.id == conteggio.id)) {
        state.conteggi_crisantemi.push(conteggio);
      }
    },
  },
  actions: {
    fetch_prenotazioni_crisantemi(context, anno) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazioni/anno/" +
          anno;
        let auth = context.getters.get_credential;

        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_prenotazioni_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_prenotazione_crisantemi_by_iva(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/anno/" +
          payload.anno +
          "/cliente/" +
          payload.cliente;
        let auth = context.getters.get_credential;

        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_conteggio_cris_by_id_ordine(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/conteggio_crisantemi/ordine/" +
          id;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("add_conteggio_cris", res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_conteggio_cris_by_id_consegna(context, id) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/conteggio_crisantemi/consegna_crisantemi/" +
          id;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            if (!res.data.res == "DoesNotExist") {
              context.commit("add_conteggio_cris", res.data.data);
            }
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    clona_prenotazione_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/clona_prenotazione/" +
          payload.iva +
          "/da_anno/" +
          payload.da_anno +
          "/a_anno/" +
          payload.a_anno;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    invio_colore_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/anno/" +
          payload.anno +
          "/cliente/" +
          payload.iva +
          "/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore +
          "/qta/" +
          payload.qta;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_colore_prenotazione", {
              prenotazione: res.data,
              id_prodotto: payload.id_prodotto,
              id_colore: payload.id_colore,
              qta: payload.qta,
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    invio_nota_colore_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/anno/" +
          payload.anno +
          "/cliente/" +
          payload.iva +
          "/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore +
          "/nota";
        let auth = context.getters.get_credential;
        axios
          .post(url, { nota: payload.nota }, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    invio_nota_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/anno/" +
          payload.anno +
          "/cliente/" +
          payload.iva +
          "/note";
        let auth = context.getters.get_credential;
        axios
          .post(url, { nota: payload.nota }, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    crea_consegna_prenotazione_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/crea_consegna/" +
          payload.id_prenotazione;
        let auth = context.getters.get_credential;

        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    riempi_rimanenza_consegna_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/consegna/" +
          payload.id_consegna +
          "/riempi_rimanenza";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_consegna_prenotazione_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/consegna/" +
          payload.id_consegna;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiorna_consegna_prenotazione_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/prodotto/" +
          payload.id_prodotto +
          "/consegna/" +
          payload.id_consegna +
          "/colore/" +
          payload.id_colore +
          "/qta/" +
          payload.qta;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            // context.commit("update_prenotazione_crisantemi", res.data);
            context.commit("update_colore_consegna", {
              id_prenotazione: payload.id_prenotazione,
              id_prodotto: payload.id_prodotto,
              id_consegna: payload.id_consegna,
              id_colore: payload.id_colore,
              qta: payload.qta,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_data_consegna_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/consegna/" +
          payload.id_consegna +
          "/data/" +
          payload.data_consegna;

        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_data_prenotazione_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/consegna_presunta/" +
          payload.ts_data;

        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_evasione_prenotazione_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/evasa/" +
          payload.evasa;

        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_ritiro_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/ritiro/" +
          payload.ritiro;

        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_prenotazione_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_prenotazione_crisantemi(context, id_prenotazione) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          id_prenotazione;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("delete_prenotazione_crisantemi", id_prenotazione);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // GESTIONE DISPONIBILITA

    fetch_dispo_crisantemi(context, anno) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/crisantemi/dispo/anno/" + anno;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_dispo_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiorna_qta_dispo_crisantemi(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/dispo/anno/" +
          payload.anno +
          "/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore +
          "/qta/" +
          payload.qta;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("update_dispo_crisantemi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    crea_ordine_da_consegna_prenotazione(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/crisantemi/prenotazione/" +
          payload.id_prenotazione +
          "/consegna/" +
          payload.id_consegna +
          "/crea_ordine";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("add_new_order", res.data);
            context.commit("set_id_ordine_to_consegna", {
              id_prenotazione: payload.id_prenotazione,
              id_consegna: payload.id_consegna,
              id_ordine: res.data._id.$oid,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_AGGIORNA_PRENOTAZIONE_CRIS(context, payload) {
      context.commit("update_prenotazione_crisantemi", payload);
    },
  },
  modules: {},
};
