import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      menu: false,
      text_tosend: "",
      typing: false,
      change_timeout: undefined,
      show_emo: false,
      delete_dialog: false,
      delete_loading: false,
    };
  },
  created() {},
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.$store.dispatch("find_non_letto_index", this.chat._id);
        setTimeout(() => {
          this.scroll_down();
        }, 500);
      } else {
        this.$emit("close");
      }
    },
    chat: {
      deep: true,
      handler() {
        if (this.dialog) {
          setTimeout(() => {
            this.scroll_down();
          }, 100);
        }
      },
    },
    text_tosend: function () {
      if (!this.typing) {
        this.typing = true;
        this.$socket.emit("typing", {
          user: this.$store.getters.get_utente.iva,
          chat_id: this.chat._id,
          typing: true,
        });
      }
      if (this.change_timeout != undefined) {
        clearTimeout(this.change_timeout);
      }
      this.change_timeout = setTimeout(this.end_typing, 1000);
    },
  },
  computed: {
    ...mapGetters({
      loading: "get_chat_loading",
      file_load_percent: "get_file_load_percent",
      img_load_percent: "get_img_load_percent",
    }),
    ontyping() {
      return this.$store.getters.get_chat_typing(this.chat._id);
    },
    last_access() {
      return moment.unix(this.autore.last_access).calendar();
    },
    msg_array() {
      if (this.chat.messaggi == undefined) {
        return [];
      }
      let res = _.groupBy(this.chat.messaggi, (msg) => {
        return moment.unix(msg.ts_creazione).startOf("day");
      });
      let arrbyday = Object.keys(res).map((date) => {
        return {
          data: moment(date).calendar({
            sameDay: "[Oggi]",
            nextDay: "[Domani]",
            nextWeek: "dddd",
            lastDay: "[Ieri]",
            lastWeek: "[Lo Scorso] dddd",
            sameElse: "DD/MM/YYYY",
          }),
          msg: res[date],
        };
      });
      return arrbyday;
    },
    autore() {
      return this.$store.getters.get_cliente_by_iva(this.chat.autore.iva);
    },
  },
  methods: {
    prendi_in_carico(){
      this.$store.dispatch("imposta_chat_carico",this.chat._id)
    },
    send_msg() {
      if (this.text_tosend == "") {
        return;
      }
      this.$store.dispatch("send_chat_msg", {
        id_conversazione: this.chat._id,
        destinatario: this.chat.autore.iva,
        body: this.text_tosend,
      });
      this.text_tosend = "";
    },
    scroll_down() {
      this.$refs.chat_body.scroll({ top: 9999 });
    },
    end_typing() {
      this.typing = false;
      this.$socket.emit("typing", {
        user: this.$store.getters.get_utente.iva,
        chat_id: this.chat._id,
        typing: false,
      });
    },
    add_to_text: function (char) {
      this.text_tosend = this.text_tosend + char;
    },
    delete_chat() {
      this.delete_loading = true;
      this.$store
        .dispatch("archive_chat", this.chat._id)
        .then(() => {})
        .finally(() => {
          this.delete_loading = false;
          this.delete_dialog = false;
          this.$emit("chat_archived")
        });
    },
  },
};
