<template>
  <v-menu
    v-model="show_emo"
    top
    offset-x
    offset-y
    :close-on-content-click="false"
    max-height="400"
    max-width="300"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="mt-n2 ml-n4" icon>
        <v-icon>mdi-emoticon-happy-outline</v-icon>
      </v-btn>
    </template>
    <v-card max-width="400">
      <v-row class="px-3 pt-2" justify="start" align="center">
        <v-col cols="2" class="">
          <v-btn icon @click="filter = 'Smiley'">
            <v-icon size="25">mdi-emoticon-happy-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="">
          <v-btn icon @click="filter = 'People'">
            <v-icon size="25">mdi-account-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="">
          <v-btn icon @click="filter = 'Object'">
            <v-icon size="25">mdi-lightbulb-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" class="">
          <v-btn icon @click="filter = 'Nature'">
            <v-icon size="25">mdi-flower</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-card-text class="emo_body">
        <v-row justify="center">
          <v-col cols="2" v-for="emo in filtered" :key="emo.codes">
            <span
              style="font-size: 22px; cursor: pointer"
              class="black--text"
              @click="select(emo.char)"
              >{{ emo.char }}</span
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      show_emo: false,
      emoji: null,
      filter: "Smileys",
    };
  },
  created() {
    this.emoji = require("emoji.json");
  },
  computed: {
    filtered() {
      if (this.filter == "People") {
        return this.emoji
          .filter((emo) => {
            return emo.group.includes(this.filter);
          })
          .filter((emo) => {
            return !emo.name.includes("skin");
          });
      }
      return this.emoji.filter((emo) => {
        return emo.group.includes(this.filter);
      });
    },
  },
  methods: {
    select(char) {
      this.$emit("emoji",char);
      this.show_emo = false
    },
  },
};
</script>

<style scoped>
.emo_body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 200px;
}
</style>