<template>
    <v-dialog v-model="dialog" width="1500" scrollable>
        <template v-slot:activator="{on}">
            <v-btn large outlined v-on="on" color="primary">
                <v-icon class="mr-2">mdi-view-dashboard</v-icon>
                LISTINO
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="grey--text">
                Listino
            </v-card-title>
            <v-card-text>
                <ListinoMain/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn outlined color="grey" @click="dialog = false">
                    chiudi
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ListinoMain from "./listino/destop/listino_main_desktop"
export default {
    components:{
        ListinoMain
    },
    data(){
        return{
            dialog: false
        }
    }
}
</script>


<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
</style>