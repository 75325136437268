import axios from "axios";

export default {
  state: {
    tanks: [],
  },
  getters: {
    get_tanks: (state) => {
      return state.tanks;
    },
  },
  mutations: {
    set_tanks(state, payload) {
      state.tanks = payload;
    },
    set_tank(state, payload) {
      console.log(payload);
      let index = state.tanks.findIndex(
        (tank) => tank._id.$oid == payload._id.$oid
      );
      state.tanks.splice(index, 1, payload);
    },
    set_nome_tank(state, payload) {
      state.tanks.find((tank) => tank._id.$oid == payload.id_tank).nome =
        payload.nome;
    },
    set_tipo_tank(state, payload) {
      state.tanks.find((tank) => tank._id.$oid == payload.id_tank).type =
        payload.tipo;
    },
    set_altezza_tank(state, payload) {
      state.tanks.find(
        (tank) => tank._id.$oid == payload.id_tank
      ).altezza = parseInt(payload.altezza);
    },
    set_larghezza_tank(state, payload) {
      state.tanks.find(
        (tank) => tank._id.$oid == payload.id_tank
      ).larghezza = parseInt(payload.larghezza);
    },
    set_profond_tank(state, payload) {
      state.tanks.find(
        (tank) => tank._id.$oid == payload.id_tank
      ).profond = parseInt(payload.profond);
    },
    set_diametro_tank(state, payload) {
      state.tanks.find(
        (tank) => tank._id.$oid == payload.id_tank
      ).diametro = parseInt(payload.diametro);
    },
    delete_tank(state, id_tank) {
      let index = state.tanks.findIndex((tank) => tank.id == id_tank);
      state.tanks.splice(index, 1);
    },
  },
  actions: {
    fetch_tanks(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/tanks";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_tanks", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_nome_tank(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/nome";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_nome_tank", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_tipo_tank(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/tipo";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_tipo_tank", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_altezza_tank(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/altezza";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_altezza_tank", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_larghezza_tank(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/larghezza";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_larghezza_tank", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_profond_tank(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/profond";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_profond_tank", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_diametro_tank(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/diametro";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_diametro_tank", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_tank_chart_data(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/tanks/" +
          payload.id_tank +
          "/chart_data/ts_inizio/" +
          payload.ts_inizio +
          "/ts_fine/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_tank(context, id_tank) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/tanks/" + id_tank;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("delete_tank", id_tank);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_TANK_UPDATE(context, payload) {
      let json = JSON.parse(payload);
      context.commit("set_tank", json);
    },
  },
};
