import axios from "axios";

export default {
  state: {
    conteggi: [],
    conteggi_evasi: [],
  },
  getters: {
    get_conteggi: (state) => {
      return state.conteggi.filter((cont) => !cont.archiviato);
    },
    get_conteggi_evasi: (state) => {
      return state.conteggi_evasi;
    },
    get_conteggio_by_ordcod: (state) => (id_ordine) => {
      return state.conteggi.find((cont) => cont.id_ordine == id_ordine);
    },
    get_numero_conteggi: (state) => {
      return state.conteggi.filter((conteggio) => !conteggio.archiviato).length;
    },
    get_conteggio_by_id_ordine: (state) => (id_ordine) => {
      return state.conteggi.find(
        (conteggio) => conteggio.id_ordine == id_ordine
      );
    },
  },
  mutations: {
    set_conteggi(state, conteggi) {
      state.conteggi = conteggi;
    },
    set_conteggio_eliminato(state, id_ordine) {
      let index = state.conteggi.findIndex(
        (conteggio) => conteggio.id_ordine == id_ordine
      );
      state.conteggi.splice(index, 1);
    },
    add_conteggio(state, conteggio) {
      state.conteggi.push(conteggio);
    },
    set_conteggio_archiviato(state, id_conteggio) {
      let conteggio = state.conteggi.find((cont) => cont.id == id_conteggio);
      if (conteggio) {
        conteggio.archiviato = true;
      }
    },
    set_conteggi_evasi(state, conteggi) {
      state.conteggi_evasi = conteggi;
    },
    set_conteggio_eliminato_diretto(state, id_conteggio) {
      let index = state.conteggi.findIndex((cont) => cont.id == id_conteggio);
      if (index >= 0) {
        state.conteggi.splice(index, 1);
      } else {
        let index = state.conteggi_evasi.findIndex(
          (cont) => cont.id == id_conteggio
        );
        state.conteggi_evasi.splice(index, 1);
      }
    },
  },
  actions: {
    SOCKET_NUOVO_CONTEGGIO(context, conteggio) {
      context.commit("add_conteggio", conteggio);
    },
    SOCKET_CONTEGGIO_ELIMINATO(context, id_conteggio) {
      context.commit("set_conteggio_eliminato_diretto", id_conteggio);
    },
    SOCKET_CONTEGGIO_ARCHIVIATO(context, id_conteggio) {
      context.commit("set_conteggio_archiviato", id_conteggio);
    },
    fetch_conteggi(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/conteggi";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_conteggi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_conteggio_by_id_ordine(context, id) {
      console.log("fetching conteggio")
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/conteggio/ordine/" + id;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_conteggi_by_iva(context, iva) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/conteggio/cliente/" + iva;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_conteggi_evasi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    archivia_conteggio(context, id_conteggio) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/conteggio/" +
          id_conteggio +
          "/archivia";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_conteggio_archiviato", id_conteggio);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_conteggio(context, id_conteggio) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/conteggio/" + id_conteggio;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_conteggio_eliminato_diretto", id_conteggio);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    riattiva_conteggio_ordine(context, id_ordine) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/conteggio/ordine/" +
          id_ordine +
          "/riattiva";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("add_conteggio", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invia_csv(context, id_conteggio) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/conteggio/" +
          id_conteggio +
          "/csv";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_conteggio_archiviato", id_conteggio);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    stampa_etichetta_prova(context) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/stampa_etichetta_prova";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
