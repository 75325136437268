<template>
  <div>
    <v-snackbar v-model="avviso_avvio" bottom shaped color="green">
      <v-icon class="mr-2">mdi-information</v-icon>
      Il mezzo selezionato si trova in azienda e la consegna verrà avviata non
      appena partirà.
    </v-snackbar>

    <v-snackbar v-model="snack_mezzo_offline" bottom shaped color="error">
      <v-icon>mdi-alert</v-icon>
      Il mezzo selezionato risulta offline.
    </v-snackbar>
    <ConsegnaDettaglio
      :consegna="consegna"
      :ordini="ordini"
      ref="consegna_dettaglio"
    />
    <v-row v-if="loading" align="center">
      <v-col cols="1">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" class="">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" class="">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <v-row no-gutters class="" justify="center" align="center">
          <v-skeleton-loader class="" type="avatar"></v-skeleton-loader>
        </v-row>
      </v-col>
      <v-col cols="1" class="">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
      <v-col cols="1" class="">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" class="d-flex align-center">
        <v-skeleton-loader type="button" class="mx-1"></v-skeleton-loader>
        <v-skeleton-loader type="button" class="mx-1"></v-skeleton-loader>
        <v-skeleton-loader type="button" class="mx-1"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row align="center" v-else>
      <v-col cols="1" class="d-flex justify-center"
        ># {{ consegna.numero }}
      </v-col>
      <v-col cols="2">
        <template
          v-for="(tappa, index) in consegna.tappe.filter(
            (tappa) => !tappa.ritorno
          )"
        >
          <div class="text-truncate" :key="tappa.id">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on"
                  ><v-icon
                    size="15"
                    class="mt-n1 mr-1"
                    :color="stato_tappa(tappa).colore"
                    >{{ stato_tappa(tappa).icona }}</v-icon
                  >{{ index + 1 }} - {{ cliente(tappa.cliente).nome }}</span
                >
              </template>
              {{ index + 1 }} - {{ cliente(tappa.cliente).nome }}
            </v-tooltip>
          </div>
        </template>
      </v-col>
      <v-col cols="2">
        {{ partenza_prevista }}
      </v-col>
      <v-col cols="2" class="">
        <template v-if="consegna.stato == 1">
          <v-row no-gutters justify="center">
            <v-icon color="indigo"> mdi-truck-fast </v-icon>
            <v-icon
              v-if="!stato_mezzo.network"
              color="red"
              size="15"
              class="ml-2"
            >
              mdi-link-off
            </v-icon>
            <v-icon v-if="!stato_mezzo.gps" color="red" size="15" class="ml-2">
              mdi-map-marker-off-outline
            </v-icon>
          </v-row>
          <v-row no-gutters justify="center"> in Consegna </v-row>
        </template>
        <template v-if="consegna.stato == 0">
          <v-row no-gutters justify="center">
            <v-icon color="orange"> mdi-clock </v-icon>
            <v-icon
              v-if="!stato_mezzo.network"
              color="red"
              size="15"
              class="ml-2"
            >
              mdi-link-off
            </v-icon>
            <v-icon v-if="!stato_mezzo.gps" color="red" size="15" class="ml-2">
              mdi-map-marker-off-outline
            </v-icon>
          </v-row>
          <v-row no-gutters justify="center"> Programmata </v-row>
        </template>
        <template v-if="consegna.stato == 2">
          <v-row no-gutters justify="center">
            <v-icon color="green"> mdi-check-circle </v-icon>
          </v-row>
          <v-row no-gutters justify="center"> Completata </v-row>
        </template>
        <template v-if="consegna.stato == 3">
          <v-row no-gutters justify="center">
            <v-icon color="purple"> mdi-upload </v-icon>
          </v-row>
          <v-row no-gutters justify="center"> Attesa Partenza Mezzo </v-row>
        </template>
        <template v-if="consegna.stato == 4">
          <v-row no-gutters justify="center">
            <v-icon color="indigo"> mdi-bus-clock </v-icon>
          </v-row>
          <v-row no-gutters justify="center"> Attesa Arrivo Mezzo </v-row>
        </template>
      </v-col>
      <v-col cols="1">{{ tot_cc_carico }}</v-col>
      <v-col cols="2">
        {{ tempo_humanized }}
        <br />
        <template v-if="consegna.stato == 1">
          <span class="purple--text">Arrivo</span> {{ ora_arrivo_previsto }}
        </template>
        <template v-if="consegna.stato == 2">
          <!-- <span class="green--text">Arrivato alle </span> {{ ora_arrivo }} -->
        </template>
      </v-col>
      <v-col cols="2">
        <!-- <ConsegnaDettaglio :consegna="consegna" :ordini="ordini" /> -->
        <v-btn icon class="ml-8" @click="$refs.consegna_dettaglio.open()"
          ><v-icon color="blue">mdi-map</v-icon></v-btn
        >
        <!-- Archivia Consegna -->

        <v-btn
          icon
          color="purple"
          @click="dialog_archivia = true"
          v-if=" consegna.stato == 2 && !consegna.archiviata"
        >
          <v-icon>mdi-package-down</v-icon>
        </v-btn>
        <v-dialog
          v-model="dialog_archivia"
          v-if="consegna.stato == 2 && !consegna.archiviata"
          width="400"
        >
          <v-card>
            <v-card-title class="purple--text">
              Archivia Consegna
            </v-card-title>
            <v-card-text> La consegna verrà archiviata. </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined color="purple" @click="archivia_consegna()">
                <v-icon class="mr-2">mdi-package-down</v-icon>
                Archivia
              </v-btn>
              <v-btn text color="grey" @click="dialog_archivia = false"
                >Annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          v-if="consegna.archiviata"
          icon
          color="green"
          @click="attiva_consegna()"
        >
          <v-icon>mdi-recycle</v-icon>
        </v-btn>

        <!-- Avvia Consegna -->
        <v-btn
          icon
          color="green"
          @click="tenta_avvio_consegna"
          v-if="consegna.stato == 0"
        >
          <v-icon>mdi-truck-delivery</v-icon>
        </v-btn>

        <v-dialog v-model="dialog_avvia" width="400" v-if="consegna.stato == 0">
          <v-card>
            <v-card-title>Attenzione</v-card-title>
            <v-card-text>
              <p>Il mezzo di consegna non si trova in azienda ...</p>
              <v-btn
                outlined
                color="blue"
                class="my-2"
                @click="programma_consegna()"
                :loading="loading_programma_consegna"
                >Non è ancora arrivato</v-btn
              >
              <v-btn
                outlined
                color="green"
                class="my-2"
                @click="avvia_consegna()"
                >è già partito</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog_mezzo_offline"
          width="500"
          v-if="consegna.stato == 0"
        >
          <v-card>
            <v-card-title>Attenzione</v-card-title>
            <v-card-text>
              <p>Il mezzo di consegna risulta Offline ...</p>
              <v-btn
                outlined
                color="green"
                class="my-2 mx-1"
                @click="avvia_consegna()"
                >Imposta Comunque</v-btn
              >
              <v-btn
                outlined
                color="purple"
                class="my-2 mx-1"
                @click="archivia_consegna()"
                >Archivia</v-btn
              >
              <v-btn
                text
                color="grey"
                class="my-2 mx-1"
                @click="dialog_mezzo_offline = false"
                >Annulla</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Termina consegna -->
        <v-btn
          icon
          color="amber"
          v-if="consegna.stato == 1"
          @click="dialog_termina = true"
        >
          <v-icon>mdi-truck-check</v-icon>
        </v-btn>
        <v-dialog
          v-model="dialog_termina"
          v-if="consegna.stato == 1"
          width="400"
        >
          <v-card>
            <v-card-title class="amber--text"> Termina Consegna </v-card-title>
            <v-card-text> La consegna verrà terminata. </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="green"
                @click="termina_consegna()"
                :loading="loading_termina"
              >
                <v-icon class="mr-2">mdi-truck-chek</v-icon>
                Termina
              </v-btn>
              <v-btn text color="grey" @click="dialog_termina = false"
                >Annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          v-if="consegna.stato == 4 || consegna.stato == 3"
          color="red"
          icon
          :loading="annulla_loading"
          @click="annulla_consegna()"
          ><v-icon>mdi-cancel</v-icon></v-btn
        >
        <!-- Elimina Consegna -->
        <v-dialog v-model="dialog_elimina" width="400">
          <template v-slot:activator="{ on }">
            <v-btn icon color="red" v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="red--text"> Elimina Consegna </v-card-title>
            <v-card-text> La consegna verrà eliminata. </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="red"
                :loading="loading_delete"
                @click="elimina_consegna()"
              >
                <v-icon class="mr-2">mdi-delete</v-icon>
                Elimina
              </v-btn>
              <v-btn text color="grey" @click="dialog_elimina = false"
                >Annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <MappaGps
          :id_mezzo="consegna.automezzo"
          :punti_scarico="consegna.tappe"
          :id_consegna="consegna.id"
        /> -->
      </v-col>
    </v-row>
    <v-divider class="my-4" />
  </div>
</template>

<script>
// import moment from "moment";
// import axios from "axios";
import consegna_element_mixin from "../mixin/consegna_element_mixin";
import ConsegnaDettaglio from "./consegna_dettaglio_mappa";
// import MappaGps from "../../Automezzi/Desktop/mappa_gps_2.vue";

export default {
  components: {
    ConsegnaDettaglio,
    // MappaGps,
  },
  mixins: [consegna_element_mixin],
  // props: {
  //   consegna: Object,
  // },
  // data() {
  //   return {
  //     loading: true,
  //     ordiniData: [],
  //     dialog_elimina: false,
  //     dialog_avvia: false,
  //     dialog_termina: false,
  //     dialog_archivia: false,
  //     loading_delete: false,
  //   };
  // },
  // watch: {
  //   "consegna.tappe": {
  //     immediate: true,
  //     handler() {
  //       this.loading = true;
  //       this.fetchOrdini(
  //         this.consegna.tappe
  //           .filter((tappa) => !tappa.ritorno)
  //           .map((tappa) => tappa.ordine)
  //       );
  //       // this.consegna.tappe.forEach((tappa) => {
  //       //   this.fetchOrdini(tappa.ordine);
  //       // });
  //     },
  //   },
  // },
  // computed: {
  //   n_scarichi() {
  //     return this.consegna.tappe.length - 1;
  //   },
  //   partenza_prevista() {
  //     return moment
  //       .unix(this.consegna.ts_partenza_prevista)
  //       .format("DD MMMM HH:mm");
  //   },
  //   ordini() {
  //     return this.ordiniData;
  //   },
  //   tot_cc_carico() {
  //     if (!this.ordini.length) {
  //       return 0;
  //     }
  //     return this.ordini
  //       .map((ordine) => {
  //         console.log(ordine.articoli);
  //         return this.tot_cc(ordine.articoli);
  //       })
  //       .reduce((tot, num) => tot + num, 0)
  //       .toFixed(1);
  //   },
  //   tempo_totale() {
  //     let tempi = this.$store.getters.get_tempi;
  //     if (!this.ordini.length) {
  //       return 0;
  //     }
  //     return this.consegna.tappe
  //       .map((tappa) => {
  //         // let ordine = this.$store.getters.get_ordine_by_id(tappa.ordine);
  //         if (tappa.ritorno) {
  //           return parseInt(tappa.durata.value / 60);
  //         }
  //         let ordine = this.ordini.find(
  //           (ordine) => ordine._id.$oid == tappa.ordine
  //         );
  //         let cliente = this.$store.getters.get_cliente_by_iva(ordine.iva);
  //         let durata = tappa.durata.value / 60;
  //         let tot_cc = this.tot_cc(ordine.articoli);
  //         return (
  //           parseFloat(tot_cc) *
  //             (cliente.cambio_cc
  //               ? parseInt(tempi.tempo_cambio_cc)
  //               : parseInt(tempi.tempo_scarico_cc)) +
  //           parseInt(tempi.tempo_pagamento) +
  //           parseInt(durata)
  //         );
  //       })
  //       .reduce((tot, num) => tot + num, 0);
  //   },
  //   tempo_humanized() {
  //     return (
  //       moment.duration(this.tempo_totale, "minutes").hours() +
  //       " h e " +
  //       moment.duration(this.tempo_totale, "minutes").minutes() +
  //       " min"
  //     );
  //   },
  //   ora_arrivo_previsto() {
  //     return moment
  //       .unix(this.consegna.ts_partenza)
  //       .add(this.tempo_totale, "minutes")
  //       .format("HH:mm");
  //   },
  // },
  // methods: {
  //   cliente(iva) {
  //     return this.$store.getters.get_cliente_by_iva(iva);
  //   },
  //   tot_cc(articoli) {
  //     if (!articoli.length) {
  //       return 0;
  //     }
  //     return parseFloat(
  //       articoli
  //         .map((item) => {
  //           let prodotto = this.$store.getters.get_prodotto_raw_by_id(item.art);
  //           if (!prodotto) {
  //             return 0;
  //           }
  //           let qta_cc = prodotto.qta_cc;
  //           if (!qta_cc) {
  //             return 0;
  //           } else {
  //             return (
  //               item.colori
  //                 .map((col) => parseInt(col.numero + col.qta_admin))
  //                 .reduce((tot, num) => tot + num) / qta_cc
  //             );
  //           }
  //         })
  //         .reduce((tot, num) => tot + num)
  //         .toFixed(1)
  //     );
  //   },
  //   async fetchOrdini(ids) {
  //     let auth = this.$store.getters.get_credential;
  //     let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/";
  //     const promises = ids.map((id) => axios.get(url + id, { auth: auth }));
  //     const response = await Promise.all(promises);
  //     this.ordiniData = response.map((res) => JSON.parse(res.data));
  //     this.loading = false;
  //   },
  //   stato_tappa(tappa) {
  //     if (tappa.raggiunta && !tappa.completa) {
  //       return {
  //         icona: "mdi-human-dolly",
  //         colore: "indigo",
  //       };
  //     } else if (tappa.raggiunta && tappa.completa) {
  //       return {
  //         icona: "mdi-check",
  //         colore: "green",
  //       };
  //     } else {
  //       return {
  //         icona: "mdi-clock",
  //         colore: "amber",
  //       };
  //     }
  //   },
  //   elimina_consegna() {
  //     this.loading_delete = true;
  //     this.$store.dispatch("elimina_consegna", this.consegna.id).finally(() => {
  //       this.loading_delete = false;
  //     });
  //   },
  //   avvia_consegna() {
  //     this.$store.dispatch("avvia_consegna", this.consegna.id);
  //   },
  //   archivia_consegna() {
  //     this.$store.dispatch("archivia_consegna", this.consegna.id);
  //   },
  //   termina_consegna() {
  //     this.$store.dispatch("termina_consegna", this.consegna.id);
  //   },
  //   attiva_consegna() {
  //     this.loading_attiva = true;
  //     this.$store.dispatch("attiva_consegna", this.consegna.id).finally(() => {
  //       this.loading_attiva = false;
  //     });
  //   },
  // },
};
</script>
