<template>
  <v-card :height="height" class="">
    <v-card-title class="grey--text text-subtitle-1">
      Ordini Attivi
      <v-btn icon @click="expand()" class="ml-4">
        <v-icon>{{
          is_expanded ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <v-menu v-model="menu_filtro">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on"><v-icon>mdi-filter</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item @click="valore_filtro = -1"> 
            <v-list-item-avatar>
              <v-icon>mdi-filter-off</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Tutti
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="valore_filtro = 1"> 
            <v-list-item-avatar>
              <v-icon color="green">mdi-clipboard-check</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Confermati
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="valore_filtro = 0"> 
            <v-list-item-avatar>
              <v-icon color="orange">mdi-clipboard-arrow-up</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                In Attesa
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="valore_filtro = 8"> 
            <v-list-item-avatar>
              <v-icon color="purple">mdi-playlist-check</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Conteggiato
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="valore_filtro = 6"> 
            <v-list-item-avatar>
              <v-icon color="blue">mdi-truck-fast</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                In Consegna
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="valore_filtro = 7"> 
            <v-list-item-avatar>
              <v-icon color="indigo">mdi-package-down</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Consegnato
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="filtro_pianificato = !filtro_pianificato"><v-icon>{{filtro_pianificato ? 'mdi-truck-check' : 'mdi-truck-outline'}}</v-icon></v-btn>

      <v-spacer />
      <NuovoOrdine />
    </v-card-title>

    <v-card-text :class="is_expanded ? 'scroll_extended' : 'scroll'">
      <v-divider class="mb-2" />
      <v-row class="font-weight-medium" no-gutters>
        <v-col cols="1" class="pl-4"> ID </v-col>
        <v-col cols="1" class="pl-1"> Data </v-col>
        <v-col cols="3"> Cliente </v-col>
        <v-col cols="1"> Consegna </v-col>
        <v-col cols="1" class="d-flex justify-end pr-2"> Totale </v-col>
        <v-col cols="1" class="d-flex justify-end pr-6">CC</v-col>
        <v-col cols="2"> Stato </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row v-if="!ordini_attivi.length" justify="center" class="pt-12">
        Nessun Nuovo Ordine
      </v-row>
      <div v-else>
        <OrdineAttivoEl
          v-for="ordine in ordini_attivi"
          :ordine="ordine"
          :key="ordine._id.$oid"
        />
      </div>
    </v-card-text>
    <v-row align="center" justify="center" >
      <v-btn icon  color="grey" depressed @click="expand()">
      <v-icon dark>{{ is_expanded ?  'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
    </v-btn>
    </v-row>
    
  </v-card>
</template>

<script>
import ordini_mixin from "../mixin/ordini_mixin";
import NuovoOrdine from "./crea_nuovo_ordine/crea_nuovo_ordine.vue";
import OrdineAttivoEl from "./ordine_attivo_element.vue";
export default {
  components: {
    NuovoOrdine,
    OrdineAttivoEl,
  },
  mixins: [ordini_mixin],
  data() {
    return {
      is_expanded: false,
      height: 390,
    };
  },
  methods: {
    expand() {
      if (this.is_expanded) {
        this.is_expanded = false;
        this.height = 390;
      } else {
        this.is_expanded = true;
        this.height = 800;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 300px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 710px;
  overflow-y: scroll;
}
</style>
