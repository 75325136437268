import axios from "axios";

export default {
  state: {
    chats: [],
    broadcasts: [],
    chats_archivio: [],
    testi_broadcast: [],
    wamsgs: [],
  },
  getters: {
    get_chats: (state) => {
      return state.chats;
      //.sort((a, b) => b.ts_ultimo_msg - a.ts_ultimo_msg);
    },
    get_broadcasts: (state) => {
      return state.broadcasts;
    },
    get_wamsgs: (state) => {
      return state.wamsgs.sort(
        (a, b) =>
          b.messageses[b.messageses.length - 1].timestamp -
          a.messageses[a.messageses.length - 1].timestamp
      );
    },
    get_chats_archivio: (state) => {
      return state.chats_archivio.sort(
        (a, b) => b.ts_ultimo_msg - a.ts_ultimo_msg
      );
    },
    get_chat_typing: (state) => (chat_id) => {
      return state.chats.find((chat) => chat._id == chat_id).typing;
    },
    get_chat_nl: (state, getters) => (chat_id) => {
      let chat = state.chats.find((chat) => chat._id == chat_id);
      return chat.messaggi
        .filter((msg) => !msg.isNlFlag)
        .filter((msg) => !getters.get_cliente_by_iva(msg.autore).isAdmin)
        .filter((msg) => msg.letto != 2 && !msg.isNlFlag).length;
    },
    get_chats_nl: (state, getters) => {
      return state.chats.filter((chat) => {
        return (
          chat.messaggi
            .filter((msg) => !msg.isNlFlag)
            .filter((msg) => {
              return !getters.get_cliente_by_iva(msg.autore).isAdmin;
            })
            .filter((msg) => msg.letto != 2 && !msg.isNlFlag).length > 0
        );
      }).length;
    },
    get_wachats_nl: (state) => {
      return state.wamsgs
        .map((chat) => chat.messageses.filter((msg) => msg.nuovo).length)
        .reduce((tot, num) => tot + num, 0);
    },
    get_testi_broadcast: (state) => {
      return state.testi_broadcast;
    },
  },
  mutations: {
    set_chats(state, chats) {
      state.chats = chats;
    },
    set_broadcasts(state, broadcasts) {
      state.broadcasts = broadcasts;
    },
    set_wamsgs(state, wamsgs) {
      state.wamsgs = wamsgs;
    },
    push_new_broadcast(state, broadcast) {
      state.broadcasts.unshift(broadcast);
    },
    add_wamsg(state, payload) {
      let chat = state.wamsgs.find((msg) => msg._id == payload.user_id);
      if (chat) {
        chat.messageses.push(payload);
      } else {
        state.wamsgs.push({ _id: payload.user_id, messageses: [payload] });
      }
    },
    change_broad_status(state, payload) {
      let broad = state.broadcasts.find(
        (broad) => broad._id.$oid == payload.id_broadcast
      );
      let el = broad.elementi.find(
        (el) => el.id_messaggio == payload.id_messaggio
      );
      el.stato = payload.stato;
    },
    change_wamsg_status(state, payload) {
      let chat = state.wamsgs.find((chat) => chat._id == payload.user_id);
      let msg = chat.messageses.find(
        (msg) => msg.msg_id == payload.id_messaggio
      );
      if (msg) {
        msg.stato = payload.stato;
      }
    },
    set_wamsg_read(state, payload) {
      state.wamsgs
        .find((msg) => msg._id == payload.user_id)
        .messageses.find((msg) => msg.msg_id == payload.id_msg).nuovo = false;
    },
    remove_broadcast(state, id_broadcast) {
      let index = state.broadcasts.findIndex(
        (broad) => broad._id.$oid == id_broadcast
      );
      state.broadcasts.splice(index, 1);
    },
    set_chats_archivio(state, chats) {
      state.chats_archivio = chats;
    },
    push_new_chat(state, chat) {
      state.chats.push(chat);
    },
    set_chat_admin(state, payload) {
      let chat = state.chats.find((chat) => chat._id == payload.id_chat);
      chat.destinatario = payload.admin;
    },
    set_chat_typing(state, payload) {
      let chat = state.chats.find((chat) => chat._id == payload.chat_id);
      chat.typing = payload.typing;
    },
    set_add_new_msg(state, payload) {
      let chat = state.chats.find((chat) => chat._id == payload.id_chat);
      chat.messaggi.push(payload);
      chat.ts_ultimo_msg = payload.ts_creazione;
    },
    set_msg_file_load(state, payload) {
      let msg = state.chats
        .find((chat) => chat._id == payload.msg.id_chat)
        .messaggi.find((msg) => msg._id == payload.msg._id);
      msg.file_name = payload.msg.file_name;
      msg.file_size = payload.msg.file_size;
      msg.file_type = payload.msg.file_type;
      msg.loading = payload.value;
    },
    set_msg_img_load(state, payload) {
      let msg = state.chats
        .find((chat) => chat._id == payload.msg.id_chat)
        .messaggi.find((msg) => msg._id == payload.msg._id);
      msg.height = payload.msg.height;
      msg.width = payload.msg.width;
      msg.loading = payload.value;
    },
    set_msg_img_wrong(state, payload) {
      let msg = state.chats
        .find((chat) => chat._id == payload.id_chat)
        .messaggi.find((msg) => msg._id == payload._id);
      msg.isImg = false;
      msg.isText = true;
      msg.testo = "Formato non supportato";
    },
    set_non_letto_flag(state, payload) {
      let index = state.chats
        .find((chat) => chat._id == payload.chat_id)
        .messaggi.findIndex((msg) => msg.isNlFlag);
      if (index >= 0) {
        state.chats
          .find((chat) => chat._id == payload.chat_id)
          .messaggi.splice(index, 1);
      }
      if (payload.position >= 0) {
        let chat = state.chats.find((chat) => chat._id == payload.chat_id);
        chat.messaggi.splice(payload.position, 0, {
          isNlFlag: true,
          ts_creazione: chat.messaggi[payload.position].ts_creazione,
        });
      }
    },
    set_msg_read(state, payload) {
      let chat = state.chats.find((chat) => chat._id == payload.id_chat);
      let msg = chat.messaggi.find((msg) => msg._id == payload.id_messaggio);
      msg.letto = 2;
    },
    set_msg_received(state, payload) {
      state.chats
        .find((chat) => chat._id == payload.id_chat)
        .messaggi.find((msg) => msg._id == payload.id_messaggio).letto = 1;
    },
    set_msg_sent(state, payload) {
      state.chats
        .find((chat) => chat._id == payload.id_chat)
        .messaggi.find((msg) => msg._id == payload._id).letto = 1;
    },
    set_msg_delete(state, payload) {
      let chat = state.chats.find((chat) => chat._id == payload.id_chat);
      let index = chat.messaggi.findIndex(
        (msg) => msg._id == payload.id_messaggio
      );
      chat.messaggi.splice(index, 1);
    },
    set_chat_empty(state, id_chat) {
      let chat = state.chats.find((chat) => chat._id == id_chat);
      let index = state.chats.findIndex((chat) => chat._id == id_chat);
      state.chats.splice(index, 1);
      state.chats_archivio.push(chat);
    },
    set_chat_eliminata(state, id_chat) {
      let index = state.chats_archivio.findIndex((chat) => chat._id == id_chat);
      if (index == -1) {
        index = state.chats.findIndex((chat) => chat._id == id_chat);
        if (index == -1) {
          return;
        }
        state.chats.splice(index, 1);
        return;
      }
      state.chats_archivio.splice(index, 1);
    },
    set_msg_notified(state, payload) {
      let chat = state.chats.find((chat) => chat._id == payload.id_chat);
      chat.messaggi.find(
        (msg) => msg._id == payload.id_messaggio
      ).notificato = true;
    },
    sort_chats(state) {
      state.chats.sort((a, b) => b.ts_ultimo_msg - a.ts_ultimo_msg);
    },
    set_testi_broadcast(state, testi) {
      state.testi_broadcast = testi;
    },
    add_testo_broadcast(state, testo) {
      state.testi_broadcast.push(testo);
    },
    del_testo_broadcast(state, id) {
      let index = state.testi_broadcast.findIndex((text) => text.id == id);
      state.testi_broadcast.splice(index, 1);
    },
  },
  actions: {
    fetch_chats(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/chat";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_chats", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_broadcasts(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/broadcasts";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_broadcasts", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_wamsgs(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/wachat";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_wamsgs", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_chats_archivio(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/chat_archivio";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_chats_archivio", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_chats_by_iva(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/chat_archivio/" + payload.iva;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_chats_archivio", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_chat_msg(context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch("find_non_letto_index", payload.id_conversazione);
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/" + payload.id_conversazione;
        let auth = context.getters.get_credential;
        axios
          .post(
            url,
            { text: payload.body, destinatario: payload.destinatario },
            { auth: auth }
          )
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_chat_file(context, payload) {
      return new Promise((resolve, reject) => {
        //context.dispatch("find_non_letto_index")
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/file_msg/" + payload.id_chat;
        let auth = context.getters.get_credential;
        axios
          .post(url, { destinatario: payload.destinatario }, { auth: auth })
          .then((res) => {
            let msg_id = res.data.msg_id;
            let file_url =
              process.env.VUE_APP_API_ROOT + "/chat/file/" + msg_id;
            axios
              .post(file_url, payload.formData, { auth: auth })
              .catch((err) => {
                context.commit("remove_chat_msg", msg_id);
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_chat_img(context, payload) {
      return new Promise((resolve, reject) => {
        //context.dispatch("find_non_letto_index");
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/img_msg/" + payload.id_chat;
        let auth = context.getters.get_credential;
        let msg_id = null;
        axios
          .post(url, { destinatario: payload.destinatario }, { auth: auth })
          .then((res) => {
            msg_id = res.data.msg_id;
            let img_url = process.env.VUE_APP_API_ROOT + "/chat/img/" + msg_id;

            axios
              .post(img_url, payload.formData, { auth: auth })
              .catch((err) => {
                context.commit("remove_chat_msg", msg_id);
                reject(err);
              });
          });
      });
    },
    find_non_letto_index(context, chat_id) {
      let index = context.state.chats
        .find((chat) => chat._id == chat_id)
        .messaggi.findIndex(
          (msg) =>
            msg.autore != context.getters.get_utente.iva &&
            msg.letto != 2 &&
            !msg.isNlFlag
        );

      context.commit("set_non_letto_flag", {
        chat_id: chat_id,
        position: index,
      });
    },
    send_received_confirm_chat(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/consegnato/" + payload._id;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_read_confirm_chat(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/letto/" + payload.id_messaggio;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_msg_read", {
              id_messaggio: payload.id_messaggio,
              id_chat: payload.id_chat,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_messaggio_chat(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/messaggio/" + payload._id;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_msg_delete", {
              id_messaggio: payload._id,
              id_chat: payload.id_chat,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    archive_chat(context, id_chat) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/" + id_chat + "/archivia";
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_chat_empty", id_chat);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_chat(context, id_chat) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat/" + id_chat;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_chat_eliminata", id_chat);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    start_new_chat(context, iva_cliente) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/new_chat/" +
          iva_cliente +
          "/admin/" +
          context.getters.get_utente.iva;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("push_new_chat", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    imposta_chat_carico(context, id_chat) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/" + id_chat + "/carico_admin";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_chat_admin", {
              id_chat: id_chat,
              admin: res.data.admin,
            });
            resolve;
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invia_broadcast(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/broadcast";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("push_new_broadcast", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invia_broadcast_immagine(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/broadcast_immagine";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("push_new_broadcast", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    archivia_broadcast(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/broadcast/" + payload.id_broadcast;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("remove_broadcast", payload.id_broadcast);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_testi_broadcast(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat/broadcast_text";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_testi_broadcast", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    new_testo_broadcast(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat/broadcast_text";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("add_testo_broadcast", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_testo_broadcast(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat/broadcast_text/" + id;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("del_testo_broadcast", id);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invia_msg_wa(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/wamsg";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    set_wamsg_read(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/wamsg/" + payload.id_msg + "/letto";
        let auth = context.getters.get_credential;
        axios
          .put(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_wamsg_read", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_NEW_MESSAGE(context, payload) {
      context.commit("set_add_new_msg", payload);
      let autore = context.getters.get_cliente_by_iva(payload.autore);
      if (!autore.isAdmin) {
        context.dispatch("send_received_confirm_chat", payload);
      }
    },
    SOCKET_MESSAGE_SENT(context, payload) {
      context.commit("set_msg_sent", payload);
    },
    SOCKET_MESSAGE_READ(context, payload) {
      context.commit("set_msg_read", {
        id_messaggio: payload._id,
        id_chat: payload.id_chat,
      });
    },
    SOCKET_MESSAGE_TYPING(context, payload) {
      context.commit("set_chat_typing", payload);
    },
    SOCKET_IMAGE_LOADED(context, payload) {
      context.commit("set_msg_img_load", { msg: payload, value: false });
    },
    SOCKET_IMAGE_WRONG(context, msg_id) {
      context.commit("set_msg_img_wrong", msg_id);
    },
    SOCKET_FILE_LOADED(context, payload) {
      context.commit("set_msg_file_load", { msg: payload, value: false });
    },
    SOCKET_CHAT_CREATED(context, payload) {
      context.commit("push_new_chat", payload);
    },
    SOCKET_NOTIF_RECEIVED(context, payload) {
      context.commit("set_msg_notified", payload);
    },
    SOCKET_CHAT_MODIFICATA(context, id_chat) {
      let url = process.env.VUE_APP_API_ROOT + "/admin/chat/" + id_chat;
      let auth = context.getters.get_credential;
      axios
        .get(url, { auth: auth })
        .then((res) => {
          if (res.data.archiviata) {
            context.commit("set_chat_empty", res.data._id);
          } else {
            context.commit("push_new_chat", res.data);
          }
        })
        .catch((err) => {
          if (err.response.status == 404) {
            context.commit("set_chat_eliminata", id_chat);
          }
        });
    },
    SOCKET_BROAD_STATUS_CHANGE(context, payload) {
      context.commit("change_broad_status", payload);
    },
    SOCKET_WAMSG_NEW_MSG(context, payload) {
      context.commit("add_wamsg", JSON.parse(payload));
    },
    SOCKET_WAMSG_STATUS_CHANGE(context, payload) {
      context.commit("change_wamsg_status", payload);
    },
  },
};
