<template>
  <div>
    <v-row class="">
      <v-col cols="10">
        <OrdiniAttivi />
      </v-col>
      <v-col cols="2">
        <v-row no-gutters>
          <v-col cols="12">
            <VisiteGiorno small />
          </v-col>
          <v-col cols="12">
            <OrdiniGiorno small class="mt-7"/>
          </v-col>
          <v-col cols="12">
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col cols="8">
        <Consegne/>
      </v-col>
      <v-col cols="4">
        <Messaggi/>
      </v-col>
      
    </v-row>

    <v-row>
      <v-col cols="6">
        <Situazione/>
      </v-col>
      <v-col cols="6">
        <ClientiOnline />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrdiniAttivi from "../../Ordini/desktop/ordini_attivi_desktop.vue";
import ClientiOnline from "../../Statistiche/desktop/clienti_online/clienti_attivi_desktop.vue";
import VisiteGiorno from "../../Statistiche/desktop/visite_giorno.vue";
import OrdiniGiorno from "../../Statistiche/desktop/ordini_giorno.vue";
import Consegne from "../../Consegne/desktop/consegne_attive.vue"
import Situazione from '../../Statistiche/desktop/situazione_prodotti.vue'
import Messaggi from '../../Chat/desktop/chat_attive.vue'

export default {
  components: {
    OrdiniAttivi,
    ClientiOnline,
    VisiteGiorno,
    OrdiniGiorno,
    Consegne,
    Situazione,
    Messaggi
  },
};
</script>