<template>
  <v-dialog v-model="dialog_unisci" width="600">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-avatar>
          <v-icon> mdi-merge </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> Unisci con ... </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="grey--text"> Unisci Ordini </v-card-title>
      <v-card-text>
        <v-row no-gutters class="pl-6" v-if="ordini_iva.length">
          <v-list>
            <v-checkbox
              v-for="ordine in ordini_iva"
              :key="ordine.numero"
              v-model="ordini_array"
              :value="ordine._id.$oid"
            >
              <template v-slot:label>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Ordine Numero # {{ ordine.numero }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      del {{ ordine.data }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-checkbox>
          </v-list>
        </v-row>
        <v-row justify="center" v-else> Nessun ordine a cui unire </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="grey" @click="dialog_unisci = false">
          Annulla
        </v-btn>
        <v-btn
          outlined
          color="primary"
          @click="unisci()"
          :loading="loading"
          :disabled="!ordini_array.length || this.loading"
        >
          Unisci
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import unisci_mixin from "../../mixin/unisci_ordini_mixin";
export default {
  mixins: [unisci_mixin],
};
</script>