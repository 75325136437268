import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      loading: true,
      cart_only: false,
      ts_inizio: moment().subtract(3, "days").format("X"),
      ts_fine: moment().format("X"),
    };
  },
  mounted() {
    this.$store
      .dispatch("fetch_accessi_clienti", {
        ts_inizio: this.ts_inizio,
        ts_fine: this.ts_fine,
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    lista_clienti_online() {
      return this.$store.getters.get_clienti_online;
    },
    lista_clienti() {
      let list = this.$store.getters.get_accessi_cliente
        .filter((log) => log.event == 1)
        .map((log) => {
          return {
            cliente: this.$store.getters.get_cliente_by_iva(log.iva),
            ts: log.ts,
          };
        })
        .filter((el) => !el.cliente.isAdmin)
        .sort((a, b) => b.cliente.last_access - a.cliente.last_access);
      list = _.uniqBy(list, "cliente.iva");
      return this.cart_only
        ? list.filter((cliente) => cliente.cliente.has_cart)
        : list;
    },
    lista_cli(){
      return this.$store.getters.get_clienti
      .filter(cli => cli.last_access > moment().subtract(3, 'days').format("X"))
      .sort((a,b)=> b.last_access - a.last_access)
    }
  },
  methods: {
    data_format(ts) {
      return moment().to(moment.unix(ts));
    },
  },
};