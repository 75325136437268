import moment from "moment";
import axios from "axios";
export default {
  props: {
    consegna: Object,
  },
  data() {
    return {
      loading: false,
      ordiniData: [],
      dialog_elimina: false,
      dialog_avvia: false,
      dialog_termina: false,
      loading_termina: false,
      dialog_archivia: false,
      loading_delete: false,
      avvia_loading: false,
      avviso_avvio: false,
      annulla_loading: false,
      loading_programma_consegna: false,
      snack_mezzo_offline: false,
      dialog_mezzo_offline: false,
    };
  },
  watch: {
    "consegna.tappe": {
      immediate: true,
      handler() {
        // this.loading = true;
        this.fetchOrdini(
          this.consegna.tappe
            .filter((tappa) => !tappa.ritorno)
            .map((tappa) => tappa.ordine)
        );
        // this.consegna.tappe.forEach((tappa) => {
        //   this.fetchOrdini(tappa.ordine);
        // });
      },
    },
  },
  computed: {
    n_scarichi() {
      return this.consegna.tappe.length - 1;
    },
    partenza_prevista() {
      return moment
        .unix(this.consegna.ts_partenza_prevista)
        .format("DD MMMM HH:mm");
    },
    ordini() {
      return this.ordiniData;
    },
    tot_cc_carico() {
      if (!this.ordini.length) {
        return 0;
      }
      return this.ordini
        .map((ordine) => {
          console.log(ordine.articoli);
          return this.tot_cc(ordine.articoli);
        })
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },
    tempo_totale() {
      let tempi = this.$store.getters.get_tempi;
      if (!this.ordini.length) {
        return 0;
      }
      return this.consegna.tappe
        .map((tappa) => {
          // let ordine = this.$store.getters.get_ordine_by_id(tappa.ordine);
          if (tappa.ritorno) {
            return parseInt(tappa.durata.value / 60);
          }
          let ordine = this.ordini.find(
            (ordine) => ordine._id.$oid == tappa.ordine
          );
          let cliente = this.$store.getters.get_cliente_by_iva(ordine.iva);
          let durata = tappa.durata.value / 60;
          let tot_cc = this.tot_cc(ordine.articoli);
          return (
            parseFloat(tot_cc) *
              (cliente.cambio_cc
                ? parseInt(tempi.tempo_cambio_cc)
                : parseInt(tempi.tempo_scarico_cc)) +
            parseInt(tempi.tempo_pagamento) +
            parseInt(durata)
          );
        })
        .reduce((tot, num) => tot + num, 0);
    },
    tempo_humanized() {
      return (
        moment.duration(this.tempo_totale, "minutes").hours() +
        " h e " +
        moment.duration(this.tempo_totale, "minutes").minutes() +
        " min"
      );
    },
    ora_arrivo_previsto() {
      return moment
        .unix(this.consegna.ts_partenza)
        .add(this.tempo_totale, "minutes")
        .format("HH:mm");
    },
    stato_mezzo(){
      return this.$store.getters.get_automezzo_status(this.consegna.automezzo)
    }
  },
  methods: {
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
    tot_cc(articoli) {
      if (!articoli.length) {
        return 0;
      }
      return parseFloat(
        articoli
          .map((item) => {
            let prodotto = this.$store.getters.get_prodotto_raw_by_id(item.art);
            if (!prodotto) {
              return 0;
            }
            let qta_cc = prodotto.qta_cc;
            if (!qta_cc) {
              return 0;
            } else {
              return (
                item.colori
                  .map((col) =>
                    parseInt(col.qta_admin ? col.qta_admin : col.numero)
                  )
                  .reduce((tot, num) => tot + num) / qta_cc
              );
            }
          })
          .reduce((tot, num) => tot + num)
          .toFixed(1)
      );
    },
    async fetchOrdini(ids) {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/";
      const promises = ids.map((id) => axios.get(url + id, { auth: auth }));
      const response = await Promise.all(promises);
      this.ordiniData = response.map((res) => JSON.parse(res.data));
      // this.loading = false;
    },
    stato_tappa(tappa) {
      if (tappa.raggiunta && !tappa.completa) {
        return {
          icona: "mdi-human-dolly",
          colore: "indigo",
        };
      } else if (tappa.raggiunta && tappa.completa) {
        return {
          icona: "mdi-check",
          colore: "green",
        };
      } else {
        return {
          icona: "mdi-clock",
          colore: "amber",
        };
      }
    },
    elimina_consegna() {
      this.loading_delete = true;
      this.$store.dispatch("elimina_consegna", this.consegna.id).finally(() => {
        this.loading_delete = false;
      });
    },
    tenta_avvio_consegna() {
      this.loading_programma_consegna = true;
      let last_update = this.$store.getters.get_mezzo_by_id(
        this.consegna.automezzo
      ).last_update;
      console.log(last_update);
      let difference = new Date().getTime() / 1000 - last_update;
      console.log(difference < 10 * 60);
      let isOnline = difference < 10 * 60;
      this.$store
        .dispatch("mezzo_consegna_in_azienda", this.consegna.id)
        .then((res) => {
          console.log("Mezzo in azienda: " + res);
          this.loading_programma_consegna = false;
          if (!isOnline) {
            console.log("Il mezzo è offline. La consegna non può partire");
            this.dialog_mezzo_offline = true;
            this.snack_mezzo_offline = true;
            return;
          }
          if (res) {
            this.avviso_avvio = true;
            this.$store.dispatch("avvia_consegna", this.consegna.id);
          } else {
            this.dialog_avvia = true;
          }
        });
    },
    programma_consegna() {
      this.loading_programma_consegna = true;
      this.$store
        .dispatch("programma_consegna", {
          id_consegna: this.consegna.id,
          id_mezzo: this.consegna.automezzo,
        })
        .then(() => {
          this.loading_programma_consegna = false;
          this.dialog_avvia = false;
        });
    },
    avvia_consegna() {
      this.avvia_loading = true;
      console.log("Avvia Consegna");
      this.$store
        .dispatch("avvia_consegna_immediato", this.consegna.id)
        .then(() => {
          this.avvia_loading = false;
          this.dialog_mezzo_offline = false
        });
    },
    archivia_consegna() {
      this.$store.dispatch("archivia_consegna", this.consegna.id);
    },
    termina_consegna() {
      this.loading_termina = true;
      this.$store.dispatch("termina_consegna", this.consegna.id).then(() => {
        this.loading_termina = false;
        this.dialog_termina = false;
      });
    },
    attiva_consegna() {
      this.loading_attiva = true;
      this.$store.dispatch("attiva_consegna", this.consegna.id).finally(() => {
        this.loading_attiva = false;
      });
    },
    annulla_consegna() {
      this.annulla_loading = true;
      this.$store.dispatch("annulla_consegna", this.consegna.id).then(() => {
        this.annulla_loading = false;
      });
    },
  },
};
