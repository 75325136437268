<template>
  <v-row no-gutters v-if="show">
    <v-col cols="8" v-if="!$vuetify.breakpoint.mobile">
      <v-container fluid fill-height class="grey lighten-4">
        <v-row justify="center">
          <v-img
            height="300"
            width="300"
            contain
            src="@/assets/logo_whole.svg"
          />
        </v-row>
      </v-container>
    </v-col>
    <v-col v-else class="d-flex align-center mb-n8">
      <v-row justify="center" class="" no-gutters align="center">
        <v-img height="150" width="150" contain src="@/assets/logo_whole.svg" />
      </v-row>
    </v-col>
    <v-col cols="12" md="4" class="mt-n8">
      <v-container fluid fill-height>
        <v-row justify="center">
          <v-col cols="11" md="8">
            <v-card elevation="0">
              <v-card-title class="grey--text text--darken-2">
                Admin Log In
                <v-icon class="ml-6"> mdi-key</v-icon>
              </v-card-title>
              <v-card-subtitle> inserisci le credenziali </v-card-subtitle>
              <v-card-text>
                <v-form v-model="valid" ref="form">
                  <v-text-field
                    v-model="username"
                    class="mt-4"
                    label="Username"
                    outlined
                    :rules="user_rule"
                  />
                  <v-text-field
                    v-model="password"
                    label="Password"
                    outlined
                    :type="show_pwd ? 'text' : 'password'"
                    @click:append="show_pwd = !show_pwd"
                    :append-icon="show_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="rules"
                  />
                  <v-row no-gutters>
                    <v-spacer />
                    <span class="indigo--text">Password Dimenticata ? </span>
                  </v-row>
                  <v-checkbox v-model="remember" label="Ricordami" />
                  <v-btn
                    :loading="loading"
                    class="mt-2"
                    block
                    rounded
                    color="primary"
                    @click="login()"
                    >LOGIN</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props:{
    show: Boolean
  },
  data() {
    return {
      show_pwd: false,
      username: "",
      password: "",
      remember: false,
      loading: false,
      auth_ok: true,
      valid: true,
      user_rule: [(v) => !!v || "Inserire Nome Utente"],
      rules: [() => this.auth_ok || "Credenziali non valide"],
    };
  },
  mounted() {
    if (localStorage.getItem("username")) {
      this.username = localStorage.getItem("username");
      if (localStorage.getItem("password")) {
        this.password = localStorage.getItem("password");
        this.remember = true;
      }
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.auth_ok = true;
      this.$refs.form.validate();
      this.$store
        .dispatch("fetch_auth", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          if (this.remember) {
            localStorage.setItem("username", this.username);
            localStorage.setItem("password", this.password);
          }
          console.log("[LOGIN] Login ok")
          this.$emit("login_ok")
        })
        .catch(() => {
          this.auth_ok = false;
          this.$refs.form.validate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>