<template>
  <!-- <v-row
    v-if="messaggio.isNlFlag"
    justify="center"
    align="center"
    class="my-2 py-1 mx-n6"
    style="background-color: rgba(255, 255, 255, 0.3)"
  >
    <v-chip color="white" class="blue--text" small> Messaggi non letti </v-chip>
  </v-row> -->
  <v-row
    class="my-2 px-4"
    :justify="wamsg.isMine ? 'end' : 'start'"
    v-observe-visibility="{ callback: visibilityChanged, once: true }"
  >
    <transition appear name="msg">
      <v-card
        elevation="1"
        color=" "
        max-width="350"
        :class="{ whgreen: wamsg.isMine }"
        class="px-2 py-1"
        @contextmenu="show"
      >
        <template v-if="wamsg.msg.type == 'text'">
          <v-row no-gutters class="">
            <!-- <template v-if="loneEmoji">
              <transition appear="" name="emoji">
                <div>
                  <span style="font-size: 50px">{{ messaggio.testo }}</span>
                </div>
              </transition>
            </template> -->
            <template>
              <span>{{ wamsg.msg.text.body }}</span>
            </template>
          </v-row>
        </template>
        <template v-if="wamsg.msg.type == 'reaction'">
            <span>{{ wamsg.msg.reaction.emoji }}</span>
        </template>
        <template v-if="wamsg.msg.type == 'image'">
          <v-dialog v-model="dialog" max-width="800">
            <template v-slot:activator="{ on }">
              <!-- <v-card
                v-if="messaggio.loading"
                elevation="0"
                height="50"
                width="50"
              >
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    size="20"
                    width="2"
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </v-card> -->
              <v-card elevation="0" width="200">
                <v-img :src="thum_url" v-on="on">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="green"
                        width="2"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                {{wamsg.msg.image.caption}}
              </v-card>
            </template>
            <v-img
              :src="img_url"
              contain
              max-height="700"
              @click="dialog = false"
            />
          </v-dialog>
        </template>
        <template v-if="wamsg.msg.type == 'document'">
          <v-card class="pa-2" elevation="0" width="300" @click="download_file()">
            <v-row align="center" class="pa-2" no-gutters>
              <v-col cols="2" class="d-flex justify-start">
                <v-icon :color="type_icon.color">{{ type_icon.icon }}</v-icon>
              </v-col>
              <v-col cols="8" class="text-truncate">
                {{ wamsg.msg.document.filename }}
              </v-col>
              <v-col cols="2">
                <v-icon>mdi-download</v-icon>
              </v-col>
              <v-col cols="12">
                <span class="grey--text" style="font-size: 12px">
                  file size:{{(wamsg.size / 1000000).toFixed(2)}} Mb</span
                >
              </v-col>
            </v-row>
          </v-card>
        </template>
        <template v-if="wamsg.msg.type == 'video'">
          <v-card class="pa-2" elevation="0" width="300" @click="download_video()">
            <v-row align="center" class="pa-2" no-gutters>
              <v-col cols="2" class="d-flex justify-start">
                <v-icon color="purple">mdi-video</v-icon>
              </v-col>
              <v-col cols="8" class="text-truncate">
                Video
              </v-col>
              <v-col cols="2">
                <v-icon>mdi-download</v-icon>
              </v-col>
              <v-col cols="12">
                <span class="grey--text" style="font-size: 12px">
                  file size: {{(wamsg.size / 1000000).toFixed(2)}} Mb</span
                >
              </v-col>
            </v-row>
          </v-card>
        </template>
        <template v-if="wamsg.msg.type == 'audio'">
          <v-card class="pa-2" elevation="0" width="300">
            <v-row no-gutters align="center">
              <v-col cols="2">
                <v-btn icon @click="download_audio()" v-if="!decodedAudio"
                  ><v-icon>mdi-download-circle-outline</v-icon></v-btn
                >
                <v-btn
                  icon
                  v-if="!on_play && decodedAudio"
                  @click="play_audio()"
                  ><v-icon>mdi-play</v-icon></v-btn
                >
                <v-btn
                  icon
                  v-if="on_play && decodedAudio"
                  @click="pause_audio()"
                  ><v-icon>mdi-pause</v-icon></v-btn
                >
              </v-col>
              <v-col cols="10">
                <v-slider :value="audioMoment" hide-details="" readonly></v-slider>
              </v-col>
              <v-col cols="12">
                <span class="grey--text" style="font-size: 12px">
                  Lunghezza: {{ audioDuration.toFixed(0) }} sec.</span
                >
              </v-col>
            </v-row>
          </v-card>
        </template>
        <v-row justify="end" align="center" no-gutters>
          <span style="font-size: 10px" class="grey--text">{{ ora }}</span>
          <template v-if="wamsg.isMine && wamsg.stato == 'pending'">
            <v-icon color="grey" size="15" class="ml-2">mdi-clock</v-icon>
          </template>
          <template v-if="wamsg.isMine && wamsg.stato == 'sent'">
            <v-icon color="grey" size="15" class="ml-2">mdi-check</v-icon>
          </template>
          <template v-if="wamsg.isMine && wamsg.stato == 'delivered'">
            <v-icon color="grey" size="15" class="ml-2">mdi-check-all</v-icon>
          </template>
          <template v-if="wamsg.isMine && wamsg.stato == 'read'">
            <v-icon color="blue" size="15" class="ml-2">mdi-check-all</v-icon>
          </template>
          <template v-if="wamsg.isMine && wamsg.stato == 'failed'">
            <v-icon color="red" size="15" class="ml-2">mdi-close</v-icon>
          </template>
        </v-row>
      </v-card>
    </transition>
    <!-- <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="elimina_messaggio()">
          <v-list-item-avatar>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Elimina</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-row>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: {
    wamsg: Object,
    user_id: String,
    chat_visibile: Boolean,
  },
  data() {
    return {
      dialog: false,
      showMenu: false,
      x: 0,
      y: 0,
      audioCtx: new AudioContext(),
      decodedAudio: null,
      audioDuration: 0,
      audioMoment: 0,
      on_play: false,
      play_timeout: null,
    };
  },
  mounted() {},
  computed: {
    // ...mapGetters({
    //   img_loaded: "set_msg_img_load",
    // }),
    // mioMsg() {
    //   return this.messaggio.autore == this.$store.getters.get_utente.iva;
    // },
    // autore() {
    //   return this.$store.getters.get_cliente_by_iva(this.messaggio.autore);
    // },
    ora() {
      return moment.unix(this.wamsg.timestamp).format("HH:mm");
    },
    img_height() {
      return (300 * this.messaggio.height) / this.messaggio.width;
    },
    loneEmoji() {
      if (!this.messaggio.isText) {
        return false;
      }
      if (this.messaggio.testo.length == 2) {
        const regexExp =
          /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
        let res = regexExp.test(this.messaggio.testo);
        return res;
      }
      return false;
    },
    type_icon() {
      switch (this.wamsg.msg.document.mime_type) {
        case "application/pdf":
          return {
            icon: "mdi-file-pdf-box",
            color: "red",
          };
        case "doc":
        case "docx":
          return {
            icon: "mdi-file-word-box",
            color: "blue",
          };
        case "xlsx":
          return {
            icon: "mdi-file-excel-box",
            color: "green",
          };
        default:
          return {
            icon: "mdi-file",
            color: "blue",
          };
      }
    },
    thum_url() {
      return process.env.VUE_APP_API_ROOT + "/wachat/foto/" + this.wamsg.msg.id;
    },
    img_url() {
      return process.env.VUE_APP_API_ROOT + "/wachat/foto/" + this.wamsg.msg.id;
    },
  },
  methods: {
    download_file() {
      let auth = this.$store.getters.get_credential;
      let url =
        process.env.VUE_APP_API_ROOT + "/wachat/file/" + this.wamsg.msg.id;
      axios.get(url, { auth: auth, responseType: "blob" }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          this.wamsg.msg.document.filename
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    download_video() {
      let auth = this.$store.getters.get_credential;
      let url =
        process.env.VUE_APP_API_ROOT + "/wachat/file/" + this.wamsg.msg.id;
      axios.get(url, { auth: auth, responseType: "blob" }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          'video.mp4'
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    download_audio() {
      // const audioCtx = new AudioContext();
      let auth = this.$store.getters.get_credential;
      let url =
        process.env.VUE_APP_API_ROOT + "/wachat/file/" + this.wamsg.msg_id;
      return axios
        .get(url, { auth: auth, responseType: "arraybuffer" })
        .then((response) => {
          console.log(response.data);
          this.audioCtx.decodeAudioData(response.data).then((decodedData) => {
            this.decodedAudio = decodedData;
            this.audioDuration = decodedData.duration;
            // const source = audioCtx.createBufferSource();
            // source.buffer = decodedData;
            // source.connect(audioCtx.destination);
            // this.audioSource = source
            // this.audioSource.start()
          });
        });
    },
    play_audio() {
      this.on_play = true;
      //   const timer = () => {
      //     console.log(this.audioDuration);
      //     this.audioMoment += 10 / parseFloat(this.audioDuration);
      //     console.log(this.audioMoment);
      //     if (this.audioMoment > 100) {
      //       clearInterval(interval);
      //       this.audioMoment = 0;
      //     }
      //   };

      const source = this.audioCtx.createBufferSource();
      source.buffer = this.decodedAudio;
      source.connect(this.audioCtx.destination);
      this.audioSource = source;
      this.audioSource.addEventListener("ended", () => {
        console.log("audio terminato");
        this.on_play = false;
      });
      let start = (this.audioDuration * this.audioMoment) / 100;
      console.log(start);
      this.audioSource.start(0, start);
      // const interval = setInterval(timer, 100);
      this.play_timeout = setInterval(() => {
        console.log(this.audioDuration);
        this.audioMoment += 10 / parseFloat(this.audioDuration);
        console.log(this.audioMoment);
        if (this.audioMoment > 100) {
          clearInterval(this.play_timeout);
          this.audioMoment = 0;
        }
      }, 100);
    },
    pause_audio() {
      this.audioSource.stop();
      clearInterval(this.play_timeout);
    },
    visibilityChanged() {
      if (!this.chat_visibile) {
        return;
      }
      if (this.wamsg.nuovo) {
        this.$store.dispatch("set_wamsg_read", {
          id_msg: this.wamsg.msg_id,
          user_id: this.user_id,
        });
      }
    },
    elimina_messaggio() {
      this.$store.dispatch("elimina_messaggio_chat", this.messaggio);
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    autore_is_admin(iva) {
      return this.$store.getters.get_cliente_by_iva(iva).isAdmin;
    },
  },
};
</script>

<style scoped>
.whgreen {
  background-color: #e1ffd7;
}

.msg-enter-active,
.msg-leave-active {
  transition: opacity 0.9s;
}
.msg-enter, .msg-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.emoji-enter-active {
  animation: bounce-in 0.5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
