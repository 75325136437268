import axios from 'axios'
import moment from 'moment'

export default {
    state: {
        logs: []
    },
    getters: {
        get_logs: state => {
            return state.logs
        },
        get_accessi_ora: state => {
            let logs = state.logs.filter(log => log.ts > moment().subtract(6, 'days').format("X")).filter(log => log.event == 1)
            let val_array = []
            for (let index = 6; index >= 0; index--) {
                let compare_hi = moment().subtract(index, 'day')
                let compare_lo = moment().subtract(index + 1, 'day')
                let l = logs.filter(log => moment.unix(log.ts).isBetween(compare_lo, compare_hi)).length
                val_array.push(l)
            }
            return val_array
        },
        get_ordini_ora: state => {
            let logs = state.logs.filter(log => log.ts > moment().subtract(6, 'days').format("X")).filter(log => log.event == 2)
            let val_array = []
            for (let index = 6; index >= 0; index--) {
                let compare_hi = moment().subtract(index, 'days')
                let compare_lo = moment().subtract(index + 1, 'days')
                let l = logs.filter(log => moment.unix(log.ts).isBetween(compare_lo, compare_hi)).length
                val_array.push(l)
            }
            return val_array
        }
    },
    mutations: {
        set_logs(state, logs) {
            state.logs = logs
        }
    },
    actions: {
        fetch_logs(context) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/admin/logs'
                let auth = context.getters.get_credential
                axios.get(url, { 'auth': auth })
                    .then(res => {
                        context.commit("set_logs", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        }
    }
}