<template>
  <v-menu
    v-model="men"
    offset-y
    bottom
    max-width="200"
    :close-on-content-click="false"
    class=""
  >
    <template v-slot:activator="{ on: men_u }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tip }">
          <v-badge
            color="green"
            :value="col"
            :content="col.numero"
            overlap
            bordered
            offset-x="20"
            offset-y="20"
          >
            <v-avatar
              v-on="{ ...tip, ...men_u }"
              @click="
                select_col(colore);
                select_box();
              "
              style="cursor: pointer"
              class="mr-2"
              size="70"
            >
              <v-img :src="color_img" :alt="colore.nome">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-badge>
        </template>
        <span class="text-capitalize">{{ colore.nome }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text class="mt-n2">
        <v-row no-gutters class="primary--text">
          {{ colore.nome }}
          <v-spacer />
          <v-icon size="20" @click="men = false"> mdi-close </v-icon>
        </v-row>
        <v-row no-gutters style="font-size: 12px" class="mb-2">
          Specifica quantità:
        </v-row>
        <v-row no-gutters align="center">
          <v-col cols="12">
            <v-text-field
              maxlength="3"
              dense
              :messages="messaggio"
              hide-details
              ref="qta_box"
              v-model="qta"
              outlined
              prepend-icon="mdi-minus"
              append-outer-icon="mdi-plus"
              :type="$vuetify.breakpoint.mobile ? 'number' : 'text'"
              @focus="select_text"
              @click:prepend="qta--"
              @click:append-outer="qta++"
              @keypress.enter="men = false"
              :rules="[(v) => !isNaN(v) || 'inserire numero intero']"
            >
              <template v-slot:append>
                <v-slide-x-reverse-transition mode="out-in">
                  <v-icon
                    color="grey"
                    @click="qta = 0"
                    v-if="!loading && !adding && !removing"
                    >mdi-delete</v-icon
                  >
                  <v-progress-circular
                    indeterminate
                    width="1"
                    size="18"
                    color="grey"
                    class="mt-1"
                    v-if="loading"
                  ></v-progress-circular>
                  <v-icon color="success" v-if="adding">mdi-check</v-icon>
                  <v-icon color="error" v-if="removing">mdi-cart-remove</v-icon>
                </v-slide-x-reverse-transition>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col cols="12">
            <!-- <div
              :style="{
                visibility: adding || removing || !valid ? 'visible' : 'hidden',
              }"
            > -->
            <!-- <span> &nbsp; </span> -->

            <!-- <transition appear name="ok_badge">
              <v-icon v-if="adding" size="20" class="mr-2 ml-7" color="success">
                mdi-check
              </v-icon>
            </transition>
            <span v-if="adding"> Aggiunto </span>

            <transition appear name="ok_badge">
              <v-icon v-if="removing" size="20" class="mr-2 ml-7" color="error"
                >mdi-cart-remove</v-icon
              >
            </transition>
            <span v-if="removing"> Rimosso </span> -->

            <span v-if="!valid">
              <v-icon size="20" class="mr-2 ml-7" color="error"
                >mdi-cancel</v-icon
              >
              Inserire Numero
            </span>
            <!-- </div> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import menu_inserimento_mixin from "../../mixin/nuovo_ordine_mixin/menu_inserimento_mixin";
export default {
  mixins: [menu_inserimento_mixin],
};
</script>

<style scoped>
.ok_badge-enter-active {
  animation: bounce-in 0.5s;
}
.ok_badge-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
