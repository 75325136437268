<template>
  <v-menu v-model="menu" left :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn small outlined color="green" v-on="on">
        <v-icon class="mr-2"> mdi-chat </v-icon>
        Chat
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text"> Seleziona cliente </v-card-title>
      <v-card-text>
        <v-autocomplete
          class="my-2"
          label="Seleziona Cliente"
          v-model="iva_cliente"
          outlined
          :items="clienti"
          item-text="nome"
          item-value="iva"
          hide-details
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="green" @click="inizia_chat()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      iva_cliente: null,
    };
  },
  computed: {
    clienti() {
      return this.$store.getters.get_clienti;
    },
  },
  methods: {
    inizia_chat() {
      if (!this.iva_cliente) {
        return;
      }
      this.$store
        .dispatch("start_new_chat", this.iva_cliente)
        .then((res)=>{
          this.$emit("chat_start", res)
        })
        .catch((err) => {
          if (err.response.status == 409) {
            alert("Chat già attiva");
          }
        })
        .finally(() => {
          this.menu = false;
        });
    },
  },
};
</script>