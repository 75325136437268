<template>
  <v-menu v-model="menu">
    <template v-slot:activator="{ on }">
      <v-btn :icon="!testo" :outlined="testo" color="indigo" v-on="on">
        <v-icon>mdi-truck-check</v-icon> <span v-if="testo" class="ml-2">Archivia</span>
      </v-btn>
    </template>
    <v-card width="105">
      <v-card-text>
        <v-btn icon color="blue" :loading="loading" @click="archivia(false)"
          ><v-icon>mdi-thumb-up</v-icon></v-btn
        >
        <v-btn icon color="green" :loading="loading" @click="archivia(true)"
          ><v-icon>mdi-thumb-down</v-icon></v-btn
        >
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    id_ordine: String,
    testo: Boolean,
  },
  data() {
    return {
      menu: false,
      loading: false,
    };
  },
  methods: {
    archivia(verde) {
      this.loading = true;
      this.$store
        .dispatch("archivia_ordine", {
          id_ordine: this.id_ordine,
          verde: verde,
          sid: this.$socket.id
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>