import axios from "axios";
import moment from "moment";

export default {
  state: {
    now: null,
    list: null,
  },
  getters: {
    get_weath_now: (state) => {
      return state.now;
    },
    get_today_forecast: (state) => {
      if (!state.list) {
        return null;
      }
      return state.list.filter(
        (el) => el.dt < moment().endOf("day").format("X")
      );
    },
    get_forecast: (state) => {
      if (!state.list) {
        return null;
      }
      return [
        state.list.filter(
          (el) =>
            el.dt > moment().add(1, "day").startOf("day").format("X") &&
            el.dt < moment().add(1, "day").endOf("day").format("X")
        ),
        state.list.filter(
          (el) =>
            el.dt > moment().add(2, "day").startOf("day").format("X") &&
            el.dt < moment().add(2, "day").endOf("day").format("X")
        ),
        state.list.filter(
          (el) =>
            el.dt > moment().add(3, "day").startOf("day").format("X") &&
            el.dt < moment().add(3, "day").endOf("day").format("X")
        ),
      ];
    },
  },
  mutations: {
    set_weath_now(state, data) {
      state.now = data;
    },
    set_weath_forecast(state, data) {
      state.list = data;
    },
  },
  actions: {
    fetch_weather_now(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/weather";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_weath_now", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_weather_forecast(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/weather_forecast";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_weath_forecast", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_NEW_WEATHER(context, payload){
      context.commit("set_weath_now", payload.now)
      context.commit("set_weath_forecast", payload.list)
    }
  },
};
