import axios from "axios";

export default {
  state: {
    automezzi: [],
  },
  getters: {
    get_automezzi: (state) => {
      return state.automezzi;
    },
    get_mezzo_by_id: (state) => (id) => {
      return state.automezzi.find((mezzo) => mezzo.id == id);
    },
    get_storico_gps_by_id: (state) => (id) => {
      return state.automezzi.find((mezzo) => mezzo.id == id).storico;
    },
    get_storico_gps: (state) => {
      return state.automezzi[2];
    },
    get_automezzo_status: (state) => (id_mezzo) => {
      let mezzo = state.automezzi.find((automezzo) => automezzo.id == id_mezzo);
      return {
        network: mezzo.last_update > Date.now() / 1000 - 600,
        gps: mezzo.gps_status,
      };
    },
  },
  mutations: {
    set_automezzi(state, automezzi) {
      state.automezzi = automezzi;
    },
    set_new_automezzo(state, mezzo) {
      state.automezzi.push(mezzo);
    },
    set_delete_automezzo(state, id_mezzo) {
      let index = state.automezzi.findIndex((mezzo) => mezzo.id == id_mezzo);
      if (index > -1) {
        state.automezzi.splice(index, 1);
      }
    },
    set_automezzo_status(state, payload) {
      state.automezzi.findIndex((mezzo) => mezzo.id == payload.id_automezzo);
    },
    update_mezzo(state, payload) {
      console.log("[AUTOMEZZI - MUTATION] Updating mezzo");
      console.log(payload);
      let mezzo = state.automezzi.find((mezzo) => mezzo.id == payload.id);
      mezzo.last_update = payload.last_update;
      mezzo.latitudine = payload.latitudine;
      mezzo.longitudine = payload.longitudine;
      mezzo.speed = payload.speed;
      mezzo.id_consegna = payload.id_consegna;
      mezzo.in_consegna = payload.in_consegna;
    },
    set_storico(state, payload) {
      let mezzo = state.automezzi.find((mezzo) => mezzo.id == payload.id_mezzo);
      mezzo.storico = payload.value;
    },
    update_storico(state, payload) {
      let mezzo = state.automezzi.find((mezzo) => mezzo.id == payload.id_mezzo);
      mezzo.storico.push({
        lat: payload.lat,
        lng: payload.lng,
        speed: payload.speed,
      });
    },
  },
  actions: {
    fetch_automezzi(context) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url = process.env.VUE_APP_API_ROOT + "/admin/automezzi";
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_automezzi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_storico_gps(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/automezzo/" +
          payload.id_mezzo +
          "/storico_gps/ts_inizio/" +
          payload.ts_inizio +
          "/ts_fine/" +
          payload.ts_fine;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            console.log(res);
            context.commit("set_storico", {
              id_mezzo: payload.id_mezzo,
              value: res.data,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_storico_gps_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/automezzo/" +
          payload.id_mezzo +
          "/consegna/" +
          payload.id_consegna;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            console.log(res);
            context.commit("set_storico", {
              id_mezzo: payload.id_mezzo,
              value: res.data,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    nuovo_automezzo(context, mezzo) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url = process.env.VUE_APP_API_ROOT + "/admin/automezzo";
        axios
          .post(url, mezzo, { auth: auth })
          .then((res) => {
            context.commit("set_new_automezzo", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_automezzo(context, id_mezzo) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url = process.env.VUE_APP_API_ROOT + "/admin/automezzo/" + id_mezzo;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_delete_automezzo", id_mezzo);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_GPS_UPDATE(context, payload) {
      context.commit("update_mezzo", payload);
    },
    SOCKET_STORICO_GPS_UPDATE(context, payload) {
      context.commit("update_storico", payload);
    },
  },
};
