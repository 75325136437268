export default {
  data() {
    return {
      chat: null,
      show_chat: false,
      current_sel: 0,
    };
  },
  computed: {
    chats() {
      return this.$store.getters.get_chats;
    },
    broadcasts() {
      return this.$store.getters.get_broadcasts;
    },
    wamsgs() {
      return this.$store.getters.get_wamsgs;
    },
    wamsg_nl() {
      return this.$store.getters.get_wachats_nl;
    },
    msg_nl(){
      return this.$store.getters.get_chats_nl;

    }
  },
  methods: {
    open_chat_dialog: function (chat) {
      this.chat = chat;
      setTimeout(() => {
        this.$refs.chat_dialog.open();
      }, 100);
    },
    close_chat_dialog() {
      this.$refs.chat_dialog.close();
      setTimeout(() => {
        this.chat = null;
      }, 1000);
    },
  },
};
