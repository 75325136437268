<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-avatar>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-content> Rif. Ordini </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title> Ordini di Riferimento </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="(el, index) in ordini" :key="el.numero">
            <v-list-item-content>
              <v-list-item-title> Ordine # {{ el.numero }} </v-list-item-title>
              <v-list-item-subtitle> del {{ el.data }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <template v-if="$vuetify.breakpoint.mobile">
                <v-icon @click="$refs.ordine_dettaglio[index].open()">mdi-open-in-new</v-icon>
                <OrdineMobileDialog :ordine="el" ref="ordine_dettaglio" icon/>
              </template>
              <template v-else>
                <OrdineDialog :ordine="el" />
              </template>
              
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OrdineDialog from "./ordine_dettaglio/ordine_dettaglio_dialog.vue";
import OrdineMobileDialog from "../mobile/ordine_dettaglio/ordine_dettaglio_mobile.vue"
export default {
  components: {
    OrdineDialog,
    OrdineMobileDialog
  },
  props: {
    lista_uniti: Array,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      ordini: [],
    };
  },
  mounted() {
    this.lista_uniti.forEach((ordine) => {
      this.$store.dispatch("fetch_ordine_by_id", ordine).then((res) => {
        this.ordini.push(res);
      });
    });
  },
};
</script>