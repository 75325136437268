<template>
  <v-menu v-model="menu_cambio_data" offset-y>
    <template v-slot:activator="{ on }">
      <v-col
        cols="12"
        class="mt-n1"
        style="font-size: 12px; cursor: pointer"
        v-on="on"
        v-if="!span"
      >
        {{ ritiro_date ? ritiro_date : "non specificato" }}
      </v-col>
      <span v-else v-on="on" style="cursor: pointer">{{ritiro_date ? ritiro_date : "non specificato"}}</span>
    </template>
    <v-card>
      <v-card-title class="primary--text"> Cambia Data </v-card-title>
      <v-card-text>
        <v-row justify="start" class="" no-gutters>
          <v-col cols="7">
            <v-dialog
              ref="dialog"
              v-model="modal_date"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatter"
                  label="Data di Ritito"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'inserire una data']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                scrollable
                locale="it-it"
                :min="minDate"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal_date = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="5">
            <v-dialog
              ref="dialog_time"
              v-model="modal_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  label="Ora di Ritiro"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'inserire un orario']"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modal_time"
                v-model="time"
                full-width
                format="24hr"
                :allowed-hours="(v) => v >= 8 && v <= 19 && v != 12 && v != 13"
                @click:hour="closePicker"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal_time = false">
                  Cancel
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="menu_cambio_data = false">
          Annulla
        </v-btn>
        <v-btn
          outlined
          color="primary"
          @click="confirm_date_change()"
          :disabled="!validDate"
        >
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import cambia_data_mixin from "../../mixin/cambia_data_ordine_mixin";
export default {
  mixins: [cambia_data_mixin],
};
</script>