import Vue from "vue";
import Vuex from "vuex";

import ordini from "./modules/ordini";
import utente from "./modules/utente";
import clienti from "./modules/clienti";
import prodotti from "./modules/prodotti";
import logs from "./modules/logs";
import carrello from "./modules/carrello";
import parametri from "./modules/parametri";
import consegne from "./modules/consegne";
import chat from "./modules/chat";
import conteggi from "./modules/conteggi";
import fornitori from "./modules/fornitori";
import lotti from "./modules/lotti";
import statistiche from "./modules/statistiche";
import weather from "./modules/weather";
import automezzi from "./modules/automezzi";
import crisantemi from "./modules/crisantemi"
import tanks from "./modules/tanks"
import calendario_arrivi from "./modules/calendario_arrivi"
import qdc from "./modules/qdc"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ordini,
    utente,
    clienti,
    prodotti,
    logs,
    carrello,
    parametri,
    consegne,
    chat,
    conteggi,
    fornitori,
    lotti,
    statistiche,
    weather,
    automezzi,
    crisantemi,
    tanks,
    calendario_arrivi,
    qdc
  },
});
