<template>
  <v-dialog width="1200" v-model="dialog" scrollable persistent>
    <template v-slot:activator="{ on }">
      <v-btn outlined color="primary" v-on="on">
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Nuovo Ordine
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text"> Crea Nuovo Ordine </v-card-title>
      <v-card-text>
        <v-row align="center">
          <v-col cols="6">
            <v-autocomplete
              prepend-inner-icon="mdi-account"
              class="my-2"
              label="Seleziona Cliente"
              v-model="cliente_sel"
              outlined
              :items="clienti"
              item-text="nome"
              item-value="iva"
              hide-details
              @change="ottieni_carrello()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <Listino v-if="cliente_sel" />
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>

        <template v-if="cliente_sel">
          <v-divider class="mb-2" />
          <v-card-subtitle> Carrello </v-card-subtitle>
          <template v-if="loading_cart">
            <v-row justify="center">
              <v-progress-circular
                indeterminate
                width="2"
              ></v-progress-circular>
            </v-row>
          </template>

          <template v-else>
            <v-row v-if="!cart_cnt" justify="center" align="center">
              Nessuno Prodotto nel Carrello
            </v-row>
            <CarrelloEl
              v-for="prodotto in carrello.prodotti"
              :item="prodotto"
              :key="prodotto.art"
              @edit="edit_prod"
            />
          </template>

          <v-row class="mt-2 mb-2" v-if="cliente_sel" align="center">
            <v-col cols="4">
              <v-autocomplete
                v-model="prodotto_sel"
                outlined
                hide-details
                label="Prodotto extra listino"
                :items="prodotti"
                item-text="nome"
                item-value="id"
                @change="open_dialog()"
              >
                <template v-slot:selection="data">
                  <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                  <template>
                    <v-list-item-avatar>
                      <v-img :src="avatar_img(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="nome_split(data.item.nome)[0]"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_split(data.item.nome)[1]"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:item="data">
                  <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                  <template>
                    <v-list-item-avatar>
                      <img :src="avatar_img(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="nome_split(data.item.nome)[0]"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="nome_split(data.item.nome)[1]"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-btn icon @click="open_dialog()" v-if="prodotto_sel">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider />

          <!-- dialog Prodotto -->

          <template v-if="prodotto_sel != null">
            <ProdottoDialog :item="prodotto" ref="dialog_prodotto" />
          </template>

          <v-row class="mt-4">
            <v-col cols="8">
              <v-row justify="start" no-gutters>
                <v-col cols="4" offset="" class="">
                  <v-form ref="metodo_form" v-model="metodo_valid">
                    <v-radio-group v-model="metodo_trasporto">
                      <v-radio value="1">
                        <template v-slot:label>
                          <div>Consegna Nostra</div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>Ritiro Cliente</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row no-gutters align="center">
                <v-col cols="6">
                  <b>Totale merce</b>
                </v-col>
                <v-col cols="6">
                  <b>€ {{ tot_merce.toFixed(2) }}</b> + iva
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  Trasporto
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" size="20" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span
                      >Trasporto gratuito per ordini superiori ai
                      {{ trasporto.soglia }} €</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col cols="6"> € {{ tot_trasp.toFixed(2) }} </v-col>
              </v-row>
              <v-divider class="my-1" />
              <v-row no-gutters align="center">
                <v-col cols="6">
                  <b>Totale</b>
                </v-col>
                <v-col cols="6">
                  <b>€ {{ cart_tot }}</b> + iva
                </v-col>
              </v-row>
              <v-row no-gutters align="center">
                <v-col cols="6">
                  <b>Carrelli</b>
                </v-col>
                <v-col cols="6">
                  <b>{{ tot_cc }}</b>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Concludi ordine -->

          <v-row v-if="metodo_trasporto == 1" class="">
            <v-col cols="6" offset="0" class="">
              <v-autocomplete
                prepend-inner-icon="mdi-google-maps"
                outlined
                label="destinazione"
                v-model="destinazione_sel"
                hide-details
                :items="destinazioni_cliente"
                item-text="indirizzo"
                item-value="id"
              >
                <!-- <template v-slot:item="data">
                  <v-list-item >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.indirizzo }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{data.item.plus_code}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:select="data">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.indirizzo }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template> -->
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" offset="0">
              <v-select
                prepend-inner-icon="mdi-cart"
                outlined
                label="Tipo di carrello"
                v-model="tipo_cc_sel"
                hide-details
                :items="tipo_carrello"
                item-text="text"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="4" offset="">
              <v-switch
                inset
                class="mt-n1"
                hide-details
                v-model="specifica_data"
                label="Specifica Data"
              ></v-switch>
            </v-col>
          </v-row>

          <template v-if="specifica_data">
            <!-- Date Pickers  -->

            <v-row justify="center" @click="set_prima_data()">
              <v-col cols="8">
                Prima data disponibile:
                <br />
                <span
                  style="font-size: 16px; cursor: pointer"
                  class="blue--text font-weight-light"
                  >{{ prima_data.giorno }} ore {{ prima_data.ora }}:00</span
                >
              </v-col>
            </v-row>

            <v-form v-model="orario_valid" ref="orario_form">
              <v-row justify="center" class="">
                <v-col cols="5">
                  <v-menu
                    v-model="modal_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatter"
                        label="Data di Ritito"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'inserire una data']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      scrollable
                      locale="it-it"
                      :min="minDate"
                      :max="maxDate"
                      @input="modal_date = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu
                    v-model="modal_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time"
                        label="Ora di Ritito"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'inserire un orario']"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :min="minHour"
                      v-if="modal_time"
                      v-model="time"
                      full-width
                      format="24hr"
                      @click:hour="closePiker"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </template>

          <v-row>
            <v-col cols="8" offset="2">
              <v-textarea
                label="Commento Finale"
                outlined
                v-model="commento"
                height="75px"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="6"> </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          :disabled="!cart_cnt || !destinazione_sel"
          outlined
          color="primary"
          :loading="on_ending"
          @click="concludi_ordine()"
        >
          <v-icon class="mr-2"> mdi-cart </v-icon>
          concludi ordine
        </v-btn>
        <v-btn outlined color="grey" @click="dialog = false"> chiudi </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CarrelloEl from "./carrello_element_desktop";
//import ListaCarrello from './lista_carrello'
import ProdottoDialog from "./dialog_carrello";
import Listino from "./dialog_lista_dispo";

import carrello_mixin from "../../mixin/nuovo_ordine_mixin/crea_nuovo_ordine_mixin";
import concludi_ordine_mixin from "../../mixin/nuovo_ordine_mixin/concludi_ordine_mixin";
import datepicker_mixin from "../../mixin/nuovo_ordine_mixin/date_picker_mixin";
export default {
  components: {
    //ListaCarrello,
    CarrelloEl,
    ProdottoDialog,
    Listino,
  },
  mixins: [carrello_mixin, concludi_ordine_mixin, datepicker_mixin],
  data() {
    return {
      radioGroup: 0,
    };
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
</style>