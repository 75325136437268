<template>
  <v-dialog v-model="dialog" max-width="700">
    <template v-slot:activator="{ on }" v-if="!no_icon">
      <v-btn icon color="grey" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Modifica Quantità
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <span style="font-size: 20px"> {{ nome[0] }}</span>
            <br />
            <span style="font-size: 12px"> {{ nome[1] }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mobile ? 3 : 2" v-for="colore in prodotto_obj.colori" :key="colore.id">
            <AggiungiColore
              :colore="colore"
              :prodotto="prodotto"
              :ordine="ordine"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              hide-details
              v-model="commento"
              label="Commento"
            >
              <template v-slot:append>
                <v-slide-x-reverse-transition mode="out-in">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    width="1"
                    size="20"
                    v-if="loading_commento"
                  />
                  <v-icon color="success" v-if="commento_ok">mdi-check</v-icon>
                </v-slide-x-reverse-transition>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AggiungiColore from "./ordine_aggiungi_colore.vue";
import _ from "lodash";
export default {
  components: {
    AggiungiColore,
  },
  props: {
    prodotto: String,
    ordine: Object,
    no_icon: Boolean,
  },
  created() {
    this.debouncecmt = _.debounce(this.send_commento, 1200);
  },
  data() {
    return {
      dialog: false,
      cmt_to_send: "",
      loading_commento: false,
      commento_ok: false,
    };
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.$emit("close");
      }
    },
  },
  computed: {
    prodotto_obj() {
      return this.$store.getters.get_prodotto_by_id(this.prodotto);
    },
    nome() {
      return this.prodotto_obj.nome.split(" in ");
    },
    commento: {
      get() {
        return this.ordine.articoli.find((art) => art.art == this.prodotto)
          .commento;
      },
      set(cmt) {
        this.cmt_to_send = cmt;
        this.debouncecmt();
      },
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    send_commento() {
      this.loading_commento = true;
      this.$store
        .dispatch("modifica_commento", {
          ordine: this.ordine._id.$oid,
          id_prodotto: this.prodotto,
          commento: this.cmt_to_send,
        })
        .then(() => {
          this.loading_commento = false;
          this.commento_ok = true;
          setTimeout(() => {
            this.commento_ok = false;
          }, 1500);
        });
    },
  },
};
</script>