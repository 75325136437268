var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.span)?_c('v-col',_vm._g({staticClass:"mt-n1",staticStyle:{"font-size":"12px","cursor":"pointer"},attrs:{"cols":"12"}},on),[_vm._v(" "+_vm._s(_vm.ritiro_date ? _vm.ritiro_date : "non specificato")+" ")]):_c('span',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(_vm._s(_vm.ritiro_date ? _vm.ritiro_date : "non specificato"))])]}}]),model:{value:(_vm.menu_cambio_data),callback:function ($$v) {_vm.menu_cambio_data=$$v},expression:"menu_cambio_data"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Cambia Data ")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data di Ritito","prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || 'inserire una data'; }]},model:{value:(_vm.dateFormatter),callback:function ($$v) {_vm.dateFormatter=$$v},expression:"dateFormatter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal_date),callback:function ($$v) {_vm.modal_date=$$v},expression:"modal_date"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"it-it","min":_vm.minDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal_date = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-dialog',{ref:"dialog_time",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ora di Ritiro","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[function (v) { return !!v || 'inserire un orario'; }]},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal_time),callback:function ($$v) {_vm.modal_time=$$v},expression:"modal_time"}},[(_vm.modal_time)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","allowed-hours":function (v) { return v >= 8 && v <= 19 && v != 12 && v != 13; }},on:{"click:hour":_vm.closePicker},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal_time = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.menu_cambio_data = false}}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":!_vm.validDate},on:{"click":function($event){return _vm.confirm_date_change()}}},[_vm._v(" Conferma ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }