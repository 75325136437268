import axios from "axios";
export default {
  state: {
    fitofarmaci: [],
    zone: [],
    avversita: [],
    eventi: [],
    fito_update: {
      processati: 0,
      aggiunti: 0,
      percent: 0,
    },
  },
  getters: {
    get_fitofarmaci: (state) => {
      return state.fitofarmaci;
    },
    get_fitofarmaco_by_id: (state) => (id) => {
      return state.fitofarmaci.find((fito) => fito.id_fitofarmaco == id);
    },
    get_avversita: (state) => {
      return state.avversita;
    },
    get_avversita_by_id: (state) => (id) => {
      return state.avversita.find((avver) => avver.id == id);
    },
    get_zone_coltivazione: (state) => {
      return state.zone;
    },
    get_zona_by_id: (state) => (id) => {
      return state.zone.find((zona) => zona.id == id);
    },
    get_eventi_qdc: (state) => {
      return state.eventi;
    },
    get_fito_update: (state) => {
      return state.fito_update;
    },
  },
  mutations: {
    set_prodotti_fito(state, payload) {
      state.fitofarmaci = payload;
    },
    set_nuovo_prodotto_fito(state, payload) {
      state.fitofarmaci.push(payload);
    },
    del_prodotto_fito(state, payload) {
      let index = state.fitofarmaci.findIndex(
        (fito) => fito.id_fitofarmaco == payload.id_prodotto
      );
      state.fitofarmaci.splice(index, 1);
    },
    set_nuovo_movimento_carico_prodotto_fito(state, payload) {
      let fito = state.fitofarmaci.find(
        (fito) => fito.id_fitofarmaco == payload.id_prodotto
      );
      fito.movimenti_carico.push(payload.movimento);
    },
    del_movimento_carico_prodotto_fito(state, payload) {
      let fito = state.fitofarmaci.find(
        (fito) => fito.id_fitofarmaco == payload.id_prodotto
      );
      let index = fito.movimenti_carico.findIndex(
        (mov) => mov.id == payload.id_carico
      );
      fito.movimenti_carico.splice(index, 1);
    },
    set_nuovo_movimento_scarico_prodotto_fito(state, payload) {
      let fito = state.fitofarmaci.find(
        (fito) => fito.id_fitofarmaco == payload.id_prodotto
      );
      fito.movimenti_scarico.push(payload.movimento);
    },
    del_movimento_scarico_prodotto_fito(state, payload) {
      let fito = state.fitofarmaci.find(
        (fito) => fito.id_fitofarmaco == payload.id_prodotto
      );
      let index = fito.movimenti_scarico.findIndex(
        (mov) => mov.id == payload.id_scarico
      );
      fito.movimenti_scarico.splice(index, 1);
    },
    set_fito_update(state, fito_update) {
      state.fito_update = fito_update;
    },

    // Mutation Avversità

    set_avversita_fito(state, payload) {
      state.avversita = payload;
    },
    set_nuova_avversita_fito(state, payload) {
      state.avversita.push(payload);
    },
    del_avversita_fito(state, payload) {
      let index = state.avversita.findIndex((avv) => avv.id == payload.id_avv);
      state.avversita.splice(index, 1);
    },

    // Mutation Zone

    set_zone_coltivazione(state, payload) {
      state.zone = payload;
    },
    set_nuova_zona_coltivazione(state, payload) {
      state.zone.push(payload);
    },
    del_zona_coltivazione(state, payload) {
      let index = state.zone.findIndex((zon) => zon.id == payload.id_zona);
      state.zone.splice(index, 1);
    },

    // Mutation Eventi

    add_nuovo_evento(state, payload) {
      state.eventi.unshift(payload);
    },
    set_eventi_qdc(state, payload) {
      state.eventi = payload;
    },
    del_evento(state, id_evento) {
      let index = state.eventi.findIndex((evento) => evento.id == id_evento);
      console.log(index);
      state.eventi.splice(index, 1);
    },
  },
  actions: {
    fetch_fitofarmaci(context) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/prodotti_fito_magazzino";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_prodotti_fito", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_prodotto_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto_fito_magazzino/" +
          payload;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            console.log(res);
            if (res.data.res == "ok") {
              context.commit("set_nuovo_prodotto_fito", res.data.prodotto);
              resolve(res.data);
            } else {
              resolve("exist");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_prodotto_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto_fito_magazzino/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            if (res.data.res == "ok") {
              context.commit("del_prodotto_fito", payload);
            }
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_movimento_prodotto_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto_fito_magazzino/" +
          payload.id_prodotto +
          "/carico";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            context.commit("set_nuovo_movimento_carico_prodotto_fito", {
              id_prodotto: payload.id_prodotto,
              movimento: res.data,
            });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_movimento_prodotto_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto_fito_magazzino/" +
          payload.id_prodotto +
          "/carico/" +
          payload.id_carico;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("del_movimento_carico_prodotto_fito", payload);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_movimento_scarico_prodotto_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto_fito_magazzino/" +
          payload.id_prodotto +
          "/scarico";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            context.commit("set_nuovo_movimento_scarico_prodotto_fito", {
              id_prodotto: payload.id_prodotto,
              movimento: res.data,
            });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_movimento_scarico_prodotto_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/prodotto_fito_magazzino/" +
          payload.id_prodotto +
          "/scarico/" +
          payload.id_scarico;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("del_movimento_scarico_prodotto_fito", payload);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Gestione Avversità

    fetch_avversita_fito(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/qdc/avversita";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_avversita_fito", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_avversit_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/qdc/avversita";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            context.commit("set_nuova_avversita_fito", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    remove_avversit_fito(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/qdc/avversita/" +
          payload.id_avv;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            if (res.data.res == "forbidden") {
              resolve(res.data);
            } else {
              context.commit("del_avversita_fito", payload);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Gestione Zone di Coltivazione

    fetch_zone_coltivazione(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/qdc/zone";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_zone_coltivazione", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_zona_coltivazione(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/qdc/zona";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            context.commit("set_nuova_zona_coltivazione", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    remove_zona_coltivazione(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/qdc/zona/" + payload.id_zona;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            if (res.data.res == "forbidden") {
              resolve(res.data);
            } else {
              context.commit("del_zona_coltivazione", payload);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    move_zona_up(context, id_zona) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/qdc/zona/" +
          id_zona +
          "/move_up";
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_zone_coltivazione", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    move_zona_down(context, id_zona) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/qdc/zona/" +
          id_zona +
          "/move_down";
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_zone_coltivazione", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Gestione Eventi

    fetch_eventi_qdc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/qdc/eventi/ts_inizio/" +
          payload.ts_inizio +
          "/ts_fine/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_eventi_qdc", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    salva_nuovo_evento(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/qdc/evento";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            console.log(res.data);
            context.commit("add_nuovo_evento", res.data.evento);
            res.data.movimenti.forEach((movimento) => {
              context.commit("set_nuovo_movimento_scarico_prodotto_fito", {
                id_prodotto: movimento.id_fito,
                movimento: movimento.scarico,
              });
            });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_evento(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/qdc/evento/" +
          payload.id_evento;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("del_evento", payload.id_evento);
            context.dispatch("fetch_fitofarmaci");
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fito_update(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/aggiorna_fitofarmaci";
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_FITO_PROGRESS(context, payload) {
      context.commit("set_fito_update", payload);
    },
  },
};
