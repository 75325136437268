<template>
  <v-card min-height="400" class="scroll">
    <v-card-title class="grey--text text-subtitle-1">
      <v-badge :value="msg_nl" :content="msg_nl" offset-x="20">
        <v-icon :color="current_sel == 0 ? 'blue' : ''" size="20" class="mr-1">mdi-chat</v-icon>
        <span
          :class="
            current_sel == 0 ? 'blue--text text-decoration-underline' : ''
          "
          class="mr-4"
          style="cursor: pointer"
          @click="current_sel = 0"
        >
          Messaggi</span
        >
      </v-badge>

      <v-badge :value="wamsg_nl" :content="wamsg_nl" offset-x="20">
        <v-icon :color="current_sel == 2 ? 'green accent-3' : ''" size="20" class="mr-1">mdi-whatsapp</v-icon>
        <span
          :class="
            current_sel == 2 ? 'blue--text text-decoration-underline' : ''
          "
          class="mr-4"
          style="cursor: pointer"
          @click="current_sel = 2"
          >Whatsapp</span
        >
      </v-badge>
        <v-icon :color="current_sel == 1 ? 'purple' : ''" size="20" class="mr-1">mdi-bullhorn-variant</v-icon>

      <span
        :class="current_sel == 1 ? 'blue--text text-decoration-underline' : ''"
        style="cursor: pointer"
        @click="current_sel = 1"
        >Annunci</span
      >
      <v-spacer></v-spacer>
      <NuovaChat @chat_start="open_chat_dialog" v-if="current_sel == 0" />
      <v-btn
        outlined
        small
        color="blue"
        class="ml-2"
        @click="$refs.broad_dialog.open()"
        v-if="current_sel == 1"
      >
        <v-icon  class="mr-2"> mdi-bullhorn-variant</v-icon>
        Annuncio
      </v-btn>
      <NuovoBroadcast ref="broad_dialog" />
      <ChatDialog
        ref="chat_dialog"
        v-if="chat"
        :chat="chat"
        @chat_archived="close_chat_dialog"
      />
    </v-card-title>
    <v-divider class="" />
    <v-card-text v-if="current_sel == 0" class="scroll">
      <v-row v-if="!chats.length" justify="center" class="mt-12">
        Nessun Messaggio
      </v-row>
      <v-list>
        <MessaggioEl v-for="msg in chats" :key="msg.id" :chat="msg" />
      </v-list>
    </v-card-text>
    <v-card-text v-if="current_sel == 1" class="scroll">
      <!-- {{broadcasts}} -->
      <BroadEl
        v-for="broad in broadcasts"
        :key="broad._id.$oid"
        :broad="broad"
      />
    </v-card-text>
    <v-card-text v-if="current_sel == 2" class="scroll">
      <v-list>
        <WaChatEl v-for="chat in wamsgs" :chat="chat" :key="chat._id" />
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import messaggi_mixin from "../mixin/messaggi_mixin";
import MessaggioEl from "./messaggio_element.vue";
import BroadEl from "./broadcast_element.vue";
import NuovaChat from "./nuova_chat_desktop.vue";
import NuovoBroadcast from "./nuovo_broadcast_desktop.vue";
import ChatDialog from "./messaggio_dialog.vue";
import WaChatEl from "./wachat_element.vue";
export default {
  components: {
    MessaggioEl,
    BroadEl,
    NuovaChat,
    NuovoBroadcast,
    ChatDialog,
    WaChatEl,
  },
  mixins: [messaggi_mixin],
  computed: {},
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  overflow-y: scroll;
  height: 320px;
}
</style>
