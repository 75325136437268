import _ from "lodash";
export default {
  props: {
    colore: Object,
    prodotto: String,
    ordine: Object,
  },
  created() {
    this.debounceqta = _.debounce(this.set_qta, 400);
  },
  mounted() {
    let ordine = this.$store.getters.get_ordine_by_id(this.ordine._id.$oid);
    let articolo = ordine.articoli.find((art) => art.art == this.prodotto);
    if(!articolo){
      return
    }
    let colore = articolo.colori.find((col) => col.id == this.colore.id);
    if (colore) {
      if (!colore.eliminato) {
        this.qta = colore.qta_admin;
        this.qta_user = colore.numero;
      }
    }
  },
  data() {
    return {
      menu: false,
      valid: true,
      qta_user: 0,
      qta: 0,
      loading: false,
      adding: false,
      removing: false,
      on_action: false,
      loading_elimina: false,
    };
  },
  watch: {
    qta: function () {
      if (isNaN(this.qta)) {
        return;
      }
      if (this.qta < 0) {
        this.qta = 0;
      } else if (this.qta > 500) {
        this.qta = 500;
      }
      if (this.qta == 0 && (!this.color_in_order || this.color_in_order.eliminato)) {
        return;
      }
      this.debounceqta();
    },
    menu: function () {
      if (this.menu) {
        this.select_box();
      }
    },
  },
  computed: {
    color_in_order() {
      let ordine = this.$store.getters.get_ordine_by_id(this.ordine._id.$oid);
      if (!ordine) {
        return null;
      }
      let articolo = ordine.articoli.find((art) => art.art == this.prodotto);
      if (!articolo) {
        return null;
      }
      return articolo.colori.find((col) => col.id == this.colore.id);
    },
    color_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto +
        "/img_colore/" +
        this.colore.id
      );
    },
  },
  methods: {
    set_qta() {
      this.loading = true;
      this.on_action = true;
      console.log("qta inserita: " + this.qta);
      if (this.qta == null || this.qta == "") {
        this.qta = 0;
      }
      // this.$store
      //   .dispatch("elimina_colore_ordine", {
      //     id_ordine: this.ordine._id.$oid,
      //     id_prodotto: this.prodotto,
      //     id_colore: this.colore.id,
      //   })
      //   .then(() => {
      //     this.loading = false;
      //     setTimeout(() => {
      //       this.removing = true;
      //     }, 100);
      //     setTimeout(() => {
      //       this.removing = false;
      //       this.on_action = false;
      //     }, 1500);
      //   })
      //   .finally(() => {});
      // } else {
      this.$store
        .dispatch("aggiungi_colore_ordine", {
          id_ordine: this.ordine._id.$oid,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
          qta: parseInt(this.qta),
        })
        .then(() => {
          setTimeout(() => {
            this.adding = true;
          }, 100);
          setTimeout(() => {
            this.adding = false;
            this.on_action = false;
          }, 1500);
        })
        .finally(() => {
          this.loading = false;
        });
      // }
    },
    elimina_colore() {
      this.loading_elimina = true;
      this.$store
        .dispatch("elimina_colore_ordine", {
          id_ordine: this.ordine._id.$oid,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
        })
        .then(() => {
          this.qta = 0;
          this.loading_elimina = false;
        });
    },
    select_box() {
      setTimeout(() => {
        this.$refs.qta_box.focus();
      }, 100);
    },
    select_text: function (e) {
      e.target.select();
    },
  },
};
