<template>
  <div>
    <v-list-item @contextmenu="show_del_menu" @click="show_dest = !show_dest">
      <v-list-item-avatar>
        <v-icon color="green accent-3">mdi-whatsapp</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ this.broad.testo }}</v-list-item-title>
        <v-list-item-subtitle>{{ data }}</v-list-item-subtitle>
        <template v-if="show_dest">
          <v-spacer />
          <v-list-item-subtitle
            v-for="elemento in broad.elementi"
            :key="elemento.destinatario"
          >
            <v-icon size="20" :color="colore_icona(elemento.stato)">{{
              stato_icona(elemento.stato)
            }}</v-icon>
            {{ cliente(elemento.destinatario).nome }}
          </v-list-item-subtitle>
        </template>
      </v-list-item-content>
      <v-list-item-action>
        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="elemento in broad.elementi"
              :key="elemento.destinatario"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  cliente(elemento.destinatario).nome
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon size="20" :color="colore_icona(elemento.stato)">{{
                    stato_icona(elemento.stato)
                  }}</v-icon>
                  {{ elemento.stato }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-list-item-action>
      <v-list-item-action-text>
        {{ broad.elementi.length }} Destinatari
      </v-list-item-action-text>
      <v-menu
        v-model="del_menu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item @click="archivia_broad()">
            <v-list-item-icon>
              <v-icon color="purple">mdi-package-down</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Archivia</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    broad: Object,
  },
  data() {
    return {
      del_menu: false,
      y: 0,
      x: 0,
      show_dest: false,
    };
  },
  computed: {
    data() {
      return moment.unix(this.broad.ts_spedizione).format("dddd DD MMM hh:mm");
    },
  },
  methods: {
    show_del_menu(e) {
      e.preventDefault();
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.del_menu = true;
      });
    },
    archivia_broad() {
      this.$store.dispatch("archivia_broadcast", {
        id_broadcast: this.broad._id.$oid,
      });
    },
    stato_icona(text) {
      if (text == "error" || text == "failed") {
        return "mdi-close";
      } else if (text == "sent") {
        return "mdi-check";
      } else if (text == "delivered") {
        return "mdi-check-all";
      } else if (text == "read") {
        return "mdi-check-all";
      } else if (text == "wait") {
        return "mdi-clock";
      }
    },
    colore_icona(text) {
      if (text == "error" || text == "failed") {
        return "red";
      } else if (text == "read") {
        return "blue";
      } else {
        return "grey";
      }
    },
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
  },
};
</script>
