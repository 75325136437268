<template>
  <v-dialog
    width="800"
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"><v-icon>mdi-open-in-new</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Logs Clienti
      </v-card-title>
      <v-card-text>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-select
              dense
              outlined
              label="periodo"
              :items="pariodo_list"
              item-text="label"
              item-value="value"
              v-model="periodo"
              :loading="loading_periodo"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="iva_cliente"
              :items="clienti"
              hide-details
              outlined
              dense
              label="Cerca cliente..."
              item-text="nome"
              item-value="iva"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <template v-for="log in lista_clienti">
          <v-row
            :key="log.id"
            class="my-1"
            no-gutters
            align="center"
            v-if="!$vuetify.breakpoint.mobile"
          >
            <v-col cols="2">
              {{ data_format(log.ts).data }}
            </v-col>
            <v-col cols="2">
              {{ data_format(log.ts).ora }}
            </v-col>
            <v-col cols="1" class="d-flex justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    size="20"
                    :color="device(log.device).color"
                    >{{ device(log.device).icon }}</v-icon
                  >
                </template>
                {{ log.device }}
              </v-tooltip>
            </v-col>
            <v-col cols="4" class="text-truncate">
              {{ cliente(log.iva) ? cliente(log.iva).nome : "" }}
            </v-col>
            <v-col cols="3">
              <v-icon class="mr-4" :color="event(log.event).color">{{
                event(log.event).icon
              }}</v-icon>
              {{ event(log.event).text }}
            </v-col>
          </v-row>
          <v-list-item :key="log.id" v-else>
            <v-list-item-content>
              <v-list-item-title>
                {{ cliente(log.iva) ? cliente(log.iva).nome : "" }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon
                  class="mr-2"
                  size="20"
                  :color="device(log.device).color"
                  >{{ device(log.device).icon }}</v-icon
                >{{ data_format(log.ts).data }} - {{ data_format(log.ts).ora }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="">
              <v-icon class="mr-4" :color="event(log.event).color">{{
                event(log.event).icon
              }}</v-icon>
            </v-list-item-action>
            <v-list-item-action-text>
              {{ event(log.event).text }}
            </v-list-item-action-text>
          </v-list-item>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      dialog: false,
      loading_periodo: false,
      periodo: 1,
      iva_cliente: null,
      pariodo_list: [
        { label: "Ultimo Giorno", value: 1 },
        { label: "Ultima Settimana", value: 7 },
        { label: "Ultimo Mese", value: 31 },
        { label: "Ultimo Anno", value: 365 },
      ],
    };
  },
  watch: {
    periodo: function () {
      this.loading_periodo = true;
      this.$store
        .dispatch("fetch_accessi_clienti", {
          ts_inizio: moment().subtract(this.periodo, "days").format("X"),
          ts_fine: moment().format("X"),
        })
        .finally(() => {
          this.loading_periodo = false;
        });
    },
    dialog: function () {
      if (this.dialog) {
        this.loading_periodo = true;
        this.$store
          .dispatch("fetch_accessi_clienti", {
            ts_inizio: moment().subtract(this.periodo, "days").format("X"),
            ts_fine: moment().format("X"),
          })
          .finally(() => {
            this.loading_periodo = false;
          });
      }
    },
  },
  computed: {
    lista_clienti() {
      let lista = this.$store.getters.get_accessi_cliente;
      if (this.iva_cliente) {
        return lista.filter((cliente) => cliente.iva == this.iva_cliente);
      } else {
        return lista;
      }
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
  },
  methods: {
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
    data_format(ts) {
      let data = moment.unix(ts);
      return {
        data: data.format("DD/MMMM"),
        ora: data.format("HH:mm"),
      };
    },
    event(ev) {
      switch (ev) {
        case 1:
          return {
            color: "green",
            icon: "mdi-login",
            text: "Login",
          };
        case 2:
          return {
            color: "blue",
            icon: "mdi-package-down",
            text: "Ordine",
          };
        case 3:
          return {
            color: "orange",
            icon: "mdi-key",
            text: "Nuova Password",
          };
        case 4:
          return {
            color: "purple",
            icon: "mdi-lock",
            text: "Reset Password",
          };
        case 5:
          return {
            color: "indigo",
            icon: "mdi-package-down",
            text: "Ordine Admin",
          };
      }
    },
    device(dev) {
      if (!dev) {
        return "";
      }
      if (dev.includes("Windows")) {
        return { icon: "mdi-microsoft-windows", color: "blue" };
      } else if (dev.includes("Android")) {
        return { icon: "mdi-android", color: "green" };
      } else if (dev.includes("iOS")) {
        return { icon: "mdi-apple", color: "grey" };
      } else {
        return { icon: "mdi-help", color: "black" };
      }
    },
  },
};
</script>
