<template>
  <div>
    <v-list-item class="ml-n4" @click="show_color = !show_color">
      <span style="font-size: 22px" class="mr-4">{{ tot_pezzi }}</span>
      <v-list-item-avatar class="mr-2">
        <v-img :src="avatar_src" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ nome }} Ø {{ articolo.diametro }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ tipo_vaso }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          € {{ item.prezzo.toFixed(2) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action-text style="font-size: 18px">
        € {{ prezzo_tot }}
      </v-list-item-action-text>
    </v-list-item>
    <v-list v-if="show_color">
      <v-list-item v-for="colore in item.colori" :key="colore.id" class="ml-n2 my-n2">
        {{colore.numero}}
        <v-list-item-avatar size="30" class="ml-2">
            <v-img :src="colore_src(colore.id)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                {{colore.nome}}
            </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider/>
  </div>
</template>

<script>
import carrello_element_mixin from "../../mixin/carrello_element_mixin";
export default {
  mixins: [carrello_element_mixin],
};
</script>