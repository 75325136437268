export default {
  props: {
    ordine: Object,
    icon: Boolean,
  },
  data() {
    return {
      menu: false,
      codice_ritiro: this.ordine.ritiro_cod,
      loading: false,
      destinazione_sel: this.ordine.destinazione.id,
    };
  },
  computed: {
    ritiro() {
      switch (this.ordine.ritiro_cod) {
        case 1:
          return { text: "Consegna", color: "blue", cod: 1 };
        case 0:
          return { text: "Ritiro", color: "green", cod: 0 };
      }
      return { text: "err", color: "red" };
    },
    destinazioni() {
      return this.$store.getters.get_cliente_by_iva(this.ordine.iva)
        .destinazioni;
    },
  },
  watch: {
    codice_ritiro: function () {
      this.confirm_type_change();
    },
    destinazione_sel: function () {
      this.loading = true;
      this.$store
        .dispatch("cambia_destinazione_ordine", {
          id_ordine: this.ordine._id.$oid,
          id_destinazione: this.destinazione_sel,
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  methods: {
    confirm_type_change() {
      this.loading = true;
      this.$store
        .dispatch("imposta_tipo_consegna", {
          id: this.ordine._id.$oid,
          tipo: this.codice_ritiro,
        })
        .then(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
