<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon
          class=""
          :class="$vuetify.breakpoint.mobile ? 'ml-n4' : 'mt-6'"
        >
          <v-badge
            overlap
            color="green"
            :value="non_letti"
            :content="non_letti"
          >
            <v-avatar color="green accent-3" size="40">
              <v-icon color="white"> mdi-whatsapp </v-icon>
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ cliente.nome }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-icon size="15">{{ last_msg.icon }}</v-icon> {{ last_msg.text }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-row>
            {{ data }}
          </v-row>
          <v-row>
            <v-icon
              v-if="messageses[0].isMine"
              size="15"
              :color="last_msg.status_color"
              >{{ last_msg.status_icon }}</v-icon
            >
          </v-row>
        </v-list-item-action-text>
      </v-list-item>
    </template>

    <v-card :img="require('@/assets/chat_bg_2.jpg')">
      <v-card-title class="white--text font-weight-light blue lighten-1">
        <!-- <v-btn icon ><v-icon>mdi-back</v-icon></v-btn> -->
        <v-list-item class="">
          <v-list-item-avatar tile size="40">
            <v-avatar color="white" size="40">
              <v-icon color="grey">mdi-account</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ cliente.nome }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
                +{{chat._id}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action
            ><v-btn color="white" icon @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-list-item-action
          >
        </v-list-item>
      </v-card-title>
      <v-card-text class="chat_wrapper mt-2" ref="chat_body">
        <template v-if="messageses != []">
          <template v-for="day in msg_array">
            <v-row class="my-2" :key="day.data" justify="center">
              <v-card max-width="300" elevation="1" class="blue lighten-5 pa-1">
                <span style="font-size: 12px" class="">
                  {{ day.data }}
                </span>
              </v-card>
            </v-row>
            <WaMessage
              v-for="msg in day.msg"
              :key="msg.msg_id"
              :wamsg="msg"
              :user_id="chat._id"
              :chat_visibile="dialog"
            ></WaMessage>
          </template>

          <!-- {{ msg_array }} -->
          <!-- <template v-for="giorno in msg_array">
            <v-row class="my-2" :key="giorno.data" justify="center">
              <v-card max-width="300" elevation="1" class="blue lighten-5 pa-1">
                <span style="font-size: 12px" class="">
                  {{ giorno.data }}
                </span>
              </v-card>
            </v-row>
            <Messaggio
              v-for="messaggio in giorno.msg"
              :key="messaggio.id"
              :messaggio="messaggio"
              :id_conversazione="chat._id"
              :miaChat="in_mio_carico"
              :chat_visibile="dialog"
            ></Messaggio>
          </template> -->
        </template>
      </v-card-text>

      <v-card-actions class="white d-flex align-center">
        <v-textarea
          rows="1"
          auto-grow
          v-model="text_to_send"
          class="py-2"
          rounded
          placeholder="Scrivi un messaggio"
          filled
          background-color=""
          dense
          hide-details
          append-outer-icon="mdi-send"
          @click:prepend-inner="show_emo = true"
          @click:append-outer="send_msg()"
          :loading="loading_send"
          :readonly="loading_send"
        >
          <!-- <template v-slot:append>
            <ImagePicker :chat_id="chat._id" :destinatario="chat.autore.iva" />
            <FilePicker :chat_id="chat._id" :destinatario="chat.autore.iva" />
          </template>
          <template v-slot:prepend-inner>
            <EmojiPicker @emoji="add_to_text" />
          </template> -->
        </v-textarea>
      </v-card-actions>
      <!-- <v-card-actions class="white d-flex align-center">
        <v-spacer />
        <v-dialog v-model="delete_dialog" width="400">
          <template v-slot:activator="{ on }">
            <v-btn outlined color="purple" v-on="on">
              <v-icon>mdi-package-down</v-icon>
              Archivia
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="purple--text"> Archivia Chat </v-card-title>
            <v-card-text>
              La chat verrà archiviata.
              <br />
              Procedere?
            </v-card-text>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn outlined color="purple" @click="delete_chat()">
                <v-icon class="mr-2">mdi-package-down</v-icon>
                Archivia
              </v-btn>
              <v-btn outlined color="grey" @click="close()"> Annulla </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import WaMessage from "./wa_message.vue";
export default {
  components: {
    WaMessage,
  },
  props: {
    chat: Object,
  },
  data() {
    return {
      dialog: false,
      delete_dialog: false,
      text_to_send: "",
      loading_send: false,
    };
  },
  computed: {
    cliente() {
      let cliente = this.$store.getters.get_cliente_by_phone(this.chat._id);
      if (cliente) {
        return cliente;
      } else {
        return {
          nome: "~ " + this.chat.messageses[0].contact.profile.name,
          numero: "+" + this.chat._id,
        };
      }
    },
    messageses() {
      const msgclone = this.chat.messageses.slice();
      return msgclone.sort((a, b) => b.timestamp - a.timestamp);
    },
    msg_array() {
      let res = _.groupBy(this.chat.messageses, (msg) => {
        return moment.unix(msg.timestamp).startOf("day");
      });
      let arrbyday = Object.keys(res).map((date) => {
        return {
          data: moment(date).calendar({
            sameDay: "[Oggi]",
            nextDay: "[Domani]",
            nextWeek: "dddd",
            lastDay: "[Ieri]",
            lastWeek: "[Lo Scorso] dddd",
            sameElse: "DD/MM/YYYY",
          }),
          msg: res[date],
        };
      });
      return arrbyday;
    },
    non_letti() {
      return this.messageses.filter((msg) => msg.nuovo).length;
    },
    last_msg() {
      let text = "";
      let icon = "";
      let color = "";
      let status_icon = "";
      let status_color = "";
      switch (this.messageses[0].msg.type) {
        case "text":
          text = this.messageses[0].msg.text.body;
          break;
        case "image":
          text = "Immagine";
          icon = "mdi-photo";
          break;
        case "audio":
          text = "Audio";
          icon = "mdi-microphone";
          break;
      }
      switch (this.messageses[0].stato) {
        case "wait":
          status_icon = "mdi-clock";
          status_color = "grey";
          break;
        case "sent":
          status_icon = "mdi-check";
          status_color = "grey";
          break;
        case "delivered":
          status_icon = "mdi-check-all";
          status_color = "grey";
          break;
        case "read":
          status_icon = "mdi-check-all";
          status_color = "blue";
          break;
      }
      return {
        text: text,
        icon: icon,
        color: color,
        status_icon: status_icon,
        status_color: status_color,
      };
    },
    data() {
      let mom = moment.unix(this.messageses[0].timestamp);
      if (mom.isSame(moment(), "day")) {
        return mom.format("HH:mm");
      } else {
        return mom.format("DD/MM/YY");
      }
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        setTimeout(() => {
          this.scroll_down();
        }, 500);
      }
    },
    chat: {
      deep: true,
      handler() {
        if (this.dialog) {
          setTimeout(() => {
            this.scroll_down();
          }, 100);
        }
      },
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    send_msg() {
      this.loading_send = true;
      this.$store
        .dispatch("invia_msg_wa", {
          id_destinatario: this.chat._id,
          body: this.text_to_send,
        })
        .then(() => {
          this.text_to_send = "";
          this.loading_send = false;
        });
    },
    scroll_down() {
      this.$refs.chat_body.scroll({ top: 9999 });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.chat_wrapper {
  height: 500px;
  overflow-y: scroll;
}
</style>
