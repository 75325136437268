<template>
  <div>
    <v-row
      class="pa-1 font-weight-medium"
      justify="start"
      align="center"
      no-gutters
    >
      <v-col cols="1">
        <v-btn icon color="primary" @click="show_color = !show_color">
          <v-icon>{{
            show_color ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <b>{{ tot_pezzi }}</b>
      </v-col>
      <v-col cols="3" class="text-truncate" @click="show_color = !show_color">
        <v-list-item class="ml-n4">
          <v-list-item-avatar size="40">
            <v-avatar size="40" class="mr-2">
              <v-img :src="avatar_src"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-body-1 font-weight-light"
                >
                {{ nome }} Ø {{ articolo.diametro }}
                </span
              >
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="font-weight-light" style="font-size: 14px">{{
                tipo_vaso
              }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="3" class="pl-2" v-if="$vuetify.breakpoint.width > 600">
        {{ item.commento }}
      </v-col>
      <v-col cols="2"> € {{ item.prezzo.toFixed(2) }} </v-col>
      <v-col cols="1"> € {{ prezzo_tot }} </v-col>
    </v-row>
    <template v-if="show_color">
      <v-row
        v-for="colore in item.colori"
        :key="colore.id"
        align="center"
        no-gutters
        class="pa-1 mb-1"
      >
        <v-col cols="1" offset="1" class="">
          {{ colore.numero }}
        </v-col>
        <v-col cols="3" class="ml-1">
          <v-avatar size="30" class="mr-2">
            <v-img :src="colore_src(colore.id)" />
          </v-avatar>
          {{ colore.nome }}
        </v-col>
        <v-col cols="2"> </v-col>
      </v-row>
    </template>
    <v-divider />
  </div>
</template>

<script>
export default {
  components: {
  },

  props: {
    item: Object,
  },
  data() {
    return {
      dialog_elimina: false,
      show_color: false,
      show_prod: false,
    };
  },
  computed: {
    articolo(){
      return this.$store.getters.get_prodotto_raw_by_id(this.item.art)
    },
    nome() {
      if (this.articolo.nome.includes("Vaso")) {
        return this.articolo.nome.split("Vaso")[0];
      } else {
        return this.articolo.nome.split("Basket")[0];
      }
    },
    tipo_vaso() {
      if (this.articolo.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.articolo.unita;
      }
    },
    tot_pezzi() {
      return this.item.colori
        .map((col) => parseInt(col.numero))
        .reduce((tot, num) => tot + num);
    },
    prezzo_tot() {
      let tot = this.tot_pezzi * this.item.prezzo;
      return tot.toFixed(2);
    },
    avatar_src() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.articolo.id +
        "?t=" +
        this.articolo.avatar_md5
      );
    },
  },
  methods: {
    colore_src(id){
      return(
         process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.articolo.id +
        "/img_colore/" +
        id
      )
    }
  },
};
</script>