<template>
  <v-row class="my-0" align="center" no-gutters>
    <v-col cols="4">
        <v-list-item class="ml-n4">
        <v-list-item-avatar>
          <v-img :src="avatar_src"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ nome[0] }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ nome[1] }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="!carrello_element"> {{ prodotto.prezzo_contato.toFixed(2) }} € </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="2" class="pl-6">
        <span><b>{{prodotto.qta_ordinata}}</b></span>
            <br/>
          <span v-if="!carrello_element" class="grey--text">{{prezzo.ordinato.toFixed(2)}}</span>    
        </v-col>
    <v-col cols="2" class="pl-6">
        <b>{{prodotto.qta}}</b>
        <br/>
        <span v-if="!carrello_element" class="grey--text">{{prezzo.contato.toFixed(2)}}</span>
        </v-col>
    <v-col cols="2" class="pl-6" v-if="!carrello_element">
        <v-icon size="20" class="mr-2" color="amber" v-if="discrepanza != 0">mdi-alert</v-icon>
        <span :class="discrepanza == 0 ? 'green--text' : 'amber--text'">{{discrepanza}}</span>
        <br/>
        <span  class="grey--text">{{prezzo.discrepanza.toFixed(2)}}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    prodotto: Object,
    carrello_element: Boolean
  },
  computed: {
    prodotto_ext() {
      return this.$store.getters.get_prodotto_by_id(this.prodotto.id_prodotto);
    },
    avatar_src() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.prodotto.id_prodotto;
    },
    nome(){
        return this.prodotto_ext.nome.split("in ")
    },
    discrepanza(){
       return this.prodotto.qta - this.prodotto.qta_ordinata
    },
    prezzo(){
        return{
            ordinato: this.prodotto.prezzo_contato * this.prodotto.qta_ordinata,
            contato: this.prodotto.prezzo_contato * this.prodotto.qta,
            discrepanza: (this.prodotto.prezzo_contato * this.prodotto.qta_ordinata) - (this.prodotto.prezzo_contato * this.prodotto.qta)
        }
    }
  },
};
</script>