export default {
  focus_watcher() {
    let prevStateIsFocused = null; // used to ensure that there is only one execution of a function for either the document focused or not

    setInterval(() => {
      if (document.hasFocus()) {
        if (prevStateIsFocused !== true) {
          window.dispatchEvent(new CustomEvent("win_focus_get"))
        }
        prevStateIsFocused = true;
      } else {
        if (prevStateIsFocused !== false) {
          window.dispatchEvent(new CustomEvent("win_focus_lost"))
        }
        prevStateIsFocused = false;
      }
    }, 500);
  },
};
