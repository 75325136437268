
export default {
  data() {
    return {
      iva_cliente: "",
      menu_filtro: false,
      valore_filtro: -1,
      filtro_pianificato: false,
    };
  },
  computed: {
    ordini_attivi() {
      return this.$store.getters.get_ordini_attivi
        .filter(
          (ordine) =>
            ordine.stato == this.valore_filtro || this.valore_filtro == -1
        )
        .filter(
          (ordine) => !this.filtro_pianificato || (!ordine.consegna_programmata && ordine.ritiro_cod == 1)
        );
    },
    ordini_evasi() {
      return this.$store.getters.get_ordini_evasi;
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
  },
  methods: {
    ricerca() {
      if (!this.iva_cliente) {
        this.$store.dispatch("fetch_ordini_evasi");
      } else {
        this.$store.dispatch("ricerca_ordine_server", {
          iva: this.iva_cliente,
        });
      }
    },
  },
};
