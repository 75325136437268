import { mapGetters } from "vuex";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      metodo_trasporto: "1",
      specifica_data: false,
      commento: "",
      dialog: false,
      cliente_sel: null,
      prodotto_sel: null,
      show_dialog: false,
      loading_cart: false,
      destinazione_sel: "",
      tipo_cc_sel: 0,
      tipo_carrello: [
        { value: 0, text: "Non specificato" },
        { value: 1, text: "Rosso Nostro" },
        { value: 2, text: "Lucchetto Nero" },
        { value: 3, text: "Tag Verde" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      //trasporto: 'get_trasporto',
      clienti: "get_clienti",
      prodotti: "get_lista_prodotti_raw",
      carrello: "get_carrello",
    }),
    trasporto() {
      return this.$store.getters.get_trasporto;
    },
    prodotto() {
      return this.prodotti.find((prod) => prod.id == this.prodotto_sel);
    },
    tot_cc() {
      if (!this.carrello.prodotti.length) {
        return 0;
      }
      return parseFloat(
        this.carrello.prodotti
          .map((item) => {
            let prodotto = this.$store.getters.get_prodotto_raw_by_id(item.art);
            if (!prodotto) {
              return 0;
            }
            let qta_cc = prodotto.qta_cc;
            if (!qta_cc) {
              return 0;
            } else {
              return (
                item.colori
                  .map((col) => parseInt(col.numero))
                  .reduce((tot, num) => tot + num) / qta_cc
              );
            }
          })
          .reduce((tot, num) => tot + num)
          .toFixed(1)
      );
    },
    tot_merce() {
      if (!this.carrello.prodotti.length) {
        return 0;
      }
      return parseFloat(
        this.carrello.prodotti
          .map((item) =>
            item.colori
              .map((col) => {
                return parseInt(col.numero) * item.prezzo;
              })
              .reduce((tot, num) => tot + num)
          )
          .reduce((tot, num) => tot + num)
      );
    },
    cart_tot() {
      if (this.carrello.prodotti.length > 0) {
        return (this.tot_merce + this.tot_trasp).toFixed(2);
      } else {
        return 0;
      }
    },
    tot_trasp() {
      if (
        this.metodo_trasporto == 1 &&
        this.tot_merce < this.trasporto.soglia
      ) {
        switch (
          this.$store.getters.get_cliente_by_iva(this.cliente_sel).listino
        ) {
          case "1":
            return this.trasporto.prezzo_listino_1;
          case "2":
            return this.trasporto.prezzo_listino_2;
          case "3":
            return this.trasporto.prezzo_listino_3;
        }
        return;
      } else {
        return 0;
      }
    },
    trasp_label() {
      if (this.trasporto.spesa > 0) {
        return (
          "Trasporto gratuito per ordini superiori ai " +
          this.trasporto.soglia +
          " €"
        );
      } else {
        return "Trasporto gratuito per ogni cifra";
      }
    },
    destinazioni_cliente() {
      return this.$store.getters.get_cliente_by_iva(this.cliente_sel)
        .destinazioni;
    },
  },
  watch: {
    cliente_sel: function () {
      if (!this.cliente_sel) {
        return;
      }
      let cliente = this.$store.getters.get_cliente_by_iva(this.cliente_sel);
      if (cliente.ultima_destinazione.id) {
        this.destinazione_sel = cliente.destinazioni.find(
          (dest) => dest.id == cliente.ultima_destinazione
        ).id;
      } else {
        this.destinazione_sel = cliente.destinazioni[0].id;
      }
      this.tipo_cc_sel = cliente.tipo_cc;
    },
  },
  methods: {
    ottieni_carrello() {
      if (!this.cliente_sel) {
        return;
      }
      this.loading_cart = true;
      this.$store
        .dispatch("fetch_carrello", { iva: this.cliente_sel })
        .finally(() => {
          this.loading_cart = false;
        });
      this.$store.dispatch("fetch_dispo_cliente", this.cliente_sel);
    },
    open_dialog() {
      if (this.prodotto_sel != null) {
        setTimeout(() => {
          this.$refs.dialog_prodotto.open();
        }, 200);
      }
    },
    close_dialog() {
      this.show_dialog = false;
    },
    edit_prod: function (e) {
      this.prodotto_sel = e;
      setTimeout(() => {
        this.$refs.dialog_prodotto.open();
      }, 200);
    },
    avatar_img(id) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
    },
    nome_split(nome) {
      return nome.split(" in ");
    },
  },
};
