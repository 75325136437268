import axios from "axios";

export default {
  state: {
    ordini_attivi: [],
    ordini_evasi: [],
  },
  getters: {
    get_ordine_by_id: (state) => (id) => {
      let ordine = state.ordini_attivi.find((ord) => ord._id.$oid == id);
      if (!ordine) {
        ordine = state.ordini_evasi.find((ord) => ord._id.$oid == id);
      }
      return ordine;
    },
    get_ordini_attivi: (state) => {
      return state.ordini_attivi.sort((a, b) => b.timeStamp - a.timeStamp);
    },
    get_ordini_evasi: (state) => {
      return state.ordini_evasi.sort((a, b) => b.timeStamp - a.timeStamp);
    },
    get_ordini_nuovi: (state) => {
      return state.ordini_attivi.filter((ordine) => ordine.nuovo).length;
    },
    get_numero_ordini_attivi: (state) => {
      return state.ordini_attivi.length;
    },
    get_qta_cc_ordine: (state, getters) => (id) => {
      let ordine = state.ordini_attivi.find((ordine) => ordine._id.$oid == id);
      if (!ordine.articoli.length) {
        return 0;
      }
      return parseFloat(
        ordine.articoli
          .map((item) => {
            let prodotto = getters.get_prodotto_raw_by_id(item.art);
            if (!prodotto) {
              return 0;
            }
            let qta_cc = prodotto.qta_cc;
            if (!qta_cc) {
              return 0;
            } else {
              return (
                item.colori
                  .map((col) => parseInt(col.qta_admin ? col.qta_admin : col.numero))
                  .reduce((tot, num) => tot + num, 0) / qta_cc
              );
            }
          })
          .reduce((tot, num) => tot + num, 0)
          .toFixed(1)
      );
    },
  },
  mutations: {
    set_ordini_attivi(state, ordini) {
      state.ordini_attivi = ordini;
    },
    set_ordini_evasi(state, ordini) {
      state.ordini_evasi = ordini;
    },
    add_ordine(state, ordine) {
      if (!ordine.stato == 2 || !ordine.stato == 3 || !ordine.stato == 4) {
        if (state.ordini_attivi.find((ord) => ord.id == ordine.id)) {
          state.ordini_attivi.push(ordine);
        }
      } else {
        if (state.ordini_evasi.find((ord) => ord.id == ordine.id)) {
          state.ordini_evasi.push(ordine);
        }
      }
    },
    set_ordine_confermato(state, payload) {
      console.log("tappa da committare: ");
      console.log(payload);
      let ordine = state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id
      );
      console.log(ordine);
      if (ordine) {
        ordine.stato = payload.stato;
      }
    },
    set_ordine_evaso(state, payload) {
      let ordine = state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id
      );
      ordine.stato = payload.stato;
      ordine.verde = payload.verde;
      let index = state.ordini_attivi.findIndex(
        (ordine) => ordine._id.$oid == payload.id
      );
      state.ordini_attivi.splice(index, 1);
      state.ordini_evasi.push(ordine);
    },
    set_ordine_eliminato(state, id) {
      let index = state.ordini_attivi.findIndex(
        (ordine) => ordine._id.$oid == id
      );
      if (index != -1) {
        state.ordini_attivi.splice(index, 1);
      } else {
        index = state.ordini_evasi.findIndex((ordine) => ordine._id.$oid == id);
        state.ordini_evasi.splice(index, 1);
      }
    },
    set_ordine_jointed(state, id) {
      state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == id
      ).joined_in = true;
    },
    add_new_order(state, ordine) {
      state.ordini_attivi.unshift(ordine);
    },
    set_visibilità_ordine(state, payload) {
      let ordine = state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id
      );
      if (!ordine) {
        ordine = state.ordini_evasi.find(
          (ordine) => ordine._id.$oid == payload.id
        );
      }
      ordine.visibile = payload.visibile;
    },
    set_ordine_data(state, payload) {
      let ordine = state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id
      );
      ordine.orario = payload.orari.orario;
      ordine.orario_ts = payload.orari.orario_ts;
    },
    set_ordine_consegna(state, payload) {
      // state.ordini_attivi.find(
      //   (ordine) => ordine._id.$oid == payload.id
      // ).ritiro_cod = parseInt(payload.tipo);
      let ordine = state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id
      );
      ordine.ritiro_cod = payload.ritiro_cod;
      ordine.tot_trasp = payload.tot_trasp;
    },
    set_riattiva_ordine(state, payload) {
      let index = state.ordini_evasi.findIndex(
        (ordine) => ordine._id.$oid == payload.id
      );
      let ordine = state.ordini_evasi[index];

      state.ordini_evasi.splice(index, 1);
      ordine.stato = payload.stato;
      state.ordini_attivi.push(ordine);
    },
    set_ordine_modificato(state, ordine) {
      let index = state.ordini_attivi.findIndex(
        (ord) => ord._id.$oid == ordine._id.$oid
      );
      state.ordini_attivi.splice(index, 1, ordine);
    },
    set_ordine_contato(state, id_ordine) {
      let ordine = state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == id_ordine
      );
      ordine.stato = 8;
      ordine.conteggiato = true;
    },
    set_ordine_pagamento(state, payload) {
      state.ordini_evasi.find((ordine) => ordine._id.$oid == payload.id).verde =
        payload.tipo == 0 ? true : false;
    },
    set_commento_edit(state, payload) {
      state.ordini_attivi
        .find((ordine) => ordine._id.$oid == payload.ordine)
        .articoli.find(
          (articolo) => articolo.art == payload.id_prodotto
        ).commento = payload.commento;
    },
    set_commento_finale_edit(state, payload) {
      state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.ordine
      ).commento = payload.commento;
    },
    set_ordine_nuovo_vecchio(state, payload) {
      state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id_ordine
      ).nuovo = payload.stato;
    },
    set_ordine_print(state, payload) {
      state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id_ordine
      ).stampata = payload.stato;
    },
    set_ordine_tipo_cc(state, payload) {
      state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id_ordine
      ).tipo_cc = payload.tipo_cc;
    },
    set_ordine_destinazione(state, payload) {
      state.ordini_attivi.find(
        (ordine) => ordine._id.$oid == payload.id_ordine
      ).destinazione = payload.destinazione;
    },
    update_ordine(state, ordine) {
      let index;
      switch (ordine.stato) {
        case 0:
        case 1:
        case 6:
        case 7:
        case 8:
          index = state.ordini_attivi.findIndex(
            (ord) => ord._id.$oid == ordine._id.$oid
          );
          if (index == -1) {
            state.ordini_attivi.push(ordine);
          } else {
            state.ordini_attivi.splice(index, 1, ordine);
          }
          index = state.ordini_evasi.findIndex(
            (ord) => ord._id.$oid == ordine._id.$oid
          );
          if (index >= 0) {
            state.ordini_evasi.splice(index, 1);
          }
          break;
        case 2:
        case 3:
        case 4:
          index = state.ordini_evasi.findIndex(
            (ord) => ord._id.$oid == ordine._id.$oid
          );
          if (index == -1) {
            state.ordini_evasi.push(ordine);
          } else {
            state.ordini_evasi.splice(index, 1, ordine);
          }
          index = state.ordini_attivi.findIndex(
            (ord) => ord._id.$oid == ordine._id.$oid
          );
          if (index >= 0) {
            state.ordini_attivi.splice(index, 1);
          }
          break;
      }
    },
  },
  actions: {
    fetch_ordini_attivi(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordini/attivi";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_ordini_attivi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_ordini_evasi(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordini/archivio/20";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_ordini_evasi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ricerca_ordine_server(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordini/archivio/cliente/" +
          payload.iva;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_ordini_evasi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_ordine_by_id(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/" + id;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("add_ordine", JSON.parse(res.data));
            resolve(JSON.parse(res.data));
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processa_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordini/" + payload.id;
        let auth = context.getters.get_credential;
        axios
          .post(url, { stato: payload.stato }, { auth: auth })
          .then(() => {
            if (payload.stato == 1) {
              context.commit("set_ordine_confermato", {
                id: payload.id,
                stato: payload.stato,
              });
            } else {
              context.commit("set_ordine_evaso", {
                id: payload.id,
                stato: payload.stato,
              });
            }
            let conteggio = context.getters.get_conteggio_by_ordcod(payload.id);
            if (conteggio) {
              context.dispatch("archivia_conteggio", conteggio.id);
            }

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    archivia_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/archivia";
        let auth = context.getters.get_credential;
        axios
          .put(url, { verde: payload.verde, sid: payload.sid }, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_evaso", {
              id: payload.id_ordine,
              stato: 2,
              verde: payload.verde,
            });
            if (res.data.conteggio) {
              context.commit("set_conteggio_eliminato", res.data.conteggio);
            }
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_ordine(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordini/" + id;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_ordine_eliminato", id);
            context.commit("set_conteggio_eliminato", id);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_NEW_ORDER(context, payload) {
      context.commit("add_new_order", JSON.parse(payload));
    },
    SOCKET_ORDINE_CONTATO(context, id_ordine) {
      context.commit("set_ordine_contato", id_ordine);
    },
    SOCKET_ORDINE_MODIFICATO(context, id_ordine) {
      context
        .dispatch("fetch_ordine_by_id", id_ordine)
        .then((res) => {
          context.commit("update_ordine", res);
          if (res.is_joined) {
            // se il nuovo ordine è unito elimino le fonti
            res.joined_from.forEach((id) => {
              context.commit("set_ordine_eliminato", id);
            });
          }
        })
        .catch(() => {
          context.commit("set_ordine_eliminato", id_ordine);
        });
    },
    unisci_ordini(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordini/join";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("add_new_order", JSON.parse(res.data));
            payload.forEach((element) => {
              context.commit("set_ordine_jointed", element);
              payload.forEach((ordine) =>
                context.commit("set_ordine_eliminato", ordine)
              );
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    visibilità_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordini/" +
          payload.id +
          "/visibilita/" +
          payload.visibile;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_visibilità_ordine", payload);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    imposta_data_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/ordini/data/" + payload.id;
        let auth = context.getters.get_credential;
        axios
          .put(url, { ts: payload.ts }, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_data", {
              orari: res.data,
              id: payload.id,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    imposta_tipo_consegna(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id +
          "/consegna/" +
          payload.tipo;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_consegna", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    riattiva_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/ordini/" + payload.id;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            if (res.data.conteggio) {
              context.commit("set_riattiva_ordine", {
                id: payload.id,
                stato: 8,
              });
              context.commit("add_conteggio", res.data.conteggio);
            } else {
              context.commit("set_riattiva_ordine", {
                id: payload.id,
                stato: 1,
              });
            }
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_colore_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore;
        let auth = context.getters.get_credential;
        axios
          .put(url, { qta: payload.qta }, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_modificato", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_colore_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/prodotto/" +
          payload.id_prodotto +
          "/colore/" +
          payload.id_colore;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_modificato", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_prodotto_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/prodotto/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_modificato", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_soft_prodotto_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/prodotto_delete_soft/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_modificato", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ripristina_soft_prodotto_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/prodotto_restore_soft/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_modificato", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_tipo_pagamento(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id +
          "/pagamento/" +
          payload.tipo;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_ordine_pagamento", {
              tipo: payload.tipo,
              id: payload.id,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    modifica_commento(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/ordine/" +
          payload.ordine +
          "/commento/" +
          payload.id_prodotto;
        let auth = context.getters.get_credential;

        axios
          .post(url, { commento: payload.commento }, { auth: auth })
          .then(() => {
            context.commit("set_commento_edit", payload);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    modifica_commento_finale(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/ordine/" +
          payload.ordine +
          "/commento";
        let auth = context.getters.get_credential;

        axios
          .post(url, { commento: payload.commento }, { auth: auth })
          .then(() => {
            context.commit("set_commento_finale_edit", payload);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    modifica_ordine_nuovo_vecchio(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/nuovo/" +
          payload.stato;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_ordine_nuovo_vecchio", payload);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    modifica_ordine_print(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/stampato/" +
          payload.stato;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_ordine_print", payload);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    modifica_ordine_tipo_cc(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/tipo_cc/" +
          payload.tipo_cc;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_ordine_tipo_cc", payload);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    cambia_destinazione_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/ordine/" +
          payload.id_ordine +
          "/destinazione/" +
          payload.id_destinazione;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_ordine_destinazione", {
              id_ordine: payload.id_ordine,
              destinazione: res.data,
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
