<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-img :src="avatar_img" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ nome[0] }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ nome[1] }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Ordinato: {{ prodotto.qta_ordine ? prodotto.qta_ordine : 0 }} (€
        {{ tot_ordinato.toFixed(2) }})
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Conteggito: {{ prodotto.qta_contata }} (€{{ tot_conteggiato.toFixed(2) }})
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Discrepanza:
        <v-icon :color="discrepanza.color" size="16" class=" mr-1">{{
          discrepanza.icon
        }}</v-icon>
        <span :class="discrepanza.color + '--text'"
          >{{ discrepanza.qta }} (€{{ tot_discrepanza.toFixed(2) }})</span
        >
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text style="font-size: 18px">
      {{prodotto.qta_contata}} / {{ prodotto.qta_ordine ? prodotto.qta_ordine : 0 }}
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
import conteggio_dett_el_mixin from "../../mixin/conteggio_dettaglio_element_mixin";
export default {
  mixins: [conteggio_dett_el_mixin],
};
</script>