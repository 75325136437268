export default {
    components: {
    },
  
    props: {
      item: Object,
    },
    data() {
      return {
        dialog_elimina: false,
        show_color: false,
        show_prod: false,
      };
    },
    computed: {
      articolo(){
        return this.$store.getters.get_prodotto_raw_by_id(this.item.art)
      },
      nome() {
        if (this.articolo.nome.includes("Vaso")) {
          return this.articolo.nome.split("Vaso")[0];
        } else {
          return this.articolo.nome.split("Basket")[0];
        }
      },
      tipo_vaso() {
        if (this.articolo.unita == "1") {
          return "Vaso Singolo";
        } else {
          return "Cassa da " + this.articolo.unita;
        }
      },
      tot_pezzi() {
        return this.item.colori
          .map((col) => parseInt(col.numero))
          .reduce((tot, num) => tot + num);
      },
      prezzo_tot() {
        let tot = this.tot_pezzi * this.item.prezzo;
        return tot.toFixed(2);
      },
      avatar_src() {
        return (
          process.env.VUE_APP_API_ROOT +
          "/avatar_img/" +
          this.articolo.id +
          "?t=" +
          this.articolo.avatar_md5
        );
      },
    },
    methods: {
      colore_src(id){
        return(
           process.env.VUE_APP_API_ROOT +
          "/prodotto/" +
          this.articolo.id +
          "/img_colore/" +
          id
        )
      }
    },
  };