<template>
  <div>
    <v-divider />
    <v-row align="center" class="green--text font-italic my-1 font-weight-light"  @click="show_inner = !show_inner" style="cursor: pointer">
      <v-col cols="1">
        <v-btn icon >
          <v-icon>{{
            show_inner ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11">
        {{ sub_categ.nome }}
      </v-col>
    </v-row>
    <template v-if="show_inner">
      <template v-for="prodotto in sub_categ.prodotti">
        <v-lazy
          :key="prodotto.id"
          :options="{
            threshold: 0.5,
          }"
          min-height="40"
          transition="fade-transition"
        >
          <ProdottoListino
            :item="prodotto"
          />
        </v-lazy>
      </template>
    </template>
  </div>
</template>

<script>
import ProdottoListino from "./listino_prodotto_element_desktop";
export default {
  components: {
    ProdottoListino,
  },
  props: {
    sub_categ: Object,
    show: Boolean,
  },
  data() {
    return {
      show_inner: true,
    };
  },
  watch: {
    show: function () {
      this.show_inner = this.show;
    },
  },
};
</script>
