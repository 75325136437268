<template>
  <v-dialog v-model="show" width="80%" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        color="blue"
        :small="small"
        v-on="on"
        v-if="!noIcon"
      >
        <v-icon> mdi-open-in-new </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="mb-1 primary--text">
        Ordine n. {{ ordine.numero }}
        <v-spacer />
        <v-btn icon color="grey" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="ordine.joined_in" class="amber--text">
        <v-icon color="amber" size="15" class="mr-1">mdi-merge</v-icon> Unito
      </v-card-subtitle>
      <v-card-subtitle>
        {{ ordine.cliente }}
        <br />
        del {{ ordine.data }}
      </v-card-subtitle>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-btn
              icon
              class="ml-1"
              color="primary"
              @click="show_all = !show_all"
            >
              <v-icon>{{
                show_all ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pa-1" no-gutters>
          <v-col cols="1"> Numero </v-col>
          <v-col cols="4"> Denominazione </v-col>
          <v-col cols="2"> prezzo unitario </v-col>
          <v-col cols="2"> prezzo totale </v-col>
          <v-col cols="3"> Note </v-col>
        </v-row>
        <ElementDect
          v-for="art in ordine.articoli"
          :key="art.cod"
          :item="art"
          :ordine="ordine"
          :show_all="show_all"
        />
        <v-row no-gutters class="px-3 pt-3" align="center">
          <v-col cols="1" offset="4"> </v-col>
          <v-col cols="2"> Totale Ordine </v-col>
          <v-col cols="2" md="3"> € {{ order_tot.toFixed(2) }} </v-col>
        </v-row>
        <v-row no-gutters class="px-3" align="center">
          <v-col cols="1" offset="4"> </v-col>
          <v-col cols="2"> Trasporto </v-col>
          <v-col cols="2" md="3"> € {{ ordine.tot_trasp.toFixed(2) }} </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pa-3">
          <v-col cols="3" offset="4" md="2" offset-md="5">
            <b>Totale</b>
          </v-col>
          <v-col>
            <b>€ {{ (order_tot + ordine.tot_trasp).toFixed(2) }}</b>
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-row>
          <v-col cols="3">
            <v-row no-gutters>
              <v-col cols="12">
                <span
                  :class="
                    ordine.ritiro_cod == 1
                      ? 'blue--text font-weight-bold'
                      : 'green--text font-weight-bold'
                  "
                >
                  {{ ordine.ritiro_cod == 1 ? "Consegna" : "Ritiro" }}
                </span>
                <CambiaConsegna :ordine="ordine" icon />
              </v-col>
              <v-col cols="12" v-if="ordine.ritiro_cod == 1">
                <template v-if="ordine.destinazione">
                  {{ ordine.destinazione.indirizzo }}
                </template>
              </v-col>
              <v-col cols="12" v-else> In azienda </v-col>
              <v-col cols="12" v-if="ordine.orario">
                <CambiaData :ordine="ordine" span />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row no-gutters>
              <v-col cols="12">
                <span class="purple--text font-weight-bold"> Carrelli </span>
                <v-menu
                  v-model="menu_modifica_tipo_cc"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-text="'mdi-pencil'"
                      size="20"
                      class="ml-2"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-select
                      class="pa-2"
                      prepend-inner-icon="mdi-cart"
                      outlined
                      label="Tipo di carrello"
                      v-model="tipo_carrello_new"
                      hide-details
                      :items="tipo_carrello"
                      item-text="text"
                      item-value="value"
                    >
                    </v-select>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12">
                {{ tot_cc }} -
                <span :class="tipo_cc.color + '--text'">{{
                  tipo_cc.text
                }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row no-gutters class="">
              <v-col cols="12">
                <b>Note Finali</b>
                <v-menu
                  v-model="menu_edit_note"
                  :close-on-content-click="false"
                  min-width="400"
                  v-if="!ordine.joined_in && (ordine.stato == 1 || ordine.stato == 0)"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-text="'mdi-pencil'"
                      size="20"
                      class="ml-2"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-text-field
                      v-model="note_edit"
                      hide-details
                      outlined
                      class="pa-2"
                    >
                      <template v-slot:append>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-progress-circular
                            indeterminate
                            width="1"
                            size="18"
                            color="grey"
                            class="mt-1"
                            v-if="loading_cmt"
                          ></v-progress-circular>
                          <v-icon color="success" v-if="ok_cmt"
                            >mdi-check</v-icon
                          >
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-text-field>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12">
                {{ ordine.commento }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <MenuAggiungi
          :ordine="ordine"
          v-if="(ordine.stato == 0 || ordine.stato == 1) && !ordine.joined_in"
        />
        <v-btn color="primary" outlined @click="processa_ordine(1)" :loading="loading_conferma" v-if="ordine.stato == 0">
          <v-icon class="mr-2">mdi-check</v-icon>
          Conferma Ordine
        </v-btn>
        <v-btn color="primary" outlined @click="pdf(true)" v-if="ordine.stato != 0">
          <v-icon class="mr-2">mdi-printer</v-icon>
          Stampa
        </v-btn>
        <v-btn color="purple" outlined @click="pdf(false)" v-if="ordine.stato != 0">
          <v-icon class="mr-2">mdi-printer</v-icon>
          Stampa Senza Prezzi
        </v-btn>
        <v-btn @click="show = false" text color="grey"> Chiudi </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ElementDect from "./ordine_dettaglio_element.vue";
import ordine_dettaglio_dialog_mixin from "../../mixin/ordine_dettaglio_dialog_mixin";
import MenuAggiungi from "./ordine_aggiungi_prodotto.vue";
import CambiaConsegna from "../ordine_attivo_utilità/cambia_tipo_consegna.vue";
import CambiaData from "../ordine_attivo_utilità/cambia_data_ordine.vue";
export default {
  components: {
    ElementDect,
    MenuAggiungi,
    CambiaConsegna,
    CambiaData,
  },
  props: {
    tot_cc: String,
  },
  mixins: [ordine_dettaglio_dialog_mixin],
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
</style>