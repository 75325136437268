import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/users",
    name: "Clienti",
    component: () =>
      import(/* webpackChunkName: "clienti" */ "../views/Clienti.vue"),
  },
  {
    path: "/prodotti",
    name: "Prodotti",
    component: () =>
      import(/* webpackChunkName: "prodotti" */ "../views/Prodotti.vue"),
  },
  {
    path: "/ordini",
    name: "Ordini",
    component: () =>
      import(/* webpackChunkName: "ordini" */ "../views/Ordini.vue"),
  },
  {
    path: "/consegne",
    name: "Consegne",
    component: () =>
      import(/* webpackChunkName: "consegne" */ "../views/Consegne.vue"),
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import(/* webpackChunkName: "chat" */ "../views/Chat.vue"),
  },
  {
    path: "/wachat",
    name: "WaChat",
    component: () => import(/* webpackChunkName: "chat" */ "../views/Whatsapp.vue"),
  },
  {
    path: "/conteggi",
    name: "Conteggi",
    component: () =>
      import(/* webpackChunkName: "conteggi" */ "../views/Conteggi.vue"),
  },
  {
    path: "/lotti",
    name: "Lotti",
    component: () =>
      import(/* webpackChunkName: "lotti" */ "../views/Lotti.vue"),
  },
  {
    path: "/fornitori",
    name: "Fornitori",
    component: () =>
      import(/* webpackChunkName: "fornitori" */ "../views/Fornitori.vue"),
  },
  {
    path: "/statistiche",
    name: "Statistiche",
    component: () =>
      import(/* webpackChunkName: "statistiche" */ "../views/Statistiche.vue"),
  },
  {
    path: "/setting",
    name: "Setting",
    component: () =>
      import(/* webpackChunkName: "setting" */ "../views/Setting.vue"),
  },
  {
    path: "/automezzi",
    name: "Automezzi",
    component: () =>
      import(/* webpackChunkName: "automezzi" */ "../views/Automezzi.vue"),
  },
  {
    path: "/crisantemi",
    name: "Crisantemi",
    component: () =>
      import(/* webpackChunkName: "crisantemi" */ "../views/Crisantemi.vue"),
  },
  {
    path: "/tanks",
    name: "Cisterne",
    component: () =>
      import(/* webpackChunkName: "cisterne" */ "../views/Tanks.vue"),
  },
  {
    path: "/calendario_arrivi",
    name: "Calendario Arrivi",
    component: () =>
      import(/* webpackChunkName: "calendario" */ "../views/CalendarioArrivi.vue"),
  },
  {
    path: "/quaderno_campagna",
    name: "Quaderno di campagna",
    component: () =>
      import(/* webpackChunkName: "qdc" */ "../views/QuadernoCampagna.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
