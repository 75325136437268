<template>
  <v-dialog v-model="dialog" width="1800">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="ml-8" color="amber" outlined><v-icon class="mr-2">mdi-map</v-icon>Pianifica Consegna</v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title class="grey--text text-subtitle-1">
        Seleziona Percorso
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col :cols="map_with">
            <GmapMap
              :center="center"
              :zoom="10"
              :style="'width: 100%; height:' + (window_height - 200) + 'px'"
            >
              <gmap-custom-marker :marker="center">
                <v-menu min-width="300">
                  <template v-slot:activator="{ on }">
                    <v-btn fab small v-on="on"
                      ><v-icon color="black">mdi-flag-checkered</v-icon></v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title class="grey--text text-subtitle-1"
                      >G.M. Flor</v-card-title
                    >
                    <v-card-subtitle>
                      Via Villafalletto 59, Fossano
                    </v-card-subtitle>
                    <v-card-text>
                      <v-btn
                        outlined
                        color="purple"
                        v-if="tappe.length"
                        @click="termina_giro()"
                        >Concludi</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </v-menu>
              </gmap-custom-marker>
              <gmap-custom-marker
                v-for="(ordine, index) in ordini_localizzati"
                :key="ordine._id.$oid"
                :marker="ordine.coord"
              >
                <v-menu
                  v-model="active_menu_index[index]"
                  :key="index"
                  :close-on-content-click="false"
                  min-width="300"
                >
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          fab
                          small
                          v-on="{ ...menu, ...tooltip }"
                          :color="
                            trova_valori_viaggio(ordine._id.$oid)
                              ? array_colori[
                                  trova_valori_viaggio(ordine._id.$oid).index
                                ].name
                              : 'grey'
                          "
                          ><v-icon color="white">mdi-google-maps</v-icon></v-btn
                        >
                      </template>
                      {{ ordine.cliente }}
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-title class="grey--text text-subtitle-1">
                      <v-icon
                        class="mr-1 ml-n2"
                        :color="
                          trova_valori_viaggio(ordine._id.$oid)
                            ? array_colori[
                                trova_valori_viaggio(ordine._id.$oid).index
                              ].name
                            : 'grey'
                        "
                        >mdi-map-marker</v-icon
                      >
                      {{ ordine.cliente }}
                      <v-spacer />
                      <v-btn small icon @click="active_menu_index = []">
                        <v-icon color="grey">mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-subtitle>
                      <v-row>
                        <v-col cols="6">
                          Ordine # {{ ordine.numero }}
                          <br />
                          Tot cc:
                          <b>
                            {{
                              $store.getters.get_qta_cc_ordine(ordine._id.$oid)
                            }}
                          </b>
                        </v-col>
                        <v-col cols="6" class="d-flex align-center">
                          <template
                            v-if="!trova_valori_viaggio(ordine._id.$oid)"
                          >
                            <v-btn
                              outlined
                              color="green"
                              @click="aggiungi_tappa(ordine._id.$oid)"
                            >
                              Aggiungi
                            </v-btn>
                          </template>
                          <template v-else>
                            <v-btn
                              outlined
                              color="red"
                              @click="
                                rimuovi_tappa_by_order_id(ordine._id.$oid)
                              "
                            >
                              Rimuovi
                            </v-btn>
                          </template>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>

                    <v-card-text>
                      <v-row v-if="trova_valori_viaggio(ordine._id.$oid)">
                        <v-col cols="12">
                          Distanza:
                          {{
                            trova_valori_viaggio(ordine._id.$oid).value.distanza
                              .text
                          }}
                          <br />
                          Durata:
                          {{
                            trova_valori_viaggio(ordine._id.$oid).value.durata
                              .text
                          }}
                          <br />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </gmap-custom-marker>
              <template v-if="tappe.length">
                <gmap-polyline
                  v-for="(tappa, index) in tappe"
                  :key="index"
                  :path="tappa.polyline"
                  v-bind:options="{
                    strokeColor: array_colori[index].hex,
                    strokeWeight: 4,
                  }"
                >
                </gmap-polyline>
              </template>
            </GmapMap>
          </v-col>

          <v-col>
            <v-card flat :height="window_height - 200" color="" class="overflow-auto">
              <v-btn
                fab
                absolute
                x-small
                depressed
                color="white"
                @click="swipe()"
                ><v-icon>mdi-arrow-split-vertical</v-icon></v-btn
              >
              <v-card-text :class="'scoll'">
                <v-dialog width="1200" v-model="dialog_aggiungi" scrollable>
                  <v-card>
                    <v-card-title>Aggiungi ... </v-card-title>
                    <v-card-text>
                      <v-card
                        class="my-4"
                        v-for="element in ordini_localizzati_destinati"
                        :key="element.zona"
                      >
                        <v-card-title class="indigo--text text-subtitle-1">
                          <v-icon color="indigo" class="mr-2">mdi-city</v-icon
                          >{{ element.zona }}
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="4"
                              v-for="ordine in element.ordini"
                              :key="ordine._id.$oid"
                            >
                              <v-card
                                elevation="0"
                                :color="
                                  trova_valori_viaggio(ordine._id.$oid)
                                    ? array_colori[
                                        trova_valori_viaggio(ordine._id.$oid)
                                          .index
                                      ].name + ' lighten-5'
                                    : 'white'
                                "
                              >
                                <v-card-title
                                  class="grey--text text-subtitle-1"
                                >
                                  <v-icon
                                    class="mr-1 ml-n2"
                                    :color="
                                      trova_valori_viaggio(ordine._id.$oid)
                                        ? array_colori[
                                            trova_valori_viaggio(
                                              ordine._id.$oid
                                            ).index
                                          ].name
                                        : 'grey'
                                    "
                                    >mdi-map-marker</v-icon
                                  >
                                  <span
                                    class="d-inline-block text-truncate"
                                    style="max-width: 250px"
                                  >
                                    {{ ordine.cliente }}
                                  </span>

                                  <v-spacer></v-spacer>
                                  <template
                                    v-if="
                                      !trova_valori_viaggio(ordine._id.$oid)
                                    "
                                  >
                                    <v-btn
                                      icon
                                      color="green"
                                      fab
                                      depressed
                                      small
                                      @click="aggiungi_tappa(ordine._id.$oid)"
                                      ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                  </template>
                                  <template v-else>
                                    <v-btn
                                      icon
                                      color="red"
                                      @click="
                                        rimuovi_tappa_by_order_id(
                                          ordine._id.$oid
                                        )
                                      "
                                      ><v-icon>mdi-minus</v-icon></v-btn
                                    >
                                  </template>
                                </v-card-title>

                                <v-card-subtitle
                                  >Ordine # {{ ordine.numero }}
                                  <br />
                                  Tot cc:
                                  <b>
                                    {{
                                      $store.getters.get_qta_cc_ordine(
                                        ordine._id.$oid
                                      )
                                    }}
                                  </b>
                                  <template
                                    v-if="trova_valori_viaggio(ordine._id.$oid)"
                                  >
                                    <br />
                                    <span
                                      >Posizione
                                      <b>
                                        #
                                        {{
                                          trova_valori_viaggio(ordine._id.$oid)
                                            .index + 1
                                        }}</b
                                      ></span
                                    >
                                  </template>
                                </v-card-subtitle>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn text color="grey" @click="dialog_aggiungi = false"
                        >chiudi</v-btn
                      >
                      <v-btn
                        outlined
                        color="purple"
                        @click="termina_giro()"
                        :disabled="!tappe.length"
                        >Concludi</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-timeline align-top dense>
                  <v-timeline-item icon="mdi-store" fill-dot>
                    <v-row>
                      <v-col cols="12">
                        <b>G.M. Flor</b>
                        <br />
                        Via Villafalletto 59, Fossano
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                  <v-timeline-item
                    fill-dot
                    v-for="(tappa, index) in tappe.filter(
                      (tappa) => !tappa.ritorno
                    )"
                    :key="tappa.id_ordine"
                    clipped
                    icon="mdi-map-marker"
                    :color="array_colori[index].name"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-row>
                        <v-col cols="10" class="text-truncate">
                          <b>{{ ordine(tappa.id_ordine).cliente }}</b>
                          <br />
                          <span
                            class="text-truncate d-inline-block"
                            style="max-width: 200px"
                            >{{
                              ordine(tappa.id_ordine).destinazione.indirizzo
                            }}</span
                          >
                          <br />
                          <template
                            v-if="ordine(tappa.id_ordine).cliente_obj.cambio_cc"
                          >
                            <span class="green--text">Cambio CC</span>
                          </template>
                          <template v-else>
                            <span class="amber--text">Scarico</span>
                          </template>
                          <br />
                          Tot. CC:
                          {{
                            $store.getters.get_qta_cc_ordine(tappa.id_ordine)
                          }}
                        </v-col>
                        <v-col cols="2">
                          <v-btn icon v-if="hover" @click="rimuovi_tappa(index)"
                            ><v-icon color="red">mdi-delete</v-icon></v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-hover>
                  </v-timeline-item>
                  <v-timeline-item
                    small
                    fill-dot
                    v-if="!tappe.some((tappa) => tappa.ritorno)"
                  >
                    <v-row class="grey--text mt-n3">
                      <v-col>
                        <v-btn
                          outlined
                          color="green"
                          small
                          @click="dialog_aggiungi = true"
                          >Aggiungi ...</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                  <v-timeline-item
                    small
                    v-if="!tappe.some((tappa) => tappa.ritorno) && tappe.length"
                    color="purple"
                    fill-dot
                  >
                    <v-row class="mt-n3">
                      <v-col>
                        <v-btn
                          outlined
                          color="purple"
                          @click="termina_giro()"
                          small
                          >Concludi</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                  <v-timeline-item
                    fill-dot
                    icon="mdi-flag-checkered"
                    v-if="tappe.some((tappa) => tappa.ritorno)"
                  >
                    <v-row>
                      <v-col cols="9">
                        <b>G.M. Flor </b>
                        <br />
                        Via Villafalletto 59, Fossano
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
                <v-row class="ml-4 mt-4">
                  <v-col>
                    Totale Carrelli:
                    <b>{{ carrelli_tot }}</b>
                    <br />
                    Tempo Viaggio:
                    <b>{{ tempo_viaggio.text }}</b>
                    <br />
                    Tempo Scarico:
                    <b>{{ tempo_scarico_totale.text }}</b>
                    <br />
                    Tempo Totale:
                    <b>{{ tempo_totale_viaggio }}</b>
                  </v-col>
                </v-row>
                <v-row class="ml-4 mt-4">
                  <v-col cols="12">
                    <v-select
                      v-model="id_mezzo"
                      label="Automezzo"
                      :items="mezzi"
                      item-value="id"
                      item-text="nome"
                      outlined
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      outlined
                      label="Giorno"
                      :items="giorni"
                      v-model="giorno_consegna"
                      item-text="label"
                      item-value="value"
                    >
                      <template v-slot:append-item>
                        <v-menu v-model="menu_scegli_giorno">
                          <template v-slot:activator="{ on }">
                            <v-list-item v-on="on">
                              <v-list-item-content>
                                <v-list-item-title>Il ...</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <v-date-picker
                            v-model="day_select"
                            @change="imposta_giorno_man()"
                          ></v-date-picker>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      outlined
                      label="Orario"
                      :items="orari"
                      v-model="orario_consegna"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="ml-4 mt-4">
                  <v-col>
                    <v-btn
                      outlined
                      color="green"
                      :disabled="
                        !tappe.some((tappa) => tappa.ritorno) ||
                        !id_mezzo ||
                        !orario_consegna ||
                        !giorno_consegna
                      "
                      @click="salva_consegna()"
                      ><v-icon class="mr-2">mdi-content-save</v-icon
                      >Salva</v-btn
                    >
                    <v-btn
                      outlined
                      color="purple"
                      class="ml-4"
                      @click="tappe = []"
                      ><v-icon class="mr-2">mdi-broom</v-icon>pulisci</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import moment from "moment";
export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  props: {},
  data() {
    return {
      dialog: false,
      dialog_aggiungi: false,
      active_menu_index: [],
      center: { lat: 44.542, lng: 7.678 },
      ordini_localizzati: [],
      tappe: [],
      map_with: 9,
      array_colori: [
        { hex: "#F44336", name: "red" },
        { hex: "#9C27B0", name: "purple" },
        { hex: "#4CAF50", name: "green" },
        { hex: "#03A9F4", name: "light-blue" },
        { hex: "#FF9800", name: "orange" },
        { hex: "#009688", name: "teal" },
        { hex: "#E91E63", name: "pink" },
      ],
      giorni: [
        { label: "Oggi", value: moment().startOf("day") },
        {
          label: "Domani",
          value: moment().add(1, "day").startOf("day"),
        },
      ],
      orari: [
        { label: "Prima Mattina", value: 8 },
        { label: "Tarda Mattina", value: 10 },
        { label: "Primo Pomeriggio", value: 14 },
        { label: "Tardo Pomeriggio", value: 16 },
      ],
      menu_scegli_giorno: false,
      day_select: null,
      giorno_consegna: null,
      orario_consegna: null,
      id_mezzo: null,
      window_height: window.innerHeight
    };
  },
  mounted() {
    window.onresize = () => {
      this.window_height = window.innerHeight;
    };
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.ottieni_coordinate_ordini();
      }
    },
  },
  computed: {
    ordini() {
      return this.$store.getters.get_ordini_attivi
        .filter(
          (ord) =>
            (ord.stato == 8 || ord.stato == 1) &&
            ord.ritiro_cod == "1" &&
            ord.joined_in == false &&
            !ord.consegna_programmata
        )
        .map((ordine) => {
          return {
            ...ordine,
            cliente_obj: this.$store.getters.get_cliente_by_iva(ordine.iva),
            cliente: this.$store.getters.get_cliente_by_iva(ordine.iva).nome,
          };
        });
    },
    mezzi() {
      return this.$store.getters.get_automezzi;
    },
    carrelli_tot() {
      return this.tappe
        .filter((tappa) => !tappa.ritorno)
        .map((tappa) => this.$store.getters.get_qta_cc_ordine(tappa.id_ordine))
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },
    tempo_viaggio() {
      let seconds = this.tappe
        // .filter((tappa) => !tappa.ritorno)
        .map((tappa) => tappa.durata.value)
        .reduce((tot, num) => tot + num, 0);
      let duration = moment.duration(seconds, "seconds");
      return {
        value: seconds / 60,
        text: duration.hours() + " ore e " + duration.minutes() + " minuti",
      };
    },
    tempo_scarico_totale() {
      if (!this.tappe.length) {
        return 0;
      }
      let minutes = this.tappe
        .filter((tappa) => !tappa.ritorno)
        .map((tappa) =>
          this.ordini_localizzati.find(
            (ordine) => ordine._id.$oid == tappa.id_ordine
          )
        )
        .map((ordine) => {
          let tot_cc = this.$store.getters.get_qta_cc_ordine(ordine._id.$oid);
          let tempi = this.$store.getters.get_tempi;
          if (ordine.cliente_obj.cambio_cc) {
            return tot_cc * tempi.tempo_cambio_cc + tempi.tempo_pagamento;
          } else {
            return tot_cc * tempi.tempo_scarico_cc + tempi.tempo_pagamento;
          }
        })
        .reduce((tot, num) => tot + num, 0);
      let duration = moment.duration(minutes, "minutes");
      return {
        value: minutes,
        text: duration.hours() + " ore e " + duration.minutes() + " minuti",
      };
    },
    tempo_totale_viaggio() {
      if (!this.tappe.length) {
        return 0;
      }
      let minutes = this.tempo_viaggio.value + this.tempo_scarico_totale.value;
      // if (this.tappe[this.tappe.length - 1].ritorno) {
      //   minutes += this.tappe[this.tappe.length - 1].durata.value / 60;
      // }
      let duration = moment.duration(minutes, "minutes");
      return duration.hours() + " ore e " + duration.minutes() + " minuti";
    },
    ordini_localizzati_destinati() {
      let lista_ordini = this.ordini_localizzati;
      let lista_zona = _.groupBy(lista_ordini, "cliente_obj.zona");
      let lista_finale = [];
      Object.keys(lista_zona).forEach((zona) => {
        lista_finale.push({ zona: zona, ordini: lista_zona[zona] });
      });
      return lista_finale;
    },
  },
  methods: {
    ordine(id) {
      return this.ordini.find((ordine) => ordine._id.$oid == id);
    },
    async get_coords() {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/";
      const promises = this.ordini.map((ordine) =>
        axios.get(url + ordine._id.$oid + "/coordinate", { auth: auth })
      );
      const response = await Promise.all(promises);
      this.punti_ordine = response.map((res) => res.data[0].geometry.location);
      console.log(this.punti_ordine);
    },
    ottieni_coordinate_ordini() {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/ordine/";
      this.ordini_localizzati = [];
      this.ordini.forEach((ordine) => {
        axios
          .get(url + ordine._id.$oid + "/coordinate", { auth: auth })
          .then((res) => {
            this.ordini_localizzati.push({
              ...ordine,
              coord: res.data,
            });
          });
      });
    },
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
    aggiungi_tappa(id_ordine) {
      let last_point = this.tappe.length
        ? this.tappe[this.tappe.length - 1].end_location
        : this.center;
      let ordine_point = this.ordini_localizzati.find(
        (ordine) => ordine._id.$oid == id_ordine
      ).coord;
      this.calcola_percorso({
        partenza: last_point,
        arrivo: ordine_point,
        id_ordine: id_ordine,
        ritorno: false,
      });
    },
    rimuovi_tappa(index) {
      this.tappe.splice(index);
      this.active_menu_index = [];
    },
    rimuovi_tappa_by_order_id(id_ordine) {
      let index = this.tappe.findIndex((tappa) => tappa.id_ordine == id_ordine);
      this.tappe.splice(index);
      this.active_menu_index = [];
    },
    termina_giro() {
      let last_point = this.tappe[this.tappe.length - 1].end_location;
      this.calcola_percorso({
        partenza: last_point,
        arrivo: this.center,
        ritorno: true,
      });
      this.dialog_aggiungi = false;
    },
    calcola_percorso(payload) {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + "/admin/calcola_punto_punto";
      let json = [payload.partenza, payload.arrivo];
      axios.post(url, json, { auth: auth }).then((res) => {
        console.log(res);
        this.tappe.push({
          ...res.data,
          id_ordine: payload.id_ordine,
          ritorno: payload.ritorno,
        });
        this.active_menu_index = [];
      });
    },
    trova_valori_viaggio(id_ordine) {
      let index = this.tappe.findIndex(
        (ordine) => ordine.id_ordine == id_ordine
      );
      if (index < 0) {
        return null;
      }
      return { index: index, value: this.tappe[index] };
    },
    imposta_giorno_man() {
      let mom = moment(this.day_select);
      if (!this.giorni[2]) {
        this.giorni.push({ label: "", value: null });
      }
      this.giorni[2].label = mom.format("dddd");
      this.giorni[2].value = mom;
      this.giorno_consegna = mom;
      this.menu_scegli_giorno = false;
    },
    salva_consegna() {
      this.$store
        .dispatch("salva_consegna", {
          tappe: this.tappe,
          ts_partenza_prevista: this.giorno_consegna
            .hours(this.orario_consegna)
            .format("X"),
          id_mezzo: this.id_mezzo,
        })
        .then(() => {
          this.dialog = false;
          this.tappe = []
        });
    },
    swipe() {
      if (this.map_with == 6) {
        this.map_with = 9;
      } else {
        this.map_with = 6;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 310px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>
