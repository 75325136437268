<template>
  <v-menu v-model="menu" max-width="300">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-avatar>
          <v-icon :color="icon(item[5].weather[0].icon).color" size="30">
            {{ icon(item[5].weather[0].icon).icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="green--text overline">
            {{ data }}
          </v-list-item-subtitle>
          <v-list-item-title>
            <span style="font-size: 14px" class="blue--text">{{ temp }}° </span>
            <span style="font-size: 12px" class="grey--text"
              >{{ temp_perc }}° percepita</span
            >
          </v-list-item-title>
          <v-list-item-subtitle class="grey--text">
            {{ item[5].weather[0].description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-subtitle
        ><v-icon class="mr-2">mdi-calendar</v-icon>{{ giorno }}</v-card-subtitle
      >
      <template v-for="ora in item">
        <v-list-item :key="ora.dt">
          <v-list-item-avatar size="40">
            <v-icon size="30" :color="icon(ora.weather[0].icon).color">{{ icon(ora.weather[0].icon).icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              ore {{ orario(ora.dt) }}
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ ora.weather[0].description }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="ora.rain">
              <v-row no-gutters align="center">
                <v-col cols="5">
                  <v-icon size="15">mdi-weather-pouring</v-icon>
                  {{ rain_mm(ora.rain).tot }} mm
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    height="8"
                    rounded
                    v-model="rain_mm(ora.rain).percent"
                    :color="rain_mm(ora.rain).color"
                    width="50"
                  />
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="ora.snow">
              <v-row no-gutters align="center">
                <v-col cols="5">
                  <v-icon size="15">mdi-weather-snowy</v-icon>
                  {{ rain_mm(ora.snow).tot }} mm
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    height="8"
                    rounded
                    v-model="rain_mm(ora.snow).percent"
                    :color="rain_mm(ora.snow).color"
                    width="50"
                  />
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon size="15">mdi-thermometer</v-icon
              >{{ temp_forecast(ora.main.temp) }}°
              <v-icon size="15" class="ml-2">mdi-weather-windy</v-icon>
              {{ (ora.wind.speed * 3.6).toFixed(0) }} km/h
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="ora.dt + '-div'" />
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: {
    item: Array,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    temp() {
      return (this.item[3].main.temp - 273.15).toFixed(1);
    },
    temp_perc() {
      return (this.item[3].main.feels_like - 273.15).toFixed(1);
    },
    data() {
      return moment.unix(this.item[0].dt).calendar({
        sameDay: "[Oggi]",
        nextDay: "[domani]",
        nextWeek: "dddd",
        sameElse: "dddd",
      });
    },
    giorno() {
      return moment.unix(this.item[0].dt).format("dddd DD MMMM ");
    },
  },
  methods: {
    icon(id) {
      switch (id) {
        case "01d":
          return { icon: "mdi-weather-sunny", color: "amber"};
        case "02d":
          return { icon: "mdi-weather-partly-cloudy", color: "blue"};
        case "03d":
          return { icon: "mdi-weather-cloudy", color: "grey"};
        case "04d":
          return { icon: "mdi-weather-cloudy", color: "grey"};
        case "09d":
          return { icon: "mdi-weather-pouring", color: "purple"};
        case "10d":
          return { icon: "mdi-weather-partly-rainy", color: "blue"};
        case "11d":
          return { icon: "mdi-weather-lightning", color: "yellow"};
        case "13d":
          return { icon: "mdi-weather-snowy", color: "cyan"};
        case "50d":
          return { icon: "mdi-weather-fog", color: "grey"};
        case "01n":
          return { icon: "mdi-weather-night", color: "amber"};
        case "02n":
          return { icon: "mdi-weather-night-partly-cloudy", color: "blue"};
        case "03n":
          return { icon: "mdi-weather-cloudy", color: "grey"};
        case "04n":
          return { icon: "mdi-weather-cloudy", color: "grey"};
        case "09n":
          return { icon: "mdi-weather-pouring", color: "purple"};
        case "10n":
          return { icon: "mdi-weather-rainy", color: "indigo"};
        case "11n":
          return { icon: "mdi-weather-lightning", color: "yellow"};
        case "13n":
          return { icon: "mdi-weather-snowy", color: "cyan"};
        case "50n":
          return { icon: "mdi-weather-fog", color: "grey"};
      }
      return null;
    },
    orario(ts) {
      return moment.unix(ts).format("HH:mm");
    },
    temp_forecast(t) {
      return (t - 273.15).toFixed(1);
    },
    rain_mm(rain) {
      let mm = rain["3h"];
      let text = "";
      let color = "";

      if (mm < 1) {
        text = "pioviggine";

        color = "blue lighten-4";
      } else if (mm < 5) {
        text = "moderata";

        color = "blue lighten-2";
      } else if (mm < 15) {
        text = "moderata";

        color = "blue";
      } else if (mm < 25) {
        text = "abbondanti";

        color = "blue darken-4";
      } else if (mm < 40) {
        text = "forti";

        color = "purple";
      } else {
        text = "nubifragi";

        color = "red";
      }
      return {
        tot: mm,
        text: text,
        percent: mm * 3,
        color: color,
      };
    },
  },
};
</script>