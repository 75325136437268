import axios from "axios";

export default {
  state: {
    calendario: {},
  },
  getters: {
    get_calendario_arrivi: (state) => {
      return state.calendario;
    },
    get_lista_prodotti_arrivo: (state) => (payload) => {
      let arrivo = state.calendario.settimane
        .find((sett) => sett.id == payload.id_settimana)
        .arrivi.find((arrivo) => arrivo.id == payload.id_arrivo);
      if (!arrivo) {
        return null;
      } else {
        return arrivo.prodotti;
      }
    },
  },
  mutations: {
    set_calendario_arrivi(state, payload) {
      state.calendario = payload;
    },
    set_nuovo_arrivo(state, payload) {
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      settimana.arrivi.push(payload.arrivo);
    },
    delete_arrivo(state, payload) {
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      let index = settimana.arrivi.findIndex(
        (arrivo) => arrivo.id == payload.id_arrivo
      );
      settimana.arrivi.splice(index, 1);
    },
    add_articolo_arrivo(state, payload) {
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      let arrivo = settimana.arrivi.find(
        (arrivo) => arrivo.id == payload.prodotto.id_arrivo
      );
      arrivo.prodotti.push(payload.prodotto);
    },
    set_nome_pdf_arrivo(state, payload) {
      console.log(payload);
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      let arrivo = settimana.arrivi.find(
        (arrivo) => arrivo.id == payload.id_arrivo
      );
      arrivo.pdf = payload.file_name;
    },
    delete_articolo_arrivo(state, payload) {
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      let arrivo = settimana.arrivi.find(
        (arrivo) => arrivo.id == payload.id_arrivo
      );
      let index = arrivo.prodotti.findIndex(
        (prod) => prod.id == payload.id_prodotto
      );
      arrivo.prodotti.splice(index, 1);
    },
    update_articolo_arrivo(state, payload) {
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      let arrivo = settimana.arrivi.find(
        (arrivo) => arrivo.id == payload.id_arrivo
      );
      let prodotto = arrivo.prodotti.find(
        (prod) => prod.id == payload.id_prodotto
      );
      prodotto.qta = payload.qta;
    },
    remove_pdf_arrivo(state, payload) {
      let settimana = state.calendario.settimane.find(
        (sett) => sett.id == payload.id_settimana
      );
      let arrivo = settimana.arrivi.find(
        (arrivo) => arrivo.id == payload.id_arrivo
      );
      arrivo.pdf = null;
    },
  },
  actions: {
    fetch_calendario_arrivi(context, anno_rif) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/calendario_arrivi/" + anno_rif;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_calendario_arrivi", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_arrivo_settimana(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/calendario_arrivi/settimana/" +
          payload.id_settimana +
          "/arrivo/fornitore/" +
          payload.id_fornitore;
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_nuovo_arrivo", {
              id_settimana: payload.id_settimana,
              arrivo: res.data,
            });
            resolve(res.data.id);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    rimuovi_arrivo_settimana(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/calendario_arrivi/arrivo/" +
          payload.id_arrivo;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("delete_arrivo", {
              id_settimana: payload.id_settimana,
              id_arrivo: payload.id_arrivo,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_prodotto_arrivo(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/calendario_arrivi/arrivo/" +
          payload.id_arrivo +
          "/articolo";
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("add_articolo_arrivo", {
              id_settimana: payload.id_settimana,
              prodotto: res.data,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    rimuovi_prodotto_arrivo(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/calendario_arrivi/prodotto/" +
          payload.id_prodotto;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("delete_articolo_arrivo", {
              id_settimana: payload.id_settimana,
              id_arrivo: payload.id_arrivo,
              id_prodotto: payload.id_prodotto,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    modifica_prodotto_arrivo(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/calendario_arrivi/prodotto/" +
          payload.id_prodotto +
          "/qta/" +
          payload.qta;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            context.commit("update_articolo_arrivo", {
              id_settimana: payload.id_settimana,
              id_arrivo: payload.id_arrivo,
              id_prodotto: payload.id_prodotto,
              qta: payload.qta,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_pdf_arrivo_settimana(context, payload) {
      return new Promise((resolve, reject) => {
        let auth = context.getters.get_credential;
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/calendario_arrivi/arrivo/" +
          payload.id_arrivo +
          "/pdf";
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("remove_pdf_arrivo", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
