import moment from 'moment'

export default {
    props: {
        ordine: Object,
        span: Boolean
    },
    data() {
        return {
            menu_cambio_data: false,
            modal_date: false,
            modal_time: false,

            date: this.ordine.orario_ts > 0
                ? moment.unix(this.ordine.orario_ts).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
            time: this.ordine.orario_ts > 0
                ? moment.unix(this.ordine.orario_ts).format("HH:mm")
                : moment().format("HH:mm"),

        }
    },
    computed: {
        dateFormatter() {
            moment.locale("it");
            return this.date ? moment(this.date).format("dddd D MMMM") : "";
        },
        minDate() {
            return moment().format("YYYY-MM-DD");
        },
        validDate() {
            return moment(this.date + " " + this.time).isValid();
        },
        ritiro_date() {
            if (this.ordine.orario_ts) {
                moment.locale("it");
                return moment.unix(this.ordine.orario_ts).format("ddd DD MMM HH:mm");
            } else {
                return null;
            }
        },
    },
    methods: {
        closePicker: function (v) {
            v = v < 10 ? "0" + v : v;
            this.time = v + ":00";
            this.modal_time = false;
        },
        confirm_date_change() {
            this.$store.dispatch("imposta_data_ordine", {
                id: this.ordine._id.$oid,
                ts: moment(this.date + " " + this.time).format("X"),
            });
        }
    }
}