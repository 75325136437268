<template>
  <v-menu
    v-model="menu"
    offset-y
    bottom
    max-width="200"
    :close-on-content-click="false"
    class=""
  >
    <template v-slot:activator="{ on: men_u }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tip }">
          <v-badge
            :color="qta ? 'green' : 'pink'"
            :value="color_in_order ? !color_in_order.eliminato : qta_user"
            :content="qta ? qta : qta_user"
            overlap
            bordered
            offset-x="20"
            offset-y="20"
          >
            <v-avatar
              v-on="{ ...tip, ...men_u }"
              style="cursor: pointer"
              class="mr-2"
              size="70"
            >
              <v-img :src="color_img" :alt="colore.nome">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-badge>
        </template>
        <span class="text-capitalize">{{ colore.nome }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text class="mt-n2">
        <v-row no-gutters class="primary--text">
          {{ colore.nome }}
          <v-spacer />
          <v-icon size="20" @click="menu = false"> mdi-close </v-icon>
        </v-row>
        <v-row no-gutters style="font-size: 12px" class="mb-2">
          Specifica quantità:
        </v-row>
        <v-row no-gutters align="center">
          <v-col cols="12">
            <v-text-field
              maxlength="3"
              dense
              hide-details
              ref="qta_box"
              v-model="qta"
              outlined
              @focus="select_text"
              prepend-icon="mdi-minus"
              append-outer-icon="mdi-plus"
              @click:prepend="qta > 0 ? qta-- : qta"
              @click:append-outer="qta++"
              :rules="[(v) => !isNaN(v) || 'inserire numero intero']"
            >
              <template v-slot:append>
                <v-slide-x-reverse-transition mode="out-in">
                  <v-icon color="grey" @click="qta = null" v-if="!on_action"
                    >mdi-delete</v-icon
                  >
                  <v-progress-circular
                    indeterminate
                    width="1"
                    size="18"
                    color="grey"
                    class="mt-1"
                    v-if="loading"
                  ></v-progress-circular>
                  <v-icon color="success" v-if="adding">mdi-check</v-icon>
                  <v-icon color="error" v-if="removing">mdi-cart-remove</v-icon>
                </v-slide-x-reverse-transition>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="mt-4">
            <v-btn
              v-if="qta != 0 || qta_user != 0"
              outlined
              color="red"
              block
              :loading="loading_elimina"
              @click="elimina_colore()"
              ><v-icon class="mr-2">mdi-delete</v-icon>Elimina</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col cols="12">
            <span v-if="!valid">
              <v-icon size="20" class="mr-2 ml-7" color="error"
                >mdi-cancel</v-icon
              >
              Inserire Numero
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import ordine_aggiungi_colore_mixin from "../../mixin/ordine_dettaglio/ordine_aggiungi_colore_mixin";
export default {
  mixins: [ordine_aggiungi_colore_mixin],
};
</script>