<template>
  <DashBoardDesktop v-if="!$vuetify.breakpoint.mobile"/>
  <DashBoardMobile v-else />
</template>

<script>
  import DashBoardDesktop from '../components/Dashboard/dashboard_desktop/dashboard_desktop.vue'
  import DashBoardMobile from '../components/Dashboard/dashboard_mobile/dashboard_mobile.vue'

  export default {
    name: 'Home',

    components: {
      DashBoardDesktop,
      DashBoardMobile,
    },
  }
</script>
