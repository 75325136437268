import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";

import VueObserveVisibility from "vue-observe-visibility";

import * as VueGoogleMaps from 'vue2-google-maps'

//import GmapVue from 'gmap-vue'

Vue.config.productionTip = false;

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketIO(process.env.VUE_APP_SOCKET_URL, {
      autoConnect: false,
    }),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.use(VueObserveVisibility);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBUyk6fBl1F9d_utG8J0uXZZNzlhbesB8g',
    libraries: 'places',
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
