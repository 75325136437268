import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import vapid from "../service/vapid";

export default {
  data() {
    return {
      notif_token: null,
      notificationsSupported: false,
      show_notification_request: false,
      show_notification_denied: false,
      show_notification_ok: false,
      // no_sub: false,
    };
  },

  created() {
    if ("Notification" in window && "serviceWorker" in navigator) {
      console.log("[NOTIF] Notif supported");
      this.notificationsSupported = true;
    } else {
      console.log("[NOTIF] Notif NOT supported");
    }
    if (this.$route.query.chat == "true") {
      setTimeout(() => {
        this.$refs.chat.open();
      }, 1000);
    }
  },

  methods: {
    checkSubscripions() {
      if (Notification.permission == "denied") {
        console.log("[NOTIF] Notifiche non permesse");
        return;
      }
      const firebaseConfig = {
        apiKey: "AIzaSyB5pz7DbT147g9-RD1Ux-pUft6Eq41Ducw",
        authDomain: "web-shop-2.firebaseapp.com",
        projectId: "web-shop-2",
        storageBucket: "web-shop-2.appspot.com",
        messagingSenderId: "750742685458",
        appId: "1:750742685458:web:9cc7e9d8aba7fc4a3f95cb",
      };
      console.log("[FIREBASE]Inizializzo firebase");
      // Initialize Firebase
      const firebase = initializeApp(firebaseConfig);

      const messaging = getMessaging(firebase);
      vapid.fetch_vapid().then((vapid_key) => {
        getToken(messaging, {
          vapidKey: vapid_key,
        }).then((token) => {
          this.notif_token = token
          vapid.send_subscription(token);
          onMessage(messaging, (payload) => {
            console.log("[firebase-messaging-sw.js] Notifica Ricevuta");
            if (payload.data.tag == "chat") {
              let coord = JSON.parse(payload.data.coord);
              this.$store.dispatch("confirm_notif_received", coord);
            }
          });
        });
      });
    },
  },
};
