<template>
  <v-dialog v-model="dialog" width="1000" persistent scrollable>
    <template v-slot:activator="{ on }" v-if="icon">
      <v-btn icon color="blue" v-on="on">
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Dettaglio Conteggio
        <v-spacer />
        <template v-if="integrità != null">
          <span style="font-size: 14px">
            Integrità {{ integrità.valore.toFixed(0) }} %
          </span>
          <v-icon
            size="15"
            class="ml-4"
            :color="integrità.ultimo ? 'green' : 'blue'"
            >mdi-circle</v-icon
          >
        </template>
      </v-card-title>
      <v-card-subtitle class="mt-0">
        <template v-if="ordine != null && !loading_conteggio">
          Ordine # {{ ordine.numero }}
          <br />
          {{ cliente.nome }}
        </template>

        <v-row no-gutters class="mt-4">
          <v-col cols="4">Prodotto</v-col>

          <v-col cols="2">Quantità in Ordine</v-col>
          <v-col cols="2">Quantità Contata</v-col>
          <v-col cols="2" class="d-flex justify-center">Discrepanza</v-col>
        </v-row>
        <v-divider class="mt-2" />
      </v-card-subtitle>
      <template v-if="loading_conteggio">
        <v-card-text>
          <v-row justify="center" class="my-8">
            <v-progress-circular indeterminate width="2"></v-progress-circular>
          </v-row>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text v-if="conteggio != 'DoesNotExist'">
          <ConteggioDettaglioEl
            v-for="prodotto in lista_combinata"
            :key="prodotto.id_prodotto"
            :prodotto="prodotto"
            :listino="listino"
          />
          <v-divider class="my-2" />
          <template v-if="ordine">
          <v-row no-gutters align="center">
            <v-col cols="4"></v-col>

            <v-col cols="2" 
              >{{ totale_ordinato.toFixed(2) }} €</v-col
            >
            <v-col cols="2" 
              >{{ totale_contato.toFixed(2) }} €</v-col
            >
            <v-col cols="2" >
              <v-list-item class="">
                <v-list-item-avatar>
                  <v-icon size="" :color="totale_discrepanza.color">
                    {{ totale_discrepanza.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >{{ totale_discrepanza.tot.toFixed(2) }} €
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider class="mb-6"/>
          </template>
          <v-row v-if="ordine" align="center" no-gutters>
            <v-col cols="8"></v-col>
            <v-col cols="2">Trasporto:</v-col>
            <v-col cols="2">{{ordine.tot_trasp.toFixed(2)}} €</v-col>
          </v-row>
          <v-row align="center" no-gutters >
            <v-col cols="8"></v-col>
            <v-col cols="2">Tot Contato:</v-col>
            <v-col cols="2">{{ totale_contato.toFixed(2)}} €</v-col>
          </v-row>
          <v-row align="center" class="font-weight-bold" no-gutters >
            <v-col cols="8"></v-col>
            <v-col cols="2">Tot Documento:</v-col>
            <v-col cols="2" >{{( totale_documento).toFixed(2)}} € + IVA</v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else> Nessun conteggio </v-card-text>
      </template>

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="green"
          :loading="loading_invia"
          @click="spedisci_conteggio()"
          ><v-icon class="mr-2">mdi-send</v-icon>spedisci</v-btn
        >

        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConteggioDettaglioEl from "./conteggio_dettaglio_element.vue";
import conteggio_dettaglio_mixin from "../../mixin/conteggio_dettaglio_mixin";
export default {
  components: {
    ConteggioDettaglioEl,
  },
  mixins: [conteggio_dettaglio_mixin],
};
</script>