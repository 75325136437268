<template>
  <v-app>
    <template v-if="loading">
      <v-container fill-height>
        <v-row justify="center">
          <v-col cols="12" class="d-flex justify-center">
            <v-img src="@/assets/glogo.svg" width="80" height="80" contain />
          </v-col>
          <v-col md="1" cols="4">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <Login :show="!isAuth" @login_ok="accedi" />
      <template v-if="isAuth">
        <v-navigation-drawer
          app
          v-model="drawer"
          :mini-variant="mini"
          :width="170"
          permanent
          touchless
          style="background-color: #eee"
          :expand-on-hover="!is_mobile"
          :clipped="is_mobile"
        >
          <v-list nav shaped dense>
            <v-list-item-group>
              <v-list-item class="px-2" v-if="!is_mobile">
                <v-list-item-avatar size="40" class="ml-n2" tile>
                  <v-img src="@/assets/logo_half.svg"></v-img>
                </v-list-item-avatar>

                <v-list-item-title class="green--text text-h6"
                  >G.M.Flor</v-list-item-title
                >
              </v-list-item>

              <v-list-item to="/">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-view-dashboard-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Home
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-1" />

              <v-list-item to="/ordini">
                <v-list-item-icon>
                  <v-badge
                    top
                    :value="n_nuovi_ordini"
                    :content="n_nuovi_ordini"
                    dot
                    overlap
                  >
                    <v-icon color="grey"> mdi-clipboard </v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Ordini
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/consegne">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-truck </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Consegne
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/conteggi">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-clipboard-list </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Conteggi
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/prodotti">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-spa </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Prodotti
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-1" v-if="!is_mobile" />

              <v-list-item to="/statistiche" v-if="!is_mobile">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-chart-bar </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Statistiche
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-1" />

              <v-list-item to="/chat">
                <v-list-item-icon>
                  <v-badge
                    top
                    :value="chats_nl"
                    :content="chats_nl"
                    dot
                    overlap
                  >
                    <v-icon color="grey"> mdi-chat-outline </v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Chat
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/wachat" v-if="is_mobile">
                <v-list-item-icon>
                  <v-badge top :value="wachats_nl" dot overlap>
                    <v-icon color="grey"> mdi-whatsapp </v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Whatsapp
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/users">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-account-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Clienti
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-1" />

              <v-list-item to="/lotti">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-barcode </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Lotti
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/calendario_arrivi" v-if="!is_mobile">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-calendar-import </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Calendario
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/fornitori">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-account-cash </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Fornitori
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/automezzi">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-truck-flatbed </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Automezzi
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/tanks">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-propane-tank</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Cisterne
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/quaderno_campagna">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-bug</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    QDC
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-1" v-if="!is_mobile" />

              <v-list-item to="/crisantemi" v-if="!is_mobile">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-cross </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Crisantemi
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="mb-1" />
              <v-list-item to="/setting">
                <v-list-item-icon>
                  <v-icon color="grey"> mdi-cog </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    Setting
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <template v-if="!is_mobile">
          <v-main style="background-color: #eee">
            <v-row justify="center" class="mt-1">
              <v-col cols="12" md="11">
                <v-app-bar color="#eee" dark flat>
                  <v-row align="center">
                    <Weather />
                    <v-col cols="2">
                      <v-list-item class="">
                        <v-list-item-avatar>
                          <v-avatar color="primary" size="40">
                            <v-icon color="white"> mdi-account </v-icon>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="grey--text"
                            style="font-size: 20px"
                          >
                            {{ nome_utente }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="grey--text text-decoration-underline"
                            style="cursor: pointer"
                            @click="logout()"
                          >
                            Logout
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-app-bar>
                <v-container fluid class="mt-2">
                  <router-view />
                </v-container>
              </v-col>
            </v-row>
          </v-main>
        </template>
        <template v-else>
          <v-app-bar app clipped-left class="white">
            <v-avatar tile size="30" class="mr-6">
              <v-img src="@/assets/logo_half.svg"></v-img>
            </v-avatar>
            <span class="text-h6 green--text">{{ route_name }}</span
            ><v-spacer />
            <v-menu v-model="logout_menu">
              <template v-slot:activator="{ on }">
                <span class="grey--text" v-on="on">
                  {{ nome_utente }}
                </span>
                <v-avatar size="30" color="green" class="mr-2"
                  ><v-icon color="white">mdi-account</v-icon></v-avatar
                >
              </template>
              <v-card>
                <v-list-item @click="logout()">
                  <v-list-item-avatar>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Log Out</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-menu>
          </v-app-bar>
          <v-main>
            <v-container fluid class="mt-2">
              <router-view />
            </v-container>
          </v-main>
        </template>
      </template>
    </template>
    <v-snackbar
      bottom
      right
      outlined
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      <v-row>
        <v-col> Nuova Versione Disponibile </v-col>
        <v-col>
          <v-btn color="primary" @click="refreshApp"> Aggiorna </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-footer>
      <v-spacer></v-spacer>Federico Bonino © {{ year }} - v
      {{ version }}</v-footer
    >
  </v-app>
</template>

<script>
import Login from "@/components/Login";
import fetch from "./service/fetch";
import focus_watcher from "./service/utility";
import Weather from "@/components/AppBar/desktop/weather.vue";
import moment from "moment";
//import subscription_mixin from "./app_mixin/subscription.js";
import firebase_msg from "./app_mixin/firebase_msg.js";
import sw_update_mixin from "./app_mixin/update.js";

export default {
  name: "App",
  components: {
    Login,
    Weather,
  },
  mixins: [firebase_msg, sw_update_mixin],

  data: () => ({
    drawer: false,
    mini: true,
    loading: true,
    menu_account: false,
    logout_menu: false,
  }),
  created() {
    moment.locale("it");
  },
  mounted() {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") == null
    ) {
      this.loading = false;
      return;
    }
    //Controllo se il token è valido

    let auth = {
      username: localStorage.getItem("token"),
      password: "",
    };
    this.verifica_credenziali(auth);
  },
  computed: {
    isAuth() {
      return this.$store.getters.get_isAuth;
    },
    is_mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    route_name() {
      return this.$route.name;
    },
    chats_nl() {
      return this.$store.getters.get_chats_nl;
    },
    wachats_nl() {
      // return false
      return this.$store.getters.get_wachats_nl;
    },
    n_nuovi_ordini() {
      return this.$store.getters.get_ordini_nuovi;
    },
    nome_utente() {
      return this.$store.getters.get_utente.nome;
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
    year() {
      return moment().format("YYYY");
    },
  },
  methods: {
    verifica_credenziali(auth) {
      this.$store
        .dispatch("fetch_auth", auth)
        .then(() => {
          this.accedi();
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {});
    },
    accedi() {
      console.log("[APP] Accedi");
      this.loading = true;
      this.$socket.io.opts.query = {
        token: this.$store.getters.get_token,
        device: "",
      };
      fetch
        .fetch_2()
        .then(() => {
          this.$store.commit("set_isAuth", true);
          this.$socket.open();
          this.checkSubscripions();
        })
        .finally(() => {
          this.loading = false;
        });
      focus_watcher.focus_watcher();
      window.addEventListener("win_focus_get", () => {
        this.$store.dispatch("fetch_ordini_attivi");
        this.$store.dispatch("fetch_chats");
        this.$store.dispatch("fetch_logs");
        this.$store.dispatch("fetch_wamsgs");
        this.$store.dispatch("fetch_consegne");
        console.log(this.$socket)
        if (!this.$socket.connected && this.$store.getters.get_isAuth) {
          console.log("socket disconnesso. Riconnetto...");
          this.$socket.open();
        }
      });
    },
    logout() {
      this.$store.dispatch("logout", this.notif_token);
      this.$socket.close();
      this.menu_account = false;
    },
  },
};
</script>
