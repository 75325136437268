<template>
  <v-row
    v-if="messaggio.isNlFlag"
    justify="center"
    align="center"
    class="my-2 py-1 mx-n6"
    style="background-color: rgba(255, 255, 255, 0.3)"
  >
    <v-chip color="white" class="blue--text" small> Messaggi non letti </v-chip>
  </v-row>
  <v-row
    v-else
    class="my-2 px-4"
    :justify="autore.isAdmin ? 'end' : 'start'"
    v-observe-visibility="{ callback: visibilityChanged, once: true }"
  >
    <transition appear name="msg">
      <v-card
        elevation="1"
        color=" "
        max-width="350"
        :class="{ whgreen: mioMsg, 'pt-6': loneEmoji }"
        class="px-2 pt-2"
        @contextmenu="show"
      >
      <template v-if="!miaChat">
        <v-row no-gutters class="mb-n1">
         <span :class="autore.isAdmin ? 'green--text' : 'blue--text'" class="font-weight-light" style="font-size: 12px">{{autore.nome}}</span>
        </v-row>
      </template>
        <template v-if="messaggio.isText">
          <v-row no-gutters class="">
            <template v-if="loneEmoji">
              <transition appear="" name="emoji">
                <div>
                  <span style="font-size: 50px">{{ messaggio.testo }}</span>
                </div>
              </transition>
            </template>
            <template v-else>
              <span>{{ messaggio.testo }}</span>
            </template>
          </v-row>
        </template>
        <template v-if="messaggio.isImg">
          <v-dialog v-model="dialog" max-width="800">
            <template v-slot:activator="{ on }">
              <v-card
                v-if="messaggio.loading"
                elevation="0"
                height="50"
                width="50"
              >
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    size="20"
                    width="2"
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </v-card>
              <v-card v-else elevation="0" height="150" width="200">
                <v-img :src="thum_url" v-on="on">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="green"
                        width="2"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </template>
            <v-img :src="img_url" contain max-height="700" @click="dialog = false" />
          </v-dialog>
        </template>
        <template v-if="messaggio.isFile">
          <v-card v-if="messaggio.loading" elevation="0" height="50" width="50">
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                color="primary"
              ></v-progress-circular>
            </v-row>
          </v-card>
          <v-card
            v-else
            class="pa-2"
            elevation="0"
            width="300"
            @click="download()"
          >
            <v-row align="center" class="pa-2" no-gutters>
              <v-col cols="2" class="d-flex justify-start">
                <v-icon :color="type_icon.color">{{ type_icon.icon }}</v-icon>
              </v-col>
              <v-col cols="8" class="text-truncate">
                {{ messaggio.file_name }}.{{ messaggio.file_type }}
              </v-col>
              <v-col cols="2">
                <v-icon>mdi-download</v-icon>
              </v-col>
              <v-col cols="12">
                <span class="grey--text" style="font-size: 12px">
                  file size: {{ messaggio.file_size }} Mb</span
                >
              </v-col>
            </v-row>
          </v-card>
        </template>
        <v-row justify="end" align="center" no-gutters>
          <span style="font-size: 10px" class="grey--text">{{ ora }}</span>
          <template v-if="mioMsg && messaggio.letto == 0">
            <v-icon color="grey" size="15" class="ml-2">mdi-check</v-icon>
          </template>
          <template v-if="mioMsg && messaggio.letto == 1">
            <v-icon color="grey" size="15" class="ml-2">mdi-check-all</v-icon>
          </template>
          <template v-if="mioMsg && messaggio.letto == 2">
            <v-icon color="blue" size="15" class="ml-2">mdi-check-all</v-icon>
          </template>
        </v-row>
      </v-card>
    </transition>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="elimina_messaggio()"> 
          <v-list-item-avatar>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Elimina</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>
<script>
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    messaggio: Object,
    miaChat: Boolean,
    id_conversazione: String,
    chat_visibile: Boolean,
  },
  created() {
    // if (
    //   this.chat_visibile &&
    //   !this.messaggio.letto &&
    //   this.messaggio.autore != this.$store.getters.get_utente.iva
    // ) {
    //   this.$store.dispatch("send_read_confirm_chat", {
    //     id_chat: this.id_conversazione,
    //     id_messaggio: this.messaggio._id,
    //   });
    // }
  },
  data() {
    return {
      dialog: false,
      showMenu: false,
      x: 0,
      y: 0,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      img_loaded: "set_msg_img_load",
    }),
    mioMsg() {
      return this.messaggio.autore == this.$store.getters.get_utente.iva;
    },
    autore(){
      return this.$store.getters.get_cliente_by_iva(this.messaggio.autore)
    },
    ora() {
      return moment.unix(this.messaggio.ts_creazione).format("HH:mm");
    },
    img_height() {
      return (300 * this.messaggio.height) / this.messaggio.width;
    },
    loneEmoji() {
      if (!this.messaggio.isText) {
        return false;
      }
      if (this.messaggio.testo.length == 2) {
        const regexExp =
          /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
        let res = regexExp.test(this.messaggio.testo);
        return res;
      }
      return false;
    },
    type_icon() {
      switch (this.messaggio.file_type) {
        case "pdf":
          return {
            icon: "mdi-file-pdf-box",
            color: "red",
          };
        case "doc":
        case "docx":
          return {
            icon: "mdi-file-word-box",
            color: "blue",
          };
        case "xlsx":
          return {
            icon: "mdi-file-excel-box",
            color: "green",
          };
        default:
          return {
            icon: "mdi-file",
            color: "blue",
          };
      }
    },
    thum_url() {
      return process.env.VUE_APP_API_ROOT + "/chat/thumb/" + this.messaggio._id;
    },
    img_url() {
      return process.env.VUE_APP_API_ROOT + "/chat/foto/" + this.messaggio._id;
    },
  },
  methods: {
    download() {
      let auth = this.$store.getters.get_credential;
      let url = process.env.VUE_APP_API_ROOT + '/chat/file/' + this.messaggio._id
      axios
        .get(url, { auth: auth, responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.messaggio.file_name + "." + this.messaggio.file_type
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    visibilityChanged() {
      if (!this.chat_visibile) {
        return;
      }
      if (
        this.messaggio.letto != 2 &&
        //this.messaggio.autore != this.$store.getters.get_utente.iva &&
        !this.autore_is_admin(this.messaggio.autore)

      ) {
        console.log("Invio conferma di ricezione")
        this.$store.dispatch("send_read_confirm_chat", {
          id_chat: this.id_conversazione,
          id_messaggio: this.messaggio._id,
        });
      }
    },
    elimina_messaggio(){
      this.$store.dispatch("elimina_messaggio_chat", this.messaggio)
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    autore_is_admin(iva){
      return this.$store.getters.get_cliente_by_iva(iva).isAdmin
    }
  },
};
</script>

<style scoped>
.whgreen {
  background-color: #dcf8c6;
}

.msg-enter-active,
.msg-leave-active {
  transition: opacity 0.9s;
}
.msg-enter, .msg-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.emoji-enter-active {
  animation: bounce-in 0.5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>