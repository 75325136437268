export default {
  props: {
    prodotto: Object,
    listino: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    avatar_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.prodotto.id_prodotto
      );
    },
    prodotto_raw() {
      return this.$store.getters.get_prodotto_by_id(this.prodotto.id_prodotto);
    },
    nome() {
      return this.prodotto_raw.nome.split(" in ");
    },
    prezzo_prodotto() {
      if (this.prodotto.prezzo_contato) {
        return this.prodotto.prezzo_contato;
      }
      switch (this.listino) {
        case "1":
          return this.prodotto_raw.prezzo1;
        case "2":
          return this.prodotto_raw.prezzo2;
        case "3":
          return this.prodotto_raw.prezzo3;
      }
      return 0;
    },
    tot_ordinato() {
      let qta = 0;
      if (this.prodotto.qta_ordine) {
        qta = this.prodotto.qta_ordine;
      }
      return qta * this.prezzo_prodotto;
    },
    tot_conteggiato() {
      return this.prodotto.qta_contata * this.prezzo_prodotto;
    },
    tot_discrepanza() {
      return this.discrepanza.qta * this.prezzo_prodotto;
    },
    discrepanza() {
      let qta;
      if (this.prodotto.qta_ordine)
        qta = this.prodotto.qta_contata - this.prodotto.qta_ordine;
      else {
        qta = this.prodotto.qta_contata;
      }
      return {
        qta: qta,
        icon: qta == 0 ? "mdi-check" : "mdi-alert",
        color: qta == 0 ? "green" : "amber",
      };
    },
  },
};
