export default {
    props: {
        item: Object
    },
    data: () => ({
        show_dialog: false
    }),
    computed: {
        nome() {
            if (this.item.nome.includes("Vaso")) {
                return this.item.nome.split("Vaso")[0];
            } else {
                return this.item.nome.split("Basket")[0];
            }

        },
        tipo_vaso() {
            if (this.item.unita == '1') {
                return 'Vaso Singolo'
            } else {
                return 'Cassa da ' + this.item.unita
            }
        },
        numero_prodotti() {
            let prodotto = this.$store.getters.get_carrello.prodotti.find(prod => prod.art == this.item.id)
            if (!prodotto) {
                return 0
            }
            return prodotto.colori.map(e => e.numero).reduce((tot, num) => tot + parseInt(num), 0)
        },
        image_list() {
            let array = []
            for (let i = 0; i < this.item.img_list_count; i++) {
                array.push(this.item.img_list_src + i)
            }
            return array
        },
        avatar_img(){
            return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.item.id + "?t=" + this.item.avatar_md5
        }
    },
    methods: {
        open_dettaglio() {
            this.$refs.dialog_prodotto.open()
        },
        switch_pref() {
            this.item.preferito = !this.item.preferito
            this.$store.dispatch("switch_pref", this.item)
        }
    }
}