<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on }">
      <v-btn icon color="purple" v-on="on"><v-icon>mdi-cart</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text"> Carrello di {{cliente.nome}} </v-card-title>
      <v-card-text>
        <v-list>
          <CarrelloElement
            v-for="prodotto in carrello"
            :item="prodotto"
            :key="prodotto.art"
          />
        </v-list>
        <v-row justify="end" align="center" class="py-8 pr-6" style="font-size: 22px"> € {{ totale.toFixed(2) }} </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import carrello_cliente_mixin from "../../mixin/carrello_cliente_mixin";
import CarrelloElement from "./carrello_element_mobile.vue";
export default {
  components: {
    CarrelloElement,
  },
  mixins: [carrello_cliente_mixin],
  methods: {},
};
</script>