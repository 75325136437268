<template>
  <v-card height="400" class="">
    <v-card-title class="grey--text text-subtitle-1">
      Situazione Prodotti Necessari
    </v-card-title>

    <v-card-text class="scroll">
      <v-divider class="mb-2" />
      <v-row class="font-weight-medium" no-gutters>
        <v-col cols="6"> Prodotti </v-col>
        <v-col cols="3"> Qta. Necessaria </v-col>
        <v-col cols="3"> # Ordini </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <template v-if="!lista.length">
        <v-row justify="center" class="py-8">
          Nessun Prodotto Necessario
        </v-row>
      </template>
      <template v-for="prod in lista">
        <v-row
          :key="prod.art"
          no-gutters
          align="center"
          class="my-1"
        >
          <v-col cols="6">
            <v-list-item dense>
              <v-list-item-avatar>
                <v-avatar size="40">
                  <v-img :src="avatar_img(prodotto(prod.id))" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ nome(prod.id)[0] }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ nome(prod.id)[1] }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            {{ prod.tot }}
          </v-col>
          <v-col cols="3">
            {{ prod.n_ordini }}
          </v-col>
        </v-row>
        <v-divider :key="prod.id + '-line'"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    lista() {
      let lista = [];
      this.$store.getters.get_ordini_attivi
        .filter((ord) => ord.stato == 1)
        .forEach((ordine) => {
          ordine.articoli.forEach((articolo) => {
            let in_list = lista.find((art) => {
              return art.id == articolo.art;
            });
            if (in_list) {
              in_list.tot += articolo.colori
                .map((col) => col.qta_admin ? col.qta_admin : col.numero)
                .reduce((tot, num) => tot + num, 0);
              in_list.n_ordini += 1;
            } else {
              lista.push({
                id: articolo.art,
                tot: articolo.colori
                  .map((col) => col.qta_admin ? col.qta_admin : col.numero)
                  .reduce((tot, num) => tot + num, 0),
                n_ordini: 1,
              });
            }
          });
        });
      return lista.sort((a, b) => b.tot - a.tot);
    },
  },
  methods: {
    prodotto(id) {
      return this.$store.getters.get_prodotto_by_id(id);
    },
    nome(id) {
      return this.prodotto(id).nome.split(" in ");
    },
    avatar_img(prod) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        prod.id +
        "?t=" +
        prod.avatar_md5
      );
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 330px;
  overflow-y: scroll;
}
</style>