import printer from "@/service/printer";
import moment from "moment";

export default {
  props: {
    ordine: Object,
  },
  data() {
    return {
      dialog_delete: false,
      dialog_refuse: false,
      dialog_join: false,
      dialog_join_confirm: false,
      show_orario: false,
      menu_opzioni: false,
      menu_cambio_data: false,
      modal_date: false,
      modal_time: false,
      joinable: [],
      loading_join: false,
      loading_elimina: false,
      accept_loading: false,
      send_loading: false,
      conteggio: null,
      loading_conteggio: true,
      loading_conferma: false,
      loading_invia_csv: false,
      in_consegna: false,
      consegna_collegata: {},
    };
  },
  mounted() {},
  computed: {
    totale() {
      return (
        parseFloat(this.ordine.tot) + parseFloat(this.ordine.tot_trasp)
      ).toFixed(2);
    },

    tot_cc() {
      return this.ordine.articoli
        .filter((art) => !art.eliminato)
        .map((art) => {
          let qta_cc = this.$store.getters.get_prodotto_raw_by_id(art.art)
            .qta_cc;

          return art.colori
            .filter((col) => !col.eliminato)
            .map((col) => col.qta_admin ? col.qta_admin : col.numero)
            .reduce((tot, num) => {
              return qta_cc != 0 ? tot + parseInt(num) / qta_cc : 0;
            }, 0);
        })
        .reduce((tot, num) => tot + num, 0)
        .toFixed(1);
    },

    stato() {
      switch (this.ordine.stato) {
        case 0:
          return {
            mdi: "mdi-clipboard-arrow-up",
            color: "warning",
            text: "In attesa di conferma",
          };
        case 1:
          return {
            mdi: "mdi-clipboard-check",
            color: "green",
            text: "Confermato",
          };
        case 2:
          return {
            mdi: "mdi-package-down",
            color: "indigo",
            text: "Evaso ",
          };
        case 3:
          return {
            mdi: "mdi-close-circle",
            color: "error",
            text: "Rifiutato",
          };
        case 4:
          return {
            mdi: "mdi-account-cancel",
            color: "error",
            text: "Annullato dal Cliente",
          };
        case 6: {
          return {
            mdi: "mdi-truck-fast",
            color: "blue",
            text: "In Consegna",
          };
        }
        case 7:
          return {
            mdi: "mdi-package-down",
            color: "indigo",
            text: "Consegnato",
          };
        case 8:
          return {
            mdi: "mdi-playlist-check",
            color: "purple",
            text: "Conteggiato",
          };
      }
      return 0;
    },
    // conteggio() {
    //   //return this.$store.getters.get_conteggio_by_ordcod(this.ordine._id.$oid)
    //   this.$store.dispatch("fetch_conteggio_by_id_ordine", this.ordine._id.$oid)
    //     .the;
    //   return;
    // },
    ritiro_date() {
      if (this.ordine.orario_ts > 0) {
        moment.locale("it");
        return moment.unix(this.ordine.orario_ts).format("ddd DD MMM HH:mm");
      } else {
        return false;
      }
    },
    dateFormatter() {
      moment.locale("it");
      return this.date ? moment(this.date).format("dddd D MMMM") : "";
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    },
    validDate() {
      return moment(this.date + " " + this.time).isValid();
    },
    // in_consegna() {
    //return this.$store.getters.get_ordine_in_consegna(this.ordine._id.$oid);
    // },
  },
  methods: {
    processa_ordine(stato) {
      this.loading_conferma = true;
      this.$store
        .dispatch("processa_ordine", {
          id: this.ordine._id.$oid,
          stato: stato,
        })
        .finally(() => {
          this.loading_conferma = false;
        });
    },
    process_order(status) {
      switch (status) {
        case 1:
          this.accept_loading = true;
          break;
        case 2:
          this.send_loading = true;
          break;
        default:
          break;
      }
      this.$store
        .dispatch("process_admin_order", {
          id: this.ordine.numero,
          status: status,
        })
        .finally(() => {
          this.accept_loading = false;
          this.ordinesend_loading = false;
        });
    },
    visibilità(visibile) {
      this.$store.dispatch("visibilità_ordine", {
        id: this.ordine._id.$oid,
        visibile: visibile,
      });
    },
    reactivate_order() {
      this.$store
        .dispatch("reactivate_order", this.ordine.numero)
        .then(() => {
          this.menu_opzioni = false;
        })
        .catch((err) => {
          if (err.code == 400) {
            this.$router.push("/login");
          }
        });
    },
    elimina_ordine() {
      this.loading_elimina = true;
      this.$store
        .dispatch("fetch_is_ordine_inconsegna", {
          id_ordine: this.ordine._id.$oid,
        })
        .then((res) => {
          if (res == false) {
            this.$store
              .dispatch("elimina_ordine", this.ordine._id.$oid)
              .finally(() => {
                this.dialog_delete = false;
                this.loading_elimina = false;
              });
          } else {
            this.consegna_collegata = res;
            setTimeout(() => {
              this.in_consegna = false;
              this.loading_elimina = false;
            }, 4000);
            this.in_consegna = true;
          }
        });
    },
    set_data: function (ts) {
      this.$store
        .dispatch("set_order_date", {
          id: this.ordine.numero,
          ts: ts,
        })
        .then(() => {
          this.process_order(1);
        });
    },
    closePicker: function (v) {
      v = v < 10 ? "0" + v : v;
      this.time = v + ":00";
      this.modal_time = false;
    },

    confirm_date_change() {
      this.$store.dispatch("set_order_date", {
        id: this.ordine.numero,
        ts: moment(this.date + " " + this.time).format("X"),
      });
    },
    print_pdf() {
      this.$store.dispatch("modifica_ordine_print", {
        id_ordine: this.ordine._id.$oid,
        stato: true,
      });
      printer.print_pdf(this.ordine.numero, false);
    },
    serch_for_join() {
      this.joinable = this.$store.getters.get_joinable_order({
        iva: this.ordine.iva,
        numero: this.ordine.numero,
      });
    },
    join(id) {
      this.loading_join = true;
      this.$store
        .dispatch("join_orders", {
          from: this.ordine._id.$oid,
          to: id,
        })
        .finally(() => {
          this.loading_join = false;
        });
    },
    apri_modale() {
      if (this.ordine.conteggiato) {
        // this.loading_conteggio = true;
        // this.$store
        //   .dispatch("fetch_conteggio_by_id_ordine", this.ordine._id.$oid)
        //   .then((res) => {
        //     this.conteggio = res.data;
        //     this.loading_conteggio = false;
        //   });
        this.$refs.modale_conteggio.open();
      }
    },
    toggle_letto() {
      this.$store.dispatch("modifica_ordine_nuovo_vecchio", {
        id_ordine: this.ordine._id.$oid,
        stato: !this.ordine.nuovo,
      });
    },
    riattiva_ordine() {
      this.$store.dispatch("riattiva_ordine", { id: this.ordine._id.$oid });
    },
    spedisci_conteggio() {
      this.loading_invia_csv = true;
      this.$store
        .dispatch("fetch_conteggio_by_id_ordine", this.ordine._id.$oid)
        .then((res) => {
          this.$store.dispatch("invia_csv", res.data.id).finally(() => {
            this.loading_invia_csv = false;
          });
        });
    },
  },
};
