<template>
  <div>
    <v-list-item @click="show_color = !show_color">
      <v-list-item-avatar>
        <v-avatar>
          <v-img :src="avatar_img" />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          :class="
            item.eliminato ? 'grey--text text-decoration-line-through' : ''
          "
        >
          {{ nome[0] }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ nome[1] }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          :class="
            item.eliminato ? 'grey--text text-decoration-line-through' : ''
          "
        >
          € {{ prezzo_tot }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.make_admin">
          <span class="green--text">Add by admin</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ item.commento }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>
          <span
            class="font-weight-bold"
            style="font-size: 16px"
            :class="
              item.eliminato ? 'grey--text text-decoration-line-through' : ''
            "
            >{{ tot_pezzi }}</span
          >
        </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>

    <v-slide-y-transition group>
      <template v-if="show_color">
        <v-list-item v-for="colore in item.colori" :key="colore.id">
          <v-list-item-avatar>
            <v-avatar size="30">
              <v-img :src="color_img(colore.id)"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="mr-4 grey--text">{{
                colore.qta_admin ? colore.qta_admin : colore.numero
              }}</span>
              <span class="font-italic grey--text">{{ colore.nome }}</span>
              <span
                v-if="colore.make_admin"
                class="green--text ml-2"
                style="font-size: 10px"
                >Add by Admin</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="ordine.stato == 1 || ordine.stato == 0"
          :key="0"
          @click="$refs.modifica_prod.open()"
        >
          <v-list-item-avatar>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-italic grey--text">Modifica ...</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="(ordine.stato == 1 || ordine.stato == 0) && !item.eliminato"
          :key="1"
          @click="rimuovi_soft_prodotto()"
        >
          <v-list-item-avatar>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-italic grey--text">Elimina</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="(ordine.stato == 1 || ordine.stato == 0) && item.eliminato"
          :key="1"
          @click="ripristina_soft_prodotto()"
        >
          <v-list-item-avatar>
            <v-icon>mdi-restore</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="font-italic grey--text">Ripristina</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-slide-y-transition>
    <ModificaProdotto
      ref="modifica_prod"
      no_icon
      :prodotto="item.art"
      :ordine="ordine"
    />
    <v-divider />
  </div>
</template>

<script>
import ordine_dettaglio_element_mixin from "../../mixin/ordine_dettaglio_element_mixin";
import ModificaProdotto from "../../desktop/ordine_dettaglio/ordine_modifica_prodotto.vue";
export default {
  components: {
    ModificaProdotto,
  },
  mixins: [ordine_dettaglio_element_mixin],
  data() {
    return {
      show_color: false,
    };
  },
  computed: {
    // avatar_url(){
    //   return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.art.art
    // },
    // nome() {
    //   if (this.art.nome.includes("Vaso")) {
    //     return this.art.nome.split("Vaso")[0];
    //   } else {
    //     return this.art.nome.split("Basket")[0];
    //   }
    // },
    // tot_pezzi() {
    //   return this.art.colori
    //     .map((col) => parseInt(col.numero + col.qta_admin))
    //     .reduce((tot, num) => tot + num);
    // },
    // prezzo_tot() {
    //   let tot = this.tot_pezzi * this.art.prezzo;
    //   return tot.toFixed(2);
    // },
  },
  methods: {
    // colore_url(id){
    //   return process.env.VUE_APP_API_ROOT + "/prodotto/" + this.art.art + "/img_colore/" + id
    // }
  },
};
</script>