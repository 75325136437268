import axios from "axios";

export default {
  state: {
    rapporto_verde: [],
    rapporto_verde_confronto: [],
    prodotti_venduti: [],
    ordini_trend: [],
    visite: [],
    analisi_ordine: [],
    analisi_prodotti: [],
    accessi: [],
  },
  getters: {
    get_rapporto_verde: (state) => {
      return state.rapporto_verde;
    },
    get_rapporto_verde_confronto: (state) => {
      return state.rapporto_verde_confronto;
    },
    get_prodotti_venduti: (state) => {
      return state.prodotti_venduti;
    },
    get_ordini_trend: (state) => {
      return state.ordini_trend;
    },
    get_visite: (state) => {
      return state.visite;
    },
    get_analisi_ordine_cliente: (state) => {
      return state.analisi_ordine;
    },
    get_analisi_prodotti: (state) => {
      return state.analisi_prodotti;
    },
    get_accessi_cliente: (state) => {
      return state.accessi;
    },
  },
  mutations: {
    set_rapporto_verde(state, rapporto) {
      state.rapporto_verde = rapporto;
    },
    set_rapporto_verde_confronto(state, rapporto) {
      state.rapporto_verde_confronto = rapporto;
    },
    set_prodotti_venduti(state, prodotti) {
      state.prodotti_venduti = prodotti;
    },
    set_ordini_trend(state, ordini) {
      state.ordini_trend = ordini;
    },
    set_visite(state, visite) {
      state.visite = visite;
    },
    set_analisi_ordine(state, analisi) {
      state.analisi_ordine = analisi;
    },
    set_analisi_prodotti(state, analisi) {
      state.analisi_prodotti = analisi;
    },
    set_accessi_clienti(state, accessi) {
      state.accessi = accessi;
    },
  },
  actions: {
    fetch_rapporto_verde(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/rapporto_verde/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_rapporto_verde", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_rapporto_verde_confronto(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/rapporto_verde/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_rapporto_verde_confronto", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_prodotti_venduti(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/prodotti_venduti/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_prodotti_venduti", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_prodotto_venduto(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/prodotto_venduto/" +
          payload.id_prodotto +
          "/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_ordini_trend(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/trend_ordini/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_ordini_trend", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_visite(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/visite/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_visite", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_analisi_ordine(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/rapporto_verde/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_analisi_ordine", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_analisi_clienti(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/rapporto_verde_clienti/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_analisi_prodotti(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/statistiche/prodotti_venduti/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_analisi_prodotti", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_accessi_clienti(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/logs/accessi/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_accessi_clienti", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
