<template>
  <div>
    <v-row class="pa-1 font-weight-bold" align="center" no-gutters>
      <v-col cols="1">
        <v-btn
          icon
          color="primary"
          class="mr-2"
          @click="show_color = !show_color"
        >
          <v-icon>{{
            show_color ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
        <span
          :class="
            item.eliminato ? 'grey--text text-decoration-line-through' : ''
          "
          >{{ tot_pezzi }}</span
        >
      </v-col>
      <v-col cols="4">
        <!-- <v-avatar class="mr-2" size="40">
          <v-img :src="avatar_img"></v-img>
        </v-avatar>
        {{ nome[0] }}
        <span
          v-if="item.make_admin"
          style="font-size: 10px"
          class="green--text font-weight-light ml-2"
        >
          Add by Admin</span
        >
        <br/>
        ciao -->
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="avatar_img"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="text-body-1 font-weight-light"
              :class="
                item.eliminato ? 'grey--text text-decoration-line-through' : ''
              "
            >
              {{ nome[0] }}
              <span
                v-if="item.make_admin"
                style="font-size: 10px"
                class="green--text font-weight-light ml-2"
              >
                Add by Admin</span
              >
            </v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-light"
              style="font-size: 14px"
            >
              {{ nome[1] }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col
        cols="2"
        :class="item.eliminato ? 'grey--text text-decoration-line-through' : ''"
      >
        € {{ item.prezzo.toFixed(2) }}
      </v-col>
      <v-col
        cols="2"
        :class="item.eliminato ? 'grey--text text-decoration-line-through' : ''"
      >
        € {{ prezzo_tot }}
      </v-col>
      <v-col cols="2">
        {{ item.commento }}
      </v-col>
      <v-col cols="1">
        <!-- Modifica prodotto -->

        <ModificaProdotto
          :prodotto="item.art"
          :ordine="ordine"
          v-if="!ordine.joined_in"
        />
        <!-- dialog cancella definitivo-->

        <v-dialog v-model="dialog_elimina" width="600" v-if="item.make_admin">
          <template v-slot:activator="{ on }">
            <v-btn icon color="red" v-on="on"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title class="red--text"> Elimina Prodotto </v-card-title>
            <v-card-text> Sei sicuro ? </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="red"
                :loading="loading_elimina"
                @click="rimuovi_prodotto()"
                ><v-icon class="ml-2">mdi-delete</v-icon>Elimina</v-btn
              >
              <v-btn text color="grey" @click="dialog_elimina = false"
                >Annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog_elimina_soft"
          width="600"
          v-if="!item.make_admin && !item.eliminato"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon color="red" v-on="on"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title class="red--text"> Elimina Prodotto </v-card-title>
            <v-card-text> Sei sicuro ? </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="red"
                :loading="loading_elimina"
                @click="rimuovi_soft_prodotto()"
                ><v-icon class="ml-2">mdi-delete</v-icon>Elimina</v-btn
              >
              <v-btn text color="grey" @click="dialog_elimina_soft = false"
                >Annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog_ripristina_soft"
          width="600"
          v-if="item.eliminato"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon color="green" v-on="on"
              ><v-icon>mdi-restore</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title class="green--text">
              Ripristina Prodotto
            </v-card-title>
            <v-card-text> Sei sicuro ? </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="green"
                :loading="loading_ripristina"
                @click.stop="ripristina_soft_prodotto()"
                ><v-icon class="ml-2">mdi-restore</v-icon>Ripristina</v-btn
              >
              <v-btn text color="grey" @click="dialog_ripristina_soft = false"
                >Annulla</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <template v-if="show_color">
      <v-row no-gutters class="pa-1 mb-1">
        <v-col cols="1"></v-col>
        <v-col cols="11">
          <v-row no-gutters>
            <v-col
              v-for="colore in item.colori"
              :key="colore.id"
              cols="12"
              class="text-truncate my-1"
            >
              <v-row no-gutters :class="colore.eliminato ? 'text-decoration-line-through' : ''">
                <v-tooltip color="white" :disabled="!colore.qta_admin" left>
                  <template v-slot:activator="{ on }">
                    <v-col cols="1" class="d-flex justify-end" >
                      <template v-if="!colore.qta_admin">
                        <span>{{ colore.numero }}</span>
                      </template>
                      <template v-else>
                        <span class="green--text">{{ colore.qta_admin }}</span>
                      </template>
                    </v-col>
                    <v-col cols="11" v-on="on">
                      <v-avatar size="27" class="mx-4">
                        <v-img :src="color_img(colore.id)" />
                      </v-avatar>
                      {{ colore.nome }}
                      <span
                        v-if="colore.make_admin"
                        style="font-size: 10px"
                        class="green--text font-weight-light"
                      >
                        Add by Admin</span
                      >
                    </v-col>
                  </template>
                  <div class="black--text">
                    {{ colore.numero }}
                    <span class="pink--text" style="font-size: 10px">
                      in precedenza
                    </span>
                  </div>
                </v-tooltip>

                <v-col cols="6"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <v-divider />
  </div>
</template>

<script>
import ordine_dettaglio_element_mixin from "../../mixin/ordine_dettaglio_element_mixin";
import ModificaProdotto from "./ordine_modifica_prodotto.vue";
export default {
  components: {
    ModificaProdotto,
  },
  mixins: [ordine_dettaglio_element_mixin],
};
</script>