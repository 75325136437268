<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
    @keydown.esc="close_dial()"
  >
    <v-card>
      <v-img :src="main_img" height="150">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-title>
        <v-list-item class="ml-n6">
          <v-list-item-avatar size="60">
            <!-- <v-icon color="primary" class="mr-3">mdi-spa</v-icon> -->
            <v-avatar size="60">
              <v-img contain :src="avatar_img" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 30px"
              class="font-italic font-weight-light primary--text"
            >
              {{ nome }}
            </v-list-item-title>
            <v-list-item-subtitle class="ml-1">
              {{ item.nome_sc }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu v-model="help_menu" open-on-hover left offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="">
                  <v-icon color="primary">mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="primary--text"> Istruzioni </v-card-title>
                <v-card-text>
                  Clicca sul colore e insersci la quantità desiderata nel box
                  che apparirà. <br />
                </v-card-text>
              </v-card>
            </v-menu>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon @click="close_dial()">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <v-card-text class="dialog_body">
        <v-divider />
        <v-row class="mt-0">
          <v-col cols="4">
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-information </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> INFORMAZIONI </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-diameter-variant </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle> Dimensione Vaso </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 20px" class="">{{
                    item.diametro
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-google-circles-communities </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>Unità di Vendita </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 20px" class="">{{ unita }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-currency-eur </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>Prezzo Unitario </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 20px" class="">{{
                    prezzo_unitario
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-currency-eur </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle
                  >Prezzo a Confezione
                </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 20px" class="">{{
                    prezzo_cliente
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="8">
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-palette </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> COLORI </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for="colore in item.colori">
              <ColorSelect :prodotto="item" :colore="colore" :key="colore.id" />
            </template>
          </v-col>
        </v-row>
        <v-row class="mt-2" no-gutters>
          <v-col>
            <v-textarea
              rows="1"
              label="Commento"
              :disabled="!exist"
              outlined
              hide-details
              v-model="commento"
              :rules="cmt_rules"
              counter="60"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined class="mb-2" color="primary" @click="close_dial()">
          <v-icon class="mr-2"> mdi-check </v-icon>
          Fatto
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dettaglio_prodotto_mixin from "../../mixin/nuovo_ordine_mixin/listino_dettaglio_prodotto_mixin";
import ColorSelect from "./menu_inserimento_qta"

export default {
  components:{
    ColorSelect,
  },
  mixins: [dettaglio_prodotto_mixin],
};
</script>