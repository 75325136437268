import axios from "axios";
import moment from "moment";
export default {
  state: {
    lotti: [],
    torbe: [],
    movimenti: null,
  },
  getters: {
    get_lotti: (state) => {
      return state.lotti;
    },
    get_lotto_by_id: (state) => (id) => {
      return state.lotti.find((lotto) => lotto.id == id);
    },
    get_torbe: (state) => {
      return state.torbe;
    },
    get_movimenti_lotti: (state) => {
      return state.movimenti;
    },
  },
  mutations: {
    set_lotti(state, lotti) {
      state.lotti = lotti;
    },
    set_nuovo_lotto(state, lotto) {
      state.lotti.push(lotto);
    },
    set_lotto_edit(state, lotto) {
      let index = state.lotti.find((lot) => lot.id == lotto.id);
      state.lotti.splice(index, 1, lotto);
    },
    set_lotto_delete(state, id_lotto) {
      let index = state.lotti.findIndex((lot) => lot.id == id_lotto);
      state.lotti.splice(index, 1);
    },
    set_torbe(state, torbe) {
      state.torbe = torbe;
    },
    set_torba_delete(state, id_torba) {
      let index = state.torbe.find((torba) => torba.id == id_torba);
      state.torbe.splice(index, 1);
    },
    set_nuova_torba(state, torba) {
      state.torbe.push(torba);
    },
    set_movimenti_lotto(state, movim) {
      state.movimenti = movim;
    },
    set_nuova_coltura_lotto(state, payload) {
      let lotto = state.lotti.find((lotto) => lotto.id == payload.id_lotto);
      lotto.colture_associate.push(payload.coltura);
    },
    set_zona_lotto(state, payload) {
      console.log(payload);
      let lotto = state.lotti.find((lotto) => lotto.id == payload.id_lotto);
      let coltura = lotto.colture_associate.find(
        (colt) => colt.id == payload.id_coltura
      );
      coltura.zona_attuale = payload.res.zona_attuale;
      coltura.ts_posizionamento = payload.res.ts_posizionamento;
      coltura.zone_precedenti = payload.res.zone_precedenti;
    },
    set_stato_coltura_lotto(state, payload) {
      let lotto = state.lotti.find((lotto) => lotto.id == payload.id_lotto);
      let coltura = lotto.colture_associate.find(
        (colt) => colt.id == payload.id_coltura
      );
      coltura.esaurito = payload.esaurito;
    },
    del_coltura_lotto(state, payload) {
      let lotto = state.lotti.find((lotto) => lotto.id == payload.id_lotto);
      let index = lotto.colture_associate.findIndex(
        (colt) => colt.id == payload.id_coltura
      );
      lotto.colture_associate.splice(index, 1);
    },
  },
  actions: {
    fetch_lotti(context) {
      return new Promise((resolve, reject) => {
        let inizio = moment().startOf("year").format("X");
        let fine = moment().endOf("year").format("X");
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/lotti/fornitore/0/periodo/" +
          inizio +
          "/" +
          fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_lotti", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invia_lotto(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/lotto";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_nuovo_lotto", res.data);
            resolve({ id_lotto: res.data.id });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_lotto(context, payload) {
      return new Promise((resolve, reject) => {
        let json = {
          nome: payload.nome,
          codice: payload.cod,
        };
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/lotto/" + payload.id_lotto;
        let auth = context.getters.get_credential;
        axios
          .put(url, json, { auth: auth })
          .then((res) => {
            context.dispatch("set_lotto_edit", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_lotto(context, id_lotto) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/lotto/" + id_lotto;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_lotto_delete", id_lotto);
            resolve("ok");
          })
          .catch((err) => {
            if (err.response.status == 400) {
              resolve("bound");
            }
            reject(err);
          });
      });
    },
    filtra_lotti(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/lotti/fornitore/" +
          payload.id_fornitore +
          "/periodo/" +
          payload.inizio +
          "/" +
          payload.fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_lotti", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_lotto_by_cod(context, codice) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/lotto/" + codice;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_nuovo_lotto", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_lotto_by_id(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/lotto/" + id;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_nuovo_lotto", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetch_torbe(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/torbe";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_torbe", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    invia_torba(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/torba";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_nuova_torba", res.data);
            resolve({ id_lotto: res.data.id });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_torba(context, id_torba) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/admin/torba/" + id_torba;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("set_torba_delete", id_torba);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ricerca_movimento_lotto(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/movimenti_lotto/" +
          payload.id_lotto +
          "/" +
          payload.ts_inizio;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_movimenti_lotto", res.data);
            resolve("ok");
          })
          .catch((err) => {
            context.commit("set_movimenti_lotto", null);
            reject(err);
          });
      });
    },
    imposta_stampa(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/lotti/set_sql_produzione";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    imposta_stampa_commercio(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/lotti/set_sql_commercio";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    aggiungi_coltura_lotto(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/movimenti_lotto/" +
          payload.id_lotto +
          "/coltura_associata";
        let auth = context.getters.get_credential;
        axios
          .post(url, payload, { auth: auth })
          .then((res) => {
            if (res.data.exist) {
              resolve("exist");
              return;
            }
            context.commit("set_nuova_coltura_lotto", {
              id_lotto: payload.id_lotto,
              coltura: res.data.coltura,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cambia_stato_produzione_coltura(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/movimenti_lotto/" +
          payload.id_lotto +
          "/coltura_associata/" +
          payload.id_coltura +
          "/stato_produzione";
        let auth = context.getters.get_credential;
        axios
          .put(url, payload, { auth: auth })
          .then(() => {
            context.commit("set_stato_coltura_lotto", {
              id_lotto: payload.id_lotto,
              id_coltura: payload.id_coltura,
              esaurito: payload.esaurito,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sposta_lotto_zona_coltura(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/movimenti_lotto/" +
          payload.id_lotto +
          "/coltura_associata/" +
          payload.id_coltura;
        let auth = context.getters.get_credential;
        axios
          .put(url, payload, { auth: auth })
          .then((res) => {
            context.commit("set_zona_lotto", {
              id_lotto: payload.id_lotto,
              id_coltura: payload.id_coltura,
              res: res.data,
            });
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_coltura_lotto(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT +
          "/admin/movimenti_lotto/" +
          payload.id_lotto +
          "/coltura_associata/" +
          payload.id_coltura;
        let auth = context.getters.get_credential;
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("del_coltura_lotto", payload);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    ricerca_lotto(context, lotto_id) {
      return new Promise((resolve, reject) => {
        let lotti = context.getters.get_lotti;
        let lotto = lotti.find((lotto) => lotto.id == lotto_id);
        if (lotto) {
          resolve(lotto);
        }
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/lotto_by_id/" + lotto_id;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
