import store from "../store/index";
import moment from "moment";

export default {
  fetch() {
    return new Promise((resolve) => {
      store
        .dispatch("fetch_prodotti_raw")
        .then(() => {
          store.dispatch("fetch_ordini_attivi");
        })
        .then(() => {
          store.dispatch("fetch_clienti").then(() => {
            store.dispatch("fetch_consegne");
            store.dispatch("fetch_consegne_archivio", {
              ts_inizio: moment().subtract(7, "days").format("X"),
              ts_fine: moment().format("X"),
            });
          });
          store.dispatch("fetch_prodotti");
          store.dispatch("fetch_ordini_evasi");
          store.dispatch("fetch_logs");
          store.dispatch("fetch_trasporto");
          store.dispatch("fetch_soglie_orari");
          store.dispatch("fetch_tempi_consegna");
          store.dispatch("fetch_stampante_remota");
          store.dispatch("fetch_chats");
          store.dispatch("fetch_chats_archivio");
          store.dispatch("fetch_conteggi");
          store.dispatch("fetch_fornitori");
          store.dispatch("fetch_lotti");
          store.dispatch("fetch_torbe");
          store.dispatch("fetch_zone");
          store.dispatch("fetch_testi_broadcast");
          resolve();
        });
    });
  },
  async fetch_2() {
    return await Promise.all([
      store.dispatch("fetch_prodotti_raw"),
      store.dispatch("fetch_ordini_attivi"),
      store.dispatch("fetch_clienti"),
      store.dispatch("fetch_consegne"),
      store.dispatch("fetch_consegne_archivio", {
        ts_inizio: moment().subtract(7, "days").format("X"),
        ts_fine: moment().format("X"),
      }),
      store.dispatch("fetch_prodotti"),
      store.dispatch("fetch_ordini_evasi"),
      store.dispatch("fetch_logs"),
      store.dispatch("fetch_trasporto"),
      store.dispatch("fetch_soglie_orari"),
      store.dispatch("fetch_stampante_remota"),
      store.dispatch("fetch_tempi_consegna"),
      store.dispatch("fetch_coord_azienda"),
      store.dispatch("fetch_chats"),
      store.dispatch("fetch_broadcasts"),
      store.dispatch("fetch_wamsgs"),
      store.dispatch("fetch_chats_archivio"),
      store.dispatch("fetch_conteggi"),
      store.dispatch("fetch_fornitori"),
      store.dispatch("fetch_lotti"),
      store.dispatch("fetch_torbe"),
      store.dispatch("fetch_zone"),
      store.dispatch("fetch_testi_broadcast"),
      store.dispatch("fetch_weather_now"),
      store.dispatch("fetch_automezzi"),
      store.dispatch("fetch_zone_coltivazione"),
    ]);
  },
};
