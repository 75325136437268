import axios from "axios";
export default {
  props: {
    cliente: Object,
  },
  data() {
    return {
      dialog: false,
      carrello: [],
    };
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        let url =
          process.env.VUE_APP_API_ROOT + "/admin/carrello/" + this.cliente.iva;
        let auth = this.$store.getters.get_credential;
        axios.get(url, { auth: auth }).then((res) => {
          this.carrello = res.data;
        });
      }
    },
  },
  computed: {
    totale() {
      return this.carrello
        .map((art) =>
          art.colori
            .map((col) => col.numero * art.prezzo)
            .reduce((tot, num) => tot + num, 0)
        )
        .reduce((tot, num) => tot + num, 0);
    },
  },
};
