import _ from 'lodash'
export default {
  props: {
    ordine: Object,
  },
  data() {
    return {
      menu_add: false,
      selectedItem: null,
      prodotto_sel_id: null,
      nome_prodotto: "",
      lista_prodotti: [],
      cmt_to_send: "",
      loading_commento: false,
      commento_ok: false,
    };
  },
  created() {
    this.debouncecmt = _.debounce(this.send_commento, 1200);
  },
  watch: {
    nome_prodotto: function () {
      if (this.nome_prodotto.length < 3) {
        this.prodotto_sel_id = null;
        this.lista_prodotti = [];
        return;
      }
      let regex = new RegExp(this.nome_prodotto, "i");
      this.lista_prodotti = this.prodotti.filter((prod) =>
        regex.test(prod.nome)
      );
    },
  },
  computed: {
    prodotti() {
      return this.$store.getters.get_lista_prodotti_raw;
    },
    prodotto_sel() {
      return this.$store.getters.get_prodotto_by_id(this.prodotto_sel_id);
    },
    commento: {
      get() {
        let articolo_in_order = this.ordine.articoli.find(
          (art) => art.art == this.prodotto_sel_id
        );

        return articolo_in_order ? articolo_in_order.commento : "";
      },
      set(cmt) {
        this.cmt_to_send = cmt;
        this.debouncecmt();
      },
    },
  },
  methods: {
    avatar_img(id) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
    },
    send_commento() {
      this.loading_commento = true;
      this.$store
        .dispatch("modifica_commento", {
          ordine: this.ordine._id.$oid,
          id_prodotto: this.prodotto_sel_id,
          commento: this.cmt_to_send,
        })
        .then(() => {
          this.loading_commento = false;
          this.commento_ok = true;
          setTimeout(() => {
            this.commento_ok = false;
          }, 1500);
        });
    },
  },
};
